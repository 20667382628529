<template>
	<div
	  class="p-4 text-green-700 border rounded border-green-900/10 bg-green-50"
	  role="alert"
	>
	  <strong class="text-sm font-medium"> Your message has been received! </strong>

	  <p class="mt-1 text-xs">
	    Thank you for contacting us!

	    We read every message and typically respond within 48 hours if a reply is required. Want to stay in touch? Follow us on Facebook!
	  </p>
	  
	</div>
</template>