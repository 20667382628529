<script>
	import narratives from '../mixins/narratives.js'

	import auth from '../mixins/auth.js'

	import actuator from '../mixins/actuator.js'

	import ImageUploader from '../mixins/imageUploader'

	export default {
		mixins: [auth, narratives, actuator, ImageUploader],
		mounted(){
			this.authenticated()
			if (this.$route.name == 'EditNarrative') {
				this.narrative_id = this.$route.params.id
				this.read()
			}
			this.readAllNarratives()
		},
		methods: {
			setHtml(z){
				this.html = z
			}
		}
	}
</script>
<template> 
	<section>

	  <div class="relative mx-auto ">
	    <div class="grid grid-cols-1 vh-full">
	      <div class=" md:py-24 text-left	">
	        <div class="max-w-lg px-4 mx-auto lg:px-8 mt-16">
	        	<div class="mx-auto my-12 ">
	        	<h1 >{{($route.name == 'EditNarrative') ? 'Edit' : 'Add'}} Narratives</h1>
	        	<a class="underline my-8" @click.prevent="$router.go(-1)">&larr;  Back </a>
	        	</div>
	          <div class="grid grid-cols-6 gap-4">

	          	<div class="col-span-6">
	          	  <label class="block mb-1 text-sm text-gray-600" for="title">
	          	    Narrative Image
	          	  </label>
	          	  <div class="relative p-8 text-center border border-gray-200 rounded-lg" v-if="arrayFiles.length == 0">
	          	    <h2 class="text-2xl font-medium">
	          	      There's nothing here...
	          	    </h2>

	          	    <p class="mt-4 text-sm text-gray-500">
	          	      Uploaded images will appear here, try clicking below to add one!
	          	    </p>
	          	  </div>
	          	  <ul class=" mb-8" >
	          	    <li  v-for="(image, i) in arrayFiles" :key="i">
	          	      <a
	          	        class="block shadow-xl"
	          	      >


	          	        <div class="aspect-w-1 aspect-h-1">
	          	          <img
	          	            loading="lazy"
	          	            alt="Smart Watch"
	          	            class="object-cover" 
	          	            :src="image.src"
	          	          />
	          	        </div>

	          	      </a>
	          	      <div class="text-center" >
	          	      	<a @click="deleteItem(i)">
	          	      		<vue-feather type=x></vue-feather>
	          	      	</a>

	          	      </div>
	          	      
	          	    </li>

	          	  </ul>



	          	  <div class="flex justify-center mt-8" v-if="arrayFiles.length == 0">
	          	      <div class="max-w-xs rounded-lg bg-transparent">
	          	          <div class="m-4">
	          	              <label class="inline-block mb-2 text-gray-500">File Upload</label>
	          	              <div class="flex items-center justify-center w-full">
	          	                  <label
	          	                      class="flex flex-col w-full h-32 border-4 border-[#126f51] border-dashed hover:bg-gray-100 hover:border-gray-300">
	          	                      <div class="flex flex-col items-center justify-center pt-7">
	          	                          <svg xmlns="http://www.w3.org/2000/svg" class="w-8 h-8 text-gray-400 group-hover:text-gray-600"
	          	                              fill="none" viewBox="0 0 24 24" stroke="currentColor">
	          	                              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
	          	                                  d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12" />
	          	                          </svg>
	          	                          <p class="pt-1 text-sm tracking-wider text-gray-400 group-hover:text-gray-600">
	          	                              Attach a file</p>
	          	                      </div>
	          	                      <input type="file" class="opacity-0" ref="inputsx" accept="image/*"  @change="multipleUploadx"/>
	          	                  </label>
	          	              </div>
	          	          </div>
	          	      </div>
	          	  </div> 
	          	</div>

	          	<div class="justify-center mx-auto ">
	          		<a
	          		  class="block shadow-xl"
	          		  v-if="$route.name == 'EditNarrative' && arrayFiles.length == 0 && loaded"
	          		>


	          		  <div class="aspect-w-1 aspect-h-1">
	          		    <img
	          		      loading="lazy"
	          		      alt="Smart Watch"
	          		      class="object-cover"
	          		      :src="parse(image)"
	          		    />
	          		  </div>

	          		</a>
	          	</div>


	          	<div class="col-span-6">
	          		<label class="block mb-1 text-sm text-gray-600">
	          			Section
	          		</label>
	          		<div class="flex items-center ">
	          		    <input id="section-radio-1" type="radio" value="Services"  v-model="section" name="section-radio" class="w-4 h-4 text-[#126f51] bg-gray-100 border-gray-300 focus:ring-[#126f51] dark:focus:ring-[#126f51] dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
	          		    <label for="section-radio-1" class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">Services </label>
	          		</div>
	          		<div class="flex items-center">
	          		    <input  id="section-radio-2" type="radio" value="About Us" v-model="section" name="section-radio" class="w-4 h-4 text-[#126f51] bg-gray-100 border-gray-300 focus:ring-[#126f51] dark:focus:ring-[#126f51] dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
	          		    <label for="section-radio-2" class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">About Us</label>
	          		</div>
	          		<div class="flex items-center">
	          		    <input  id="section-radio-3" type="radio" value="Properties" v-model="section" name="section-radio" class="w-4 h-4 text-[#126f51] bg-gray-100 border-gray-300 focus:ring-[#126f51] dark:focus:ring-[#126f51] dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
	          		    <label for="section-radio-3" class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">Properties</label>
	          		</div>
	          		<div class="flex items-center">
	          		    <input  id="section-radio-4" type="radio" value="Team" v-model="section" name="section-radio" class="w-4 h-4 text-[#126f51] bg-gray-100 border-gray-300 focus:ring-[#126f51] dark:focus:ring-[#126f51] dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
	          		    <label for="section-radio-4" class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">Team</label>
	          		</div>
	          		<div class="flex items-center">
	          		    <input  id="section-radio-5" type="radio" value="Careers" v-model="section" name="section-radio" class="w-4 h-4 text-[#126f51] bg-gray-100 border-gray-300 focus:ring-[#126f51] dark:focus:ring-[#126f51] dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
	          		    <label for="section-radio-5" class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">Careers</label>
	          		</div>
	          	</div>
	            <div class="col-span-6">
	              <label class="block mb-1 text-sm text-gray-600" for="title">
	                Subsection
	              </label>

	              <select
	                class="border-gray-200 relative rounded-t-lg w-full focus:ring-[#126f51] text-sm p-2.5"
	                v-model="subsection"
	              >
	                <optgroup label="Services" v-if="section == 'Services'">
	                    <option>Heading</option>
	                    <option>Text</option>
	                </optgroup>
	                <optgroup label="About Us" v-if="section == 'About Us'">
	                	<option>Main</option>
	                    <option>Vision</option>
	                    <option>Mission</option>
	                    <option>Approach</option>
	                    <!-- Add a subheading optional -->
	                </optgroup>
	                <optgroup label="Properties" v-if="section == 'Properties'">
	                    <option>Heading</option>
	                    <option>Text</option>
	                </optgroup>
	                <optgroup label="Team" v-if="section == 'Team'">
	                    <option>Heading</option>
	                    <option>Text</option>
	                </optgroup>
	                <optgroup label="Careers" v-if="section == 'Careers'">
	                    <option>Heading</option>
	                    <option>Text</option>
	                </optgroup>
	              </select>
	            </div>


	            <div class="col-span-6">
	              <label class="block mb-1 text-sm text-gray-600" for="description">
	                Description
	              </label>
  	              <wysiwyg 
  	                v-if="loadedEdit"
  	              	@write="setHtml" 
  	              	:isEdit="true" 
  	              	:editContent="html"
  	              	></wysiwyg>
  	              	<wysiwyg 
  	              	v-else
            		@write="setHtml" 
            		:isEdit="false" 
            		:editContent="html"
            		></wysiwyg>
	            </div>
	            <div class="col-span-6 mt-5" v-if="alert">
	            	<div class="flex p-4 mb-4 text-sm text-yellow-700 bg-yellow-100 rounded-lg dark:bg-yellow-200 dark:text-yellow-800" role="alert">
	            	  <svg aria-hidden="true" class="flex-shrink-0 inline w-5 h-5 mr-3" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clip-rule="evenodd"></path></svg>
	            	  <span class="sr-only">Info</span>
	            	  <div>
	            	    <span class="font-medium">Warning alert!</span> {{message}}
	            	  </div>
	            	</div>
	            </div>
	            <div class="col-span-6 mt-5">
	              <button
	                v-if="!loading && $route.name == 'EditNarrative' && arrayFiles.length == 0"
	                @click="edit"
	                class="rounded-lg bg-[#126f51] text-sm p-2.5 text-white  block">
	                Edit
	              </button>
	              <button
	              	v-if="!loading && arrayFiles.length != 0"
	              	@click="uploadImage"
	                class="rounded-lg bg-[#126f51] text-sm p-2.5 text-white block">
	                Proceed
	              </button>
	              <loader v-if="loading"></loader>
	            </div>

	          </div>

	        </div>
	      </div>


	    </div>
	  </div>

	</section>
</template>
