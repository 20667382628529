import { Numbers } from 'humanify-numbers'

const actuator = {
	methods: {
		isEmpty(e){
			var _ = require('lodash')
			const a = _.isEmpty(e)
			return a
		},
		isEmptyOrUndefinedString(str) {
		  return str === 'undefined' || str.trim().length === 0;
		},
		removeSymbols(str) {
		  // Regular expression to match symbols
		  const symbolRegex = /[^\w\s]|_/g;
		  
		  // Remove symbols using the regex
		  const cleanedString = str.replace(symbolRegex, '');
		  
		  return cleanedString;
		},
		parse(e){
			// var a=Array.from(e)
			// console.log( a)
			if (e == '[]' || e == ''){
				return 'https://dummyimage.com/720x400'
			}else {
				try {
					return JSON.parse(e)[0].file
				}catch{
					return 'https://dummyimage.com/720x400'
				}
			}
			 
		},
		isKeyUndefinedOrEmpty(array, key) {
		  for (let i = 0; i < array.length; i++) {
		    const value = array[i][key]
		    if (value === undefined || value === '') {
		      return true
		    }
		  }
		  return false
		},
		parseAll(e){
			if (e == '[]'){
				return []
			}else {
				return JSON.parse(e)
			}
		
		},
		parseStr(e){
			try {
			  return JSON.parse(e)
			  console.log(e)
			}
			catch(err) {
			  return []
			}
		},
		strNum(e){
			const { Numbers } = require("humanify-numbers")
			return Numbers.stringify(parseInt(e)) 

		},
		lastPart(e) {
		    var lastPart = e.split("/").pop()
		    return lastPart
		},
		processCode(str) {
		  if (str.trim().length === 0) {
		    return 254;
		  }
		  // Process the non-empty string
		  // (You can modify this part based on your requirements)
		  return str;
		},
		sanitizeNumber(mobileNumber, countryCode){
			const mobileNumberPattern = /^(\d{3})(\d{3})(\d{4})$/;

			// Remove country code or leading zero if it exists
			let number = mobileNumber.replace(/^(\+|)?(0)?/, '');

			// If the country code is not empty and matches the first few digits of the mobile number, remove it
			if (countryCode && number.startsWith(countryCode)) {
			  number = number.substring(countryCode.length);
			}

			// Match the mobile number against the regular expression pattern
			const matches = number.match(mobileNumberPattern);

			// If there were no matches, return the original mobile number
			if (!matches) {
			  return number;
			}

			// Format the mobile number as country code + number
			const formattedNumber = `${countryCode} ${matches[1]}-${matches[2]}-${matches[3]}`;

			return formattedNumber;
		}
	}
}

export default actuator