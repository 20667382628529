import axios from 'axios'
import api from './api'
import actuator from './actuator'

const team = {
	data: () => {
		return {
			name: '',
			role: '',
			avatar: '',
			description: '',
			twitter: '',
			linkedin: '',
			email: '',
			teammate_id: '',
			teammates: [],
			loaded: false,
			showDelete: false,
			teammate_id: 0 
		}
	},
	computed: {
		images(){
			return this.$store.state.images
		}
	},
	watch:{
		images(y){
			if(y.length > 0){
				if (this.$route.name == 'EditTeammate') {
					this.edit()
				}else {
					this.create()
				}
				
			}
		}
	},
	methods: {
		create(){
			var f = new FormData()

			f.append('name', this.name)
			f.append('role', this.role)
			f.append('description', this.description)
			f.append('twitter', this.twitter)
			f.append('linkedin', this.linkedin)
			f.append('email', this.email)
			f.append('avatar', JSON.stringify(this.$store.state.images))

			api.post('create/teammate/', f,  {  headers: {
			    'Content-Type': 'application/json',
			    'X-CSRFToken': this.$store.state.authtoken
			}}).then( (r) => {
				// this.$router.push('/manage')
				this.$store.commit('SET_IMAGES', [])
				this.$router.push('/manage')
			}, (e) => {
				console.log(e)
			})
		},
		edit(){
			var f = new FormData()

			f.append('teammate_id', this.teammate_id)
			f.append('name', this.name)
			f.append('role', this.role)
			f.append('description', this.description)
			f.append('twitter', this.twitter)
			f.append('linkedin', this.linkedin)
			f.append('email', this.email)

			if (this.$store.state.images.length == 0 ) {
				f.append('avatar', this.avatar)
			}else {
				f.append('avatar', JSON.stringify(this.$store.state.images))
			}
			

			api.post('edit/teammate/', f,  {  headers: {
			    'Content-Type': 'application/json',
			    'X-CSRFToken': this.$store.state.authtoken
			}}).then( (r) => {
				// this.$router.push('/manage')
				this.$store.commit('SET_IMAGES', [])
				this.$router.push('/manage')

				this.loaded = false
			}, (e) => {
				console.log(e)
			})
		},
		getTeammate(){
			var f = new FormData()

			f.append('name', this.name)

			api.post('get/teammate/', f).then( (r) => {
				// this.$router.push('/manage')
				this.teammate_id = r.data.teammate_id
				this.name = r.data.name
				this.role = r.data.role
				this.avatar = r.data.avatar	
				this.description = r.data.description
				this.twitter = r.data.twitter
				this.linkedin = r.data.linkedin
				this.email = r.data.email

				this.loaded = true
			}, (e) => {
				console.log(e)
			})
		},
		readAll(){ 
			api.get('get/teammates/').then( (r) => {
				this.teammates = r.data
				this.$store.state.teamLn = r.data.length
				this.loaded = true
			}, (e) => {
				console.log(e)
			})
		},
		initDel(y){
			this.teammate_id = y
			this.showDelete = true
		},
		del(){
			var f = new FormData()

			f.append('teammate_id', this.teammate_id)

			api.post('delete/teammate/', f).then( (r) => {
				this.readAll()
				this.showDelete = false
			})
		}
	}
}

export default team