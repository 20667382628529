<script >
	import FooterComponent from './footer'
	import Auth from '../mixins/auth'

	export default {
		mixins: [Auth],
		components: {
			FooterComponent
		}
	}
</script>
<template>
	<div class="px-4 py-3 text-white bg-[#126f51] fixed w-full z-infin">
	  <p class="text-sm font-medium text-center">
	    Love Axis? Subscribe to our newsletter!

	    <a class="underline" href="/#contact"> Learn More &rarr; </a>
	  </p>
	</div>
	<div class="flex items-center min-h-screen p-6 bg-gray-50 bg-[#126f51]">
	  <div
	    class="flex-1 h-full max-w-4xl mx-auto overflow-hidden bg-white rounded-lg shadow-3xl"
	  >
	    <div class="flex flex-col overflow-y-auto md:flex-row">
	      <div class="h-32 md:h-auto md:w-1/2">
	        <img
	          aria-hidden="true"
	          class="object-cover w-full h-full dark:hidden"
	          src="/static/house.gif"
	          alt="Office"
	        />
	        <img
	          aria-hidden="true"
	          class="hidden object-cover w-full h-full dark:block"
	          src="/static/house.gif"
	          alt="Office"
	        />
	      </div>
	      <div class="flex items-center justify-center p-6 sm:p-12 md:w-1/2">
	        <div class="w-full">
	          <h1
	            class="mb-4 text-xl font-semibold text-gray-700 dark:text-gray-200"
	          >
	            Login
	          </h1>
<!-- 	          <label class="block text-sm">
	            <span class="text-gray-700 dark:text-gray-400">Email</span>
	            <input type="email" v-model="email" id="email" name="email" class="w-full bg-white rounded border focus:border-[#126f51] focus:ring-2 focus:ring-[#126f51] text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out">
	          </label> -->
	          <label class="block text-sm">
	            <span class="text-gray-700 dark:text-gray-400">username</span>
	            <input type="username" v-model="username" id="username" name="username" class="w-full bg-white rounded border focus:border-[#126f51] focus:ring-2 focus:ring-[#126f51] text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out">
	          </label>
	          <label class="block mt-4 text-sm">
	            <span class="text-gray-700 dark:text-gray-400">Password</span>
	            <input type="password" v-model="password" id="password" placeholder="***************" class="w-full bg-white rounded border focus:border-[#126f51] focus:ring-2 focus:ring-[#126f51] text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out">
	          </label>

	          <div class="flex mt-6 text-sm">
	            <label class="flex items-center dark:text-gray-400">
	              <input
	                type="checkbox"
	                class="text-[#126f51] form-checkbox focus:border-bg-[#126f51] focus:outline-none focus:shadow-outline-purple dark:focus:shadow-outline-gray"
	              />
	              <span class="ml-2">
	                I agree to the
	                <span class="underline">privacy policy</span>
	              </span>
	            </label>
	          </div>

	          <!-- You should use a button here, as the anchor is only used for the example  -->
	          <a
	            class="block w-full px-4 py-2 mt-4 text-sm font-medium leading-5 text-center text-white transition-colors duration-150 border border-transparent rounded-lg bg-[#126f51] focus:outline-none focus:shadow-outline-purple"
	            @click="login"
	          >
	            Login
	          </a>

	          <!-- <hr class="my-8" /> -->


<!-- 	          <p class="mt-4">
	            <a
	              class="text-sm font-medium text-[#126f51] hover:underline"
	            >
	              Already have an account? Login
	            </a>
	          </p> -->
	        </div>
	      </div>
	    </div>
	  </div>
	</div>
</template>
<style scoped>
	#app {
		background: white!important;
	}
</style>