<template>
	<navbar />
	<div v-if="loaded">
		<div class="max-w-5xl mx-auto flex flex-col sm:flex-row gap-10 sm:gap-20 mt-32 mb-10 p-3">
		    <div class="text-3xl w-full sm:w-2/3 text-green-900 leading-relax">
		       Axis Real Estate Ltd is a leading real estate adviser in Africa. 
		    </div>

		    <div class="flex flex-col gap-5 items-start justify-center w-full sm:w-1/3">
		        <div class="text-green-900" v-html="(aboutMain != null || aboutMain != undefined) ? aboutMain.html : 'We provide our own property reviews on new house launches.'" >
		        </div>

		        <router-link to="/team" class="text-green-900 hover:text-green-700 inline-flex gap-1 items-center justify-start">
		            View Team
		            <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 rotate-45 text-current" fill="none"
		                 viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
		                <path stroke-linecap="round" stroke-linejoin="round" d="M7 11l5-5m0 0l5 5m-5-5v12"/>
		            </svg>
		        </router-link>
		    </div>
		</div>


		<!-- component -->
		<div class="py-16 bg-white max-w-5xl mx-auto">  
		  <div class="container m-auto px-6 text-gray-600 md:px-12 xl:px-6">
		      <div class="space-y-6 md:space-y-0 md:flex md:gap-6 lg:items-center lg:gap-12">
		        <div class="md:full lg:w-5/12">
		          <img :src="parse(aboutMission.image)" alt="image" loading="lazy" width="" height="">
		        </div>  
		        <div class="md:full lg:w-6/12">
		          <h2 class="text-2xl text-gray-900 font-bold md:text-4xl">Mission</h2>
		          <div class="text-green-900 mt-9" v-html="(aboutMission != null || aboutMission != undefined) ? aboutMission.html : 'We provide our own property reviews on new house launches.'"></div>
		        </div>
		      </div>
		  </div>
		</div>

		<!-- component -->
		<div class="py-16 bg-white max-w-5xl mx-auto">  
		  <div class="container m-auto px-6 text-gray-600 md:px-12 xl:px-6">
		      <div class="space-y-6 md:space-y-0 md:flex md:gap-6 lg:items-center lg:gap-12">

		        <div class="md:7/12 lg:w-6/12">
		          <h2 class="text-2xl text-gray-900 font-bold md:text-4xl">Vision</h2>
		          <div class="text-green-900 mt-9" v-html="(aboutVision != null || aboutVision != undefined) ? aboutVision.html : 'We provide our own property reviews on new house launches.'">
		          </div>
		        </div>
		        <div class="md:5/12 lg:w-5/12">
		         <img :src="parse(aboutVision.image)" class="rounded-3xl" alt="image" loading="lazy" width="" height="">
		        </div>
		      </div>
		  </div>
		</div>

		<!-- component -->
		<div class="py-16 bg-white max-w-5xl mx-auto">  
		  <div class="container m-auto px-6 text-gray-600 md:px-12 xl:px-6">
		      <div class="space-y-6 md:space-y-0 md:flex md:gap-6 lg:items-center lg:gap-12">
		        <div class="md:5/12 lg:w-5/12">
		          <img :src="parse(aboutApproach.image)" class="rounded-3xl" alt="image" loading="lazy" width="" height="">
		        </div>
		        <div class="md:7/12 lg:w-6/12">
		          <h2 class="text-2xl text-gray-900 font-bold md:text-4xl">Approach</h2>
		          <div class="text-green-900 mt-9" v-html="(aboutApproach != null || aboutApproach != undefined) ? aboutApproach.html : 'We provide our own property reviews on new house launches.'">
		          </div>
		        </div>
		      </div>
		  </div> 
		</div>
	</div>

</template>
<script>
	import narratives from '../mixins/narratives'
	import navbar from './navbar'
	import actuator from '../mixins/actuator'

	export default {
		components: {
			navbar 
		},
		mixins: [narratives, actuator],
		mounted(){
			this.readAllNarratives()
		}
	}
</script>
