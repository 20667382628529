import axios from 'axios'
import api from './api'
import actuator from './actuator'

const narratives = {
	data: () => {
		return {
			section: 'Services',
			subsection: '',
			html: '',
			narrative_id: '',
			narratives: [],
			loaded: false,
			showDelete: false,
			alert:false,
			message: null, 
			image: '',
			loadedEdit: false,
			serviceHeading: null,
			serviceText: null,
			aboutMain: null,
			aboutVision: null,
			aboutMission: null,
			aboutApproach: null,
			propertiesHeading: null,
			propertiesText: null,
			teamHeading: null,
			teamText: null,
		}
	},
	computed: {
		images(){
			return this.$store.state.images
		}
	},
	watch:{
		images(y){
			if(y.length > 0){
				if (this.$route.name == 'EditNarrative') {
					this.edit()
				}else {
					this.create()
				}
				
			}
		}
	},
	methods: {
		create(){
			var f = new FormData()

			if (this.narratives.some(e => e.section === this.section && e.subsection === this.subsection)) {
				this.alert = true
				this.message = 'You have already created a similar narrative kindly go back and edit it to change it'
			}else{
				f.append('section', this.section)
				f.append('subsection', this.subsection)
				f.append('html', this.html)
				f.append('image', JSON.stringify(this.$store.state.images))

				api.post('create/narrative/', f, {  headers: {
				    'Content-Type': 'application/json',
				    'X-CSRFToken': this.$store.state.authtoken
				}}).then( (r) => {
					this.$store.commit('SET_IMAGES', [])
					this.$router.push('/view/narratives')
				}, (e) => {
					console.log(e)
				})
			}
		},
		edit(){
			var f = new FormData()

			f.append('narrative_id', this.narrative_id)
			f.append('section', this.section)
			f.append('subsection', this.subsection)
			f.append('html', this.html)

			if (this.$store.state.images.length == 0 ) {
				f.append('image', this.image)
			}else { 
				f.append('image', JSON.stringify(this.$store.state.images))
			}
			

			api.post('edit/narrative/', f,  {  headers: {
			    'Content-Type': 'application/json',
			    'X-CSRFToken': this.$store.state.authtoken
			}}).then( (r) => {
				this.$store.commit('SET_IMAGES', [])
				this.$router.push('/view/narratives')

				this.loaded = false
			}, (e) => {
				console.log(e)
			})
		},
 		read(){
 			var f = new FormData()

 			f.append('narrative_id', this.narrative_id)

 			api.post('get/narrative/', f).then( (r) => {
 				// this.$router.push('/manage')
 				this.narrative_id = r.data.narrative_id
 				this.section = r.data.section
 				this.subsection = r.data.subsection
 				this.html = r.data.html	
 				this.image = r.data.image	

 				this.loaded = true
 				this.loadedEdit = true
 			}, (e) => {
 				console.log(e)
 			})
 		},
		readAllNarratives(){
			api.get('get/narratives/').then( (r) => {
				this.narratives = r.data
				this.setNarratives()
				this.loaded = true
			}, (e) => {
				console.log(e)
			})
		},
		initDel(y){
			this.narrative_id = y
			this.showDelete = true
		},
		del(){
			var f = new FormData()

			f.append('narrative_id', this.narrative_id) 

			api.post('delete/narrative/', f).then( (r) => {
				this.readAllNarratives()
				this.showDelete = false
			})
		},
		setNarratives(){
			this.serviceHeading = this.narratives.find(z => {
			  return z.section === 'Services' &&  z.subsection === 'Heading'
			})
			this.serviceText = this.narratives.find(z => {
			  return z.section === 'Services' &&  z.subsection === 'Text'
			})
			this.aboutMain = this.narratives.find(z => {
			  return z.section === 'About Us' &&  z.subsection === 'Main'
			})
			this.aboutVision = this.narratives.find(z => {
			  return z.section === 'About Us' &&  z.subsection === 'Vision'
			})
			this.aboutMission = this.narratives.find(z => {
			  return z.section === 'About Us' &&  z.subsection === 'Mission'
			})
			this.aboutApproach = this.narratives.find(z => {
			  return z.section === 'About Us' &&  z.subsection === 'Approach'
			})	
			this.propertiesHeading = this.narratives.find(z => {
			  return z.section === 'Properties' &&  z.subsection === 'Heading'
			})
			this.propertiesText = this.narratives.find(z => {
			  return z.section === 'Properties' &&  z.subsection === 'Text'
			})
			this.teamHeading = this.narratives.find(z => {
			  return z.section === 'Team' &&  z.subsection === 'Heading'
			})
			this.teamText = this.narratives.find(z => {
			  return z.section === 'Team' &&  z.subsection === 'Text'
			})
		}
	}
}

export default narratives