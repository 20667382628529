<template>
<div class="relative overflow-x-auto shadow-md sm:rounded-lg">
    <table class="w-full text-sm text-left text--100 dark:text--100">
        <tbody>
            <tr class="bg--500 border-b border--400" v-for="field in fields">
                <th scope="row" class="px-6 py-4 font-medium text--50 uppercase whitespace-nowrap dark:text--100">
                    {{field}}
                </th>
                <td class="px-6 py-4">
                    {{ listing[field] }}
                </td>
            </tr>

        </tbody>
    </table>
</div>

</template>
<script>
	export default {
		data: () => {
			return {
				fields: ["uuid", "title", "parking", "bathroom", "bedroom", "price", "category", "subcategory", "size", "location", "status", "availability", "disclosure", "currency", "serviceCharge"]
			}
		},
		props: [
			'listing'
		]
	}
</script>