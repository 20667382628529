<script>
	export default {
		data: () => {
			return {
				showNav: false
			}
		}
	}
</script>
<template>
	<nav class="bg-white fixed w-full mt-0 z-10 top-0 border-gray-200 px-2 sm:px-4 py-4 rounded :bg-gray-800">
	  <div class="container flex flex-wrap justify-between items-center mx-auto">
	  <router-link to="/" class="flex items-center">
	      <img src="/static/logo.png" class="mr-3 h-9 sm:h-9" alt="Axis Logo">
	  </router-link>
	  <div class="flex items-center md:order-2">
	      <button @click="(showNav == false) ? showNav = true : showNav = false" data-collapse-toggle="mobile-menu-2" type="button" class="inline-flex items-center p-2 ml-1 text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 :text-gray-400 :hover:bg-gray-700 :focus:ring-gray-600" aria-controls="mobile-menu-2" aria-expanded="false">
	      <span class="sr-only">Open main menu</span>
	      <svg class="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clip-rule="evenodd"></path></svg>
	      <svg class="hidden w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
	    </button>
	  </div>
	  <div class="hidden w-full md:flex md:w-auto md:order-1" >
	    <ul class="flex md:-ml-24 flex-col mt-4 md:flex-row md:space-x-8 md:mt-0 md:text-sm md:font-medium">
	      <li>
	        <router-link to="/" class="block py-2 pr-4 pl-3 text-gray-700 border-b border-gray-100 hover:bg-gray-50 md:hover:bg-transparent md:border-0 md:hover:text-[#006747] md:p-0 :text-gray-400 md::hover:text-white :hover:bg-gray-700 :hover:text-white md::hover:bg-transparent :border-gray-700 text-base">Home</router-link>
	      </li>
	      <li>
	        <router-link to="/about" class="block py-2 pr-4 pl-3 text-gray-700 border-b border-gray-100 hover:bg-gray-50 md:hover:bg-transparent md:border-0 md:hover:text-[#006747] md:p-0 :text-gray-400 md::hover:text-white :hover:bg-gray-700 :hover:text-white md::hover:bg-transparent :border-gray-700 text-base">About</router-link>
	      </li>
	      <li>
	        <router-link to="/services" class="block py-2 pr-4 pl-3 text-gray-700 border-b border-gray-100 hover:bg-gray-50 md:hover:bg-transparent md:border-0 md:hover:text-[#006747] md:p-0 :text-gray-400 md::hover:text-white :hover:bg-gray-700 :hover:text-white md::hover:bg-transparent :border-gray-700 text-base">Services</router-link>
	      </li>
<!-- 	      <li>
	        <router-link to="/properties" class="block py-2 pr-4 pl-3 text-gray-700 border-b border-gray-100 hover:bg-gray-50 md:hover:bg-transparent md:border-0 md:hover:text-[#006747] md:p-0 :text-gray-400 md::hover:text-white :hover:bg-gray-700 :hover:text-white md::hover:bg-transparent :border-gray-700 text-base">Properties</router-link>
	      </li> -->
	      <li>
	        <router-link to="/team" class="block py-2 pr-4 pl-3 text-gray-700 border-b border-gray-100 hover:bg-gray-50 md:hover:bg-transparent md:border-0 md:hover:text-[#006747] md:p-0 :text-gray-400 md::hover:text-white :hover:bg-gray-700 :hover:text-white md::hover:bg-transparent :border-gray-700 text-base">Team</router-link>
	      </li>
	      <li>
	        <router-link to="/contact" class="block py-2 pr-4 pl-3 text-gray-700 border-b border-gray-100 hover:bg-gray-50 md:hover:bg-transparent md:border-0 md:hover:text-[#006747] md:p-0 :text-gray-400 md::hover:text-white :hover:bg-gray-700 :hover:text-white md::hover:bg-transparent :border-gray-700 text-base">Contact</router-link>
	      </li>
	      <li>
	        <router-link to="/manage" class="block py-2 pr-4 pl-3 text-gray-700 border-b border-gray-100 hover:bg-gray-50 md:hover:bg-transparent md:border-0 md:hover:text-[#006747] md:p-0 :text-gray-400 md::hover:text-white :hover:bg-gray-700 :hover:text-white md::hover:bg-transparent :border-gray-700 text-base">Dashboard</router-link>
	      </li>
	    </ul>
	  </div>
	  <div class=" w-full md:flex md:w-auto md:order-1" v-if="showNav">
	    <ul class="flex md:-ml-24 flex-col mt-4 md:flex-row md:mt-0 md:text-sm md:font-medium">
	    	<li>
	    	  <router-link to="/" class="block py-2 pr-4 pl-3 text-gray-700 border-b border-gray-100 hover:bg-gray-50 md:hover:bg-transparent md:border-0 md:hover:text-[#006747] md:p-0 :text-gray-400 md::hover:text-white :hover:bg-gray-700 :hover:text-white md::hover:bg-transparent :border-gray-700 text-base">Home</router-link>
	    	</li>
	    	<li>
	    	  <router-link to="/about" class="block py-2 pr-4 pl-3 text-gray-700 border-b border-gray-100 hover:bg-gray-50 md:hover:bg-transparent md:border-0 md:hover:text-[#006747] md:p-0 :text-gray-400 md::hover:text-white :hover:bg-gray-700 :hover:text-white md::hover:bg-transparent :border-gray-700 text-base">About</router-link>
	    	</li>
	    	<li>
	    	  <router-link to="/services" class="block py-2 pr-4 pl-3 text-gray-700 border-b border-gray-100 hover:bg-gray-50 md:hover:bg-transparent md:border-0 md:hover:text-[#006747] md:p-0 :text-gray-400 md::hover:text-white :hover:bg-gray-700 :hover:text-white md::hover:bg-transparent :border-gray-700 text-base">Services</router-link>
	    	</li>
<!-- 	    	<li>
	    	  <router-link to="/properties" class="block py-2 pr-4 pl-3 text-gray-700 border-b border-gray-100 hover:bg-gray-50 md:hover:bg-transparent md:border-0 md:hover:text-[#006747] md:p-0 :text-gray-400 md::hover:text-white :hover:bg-gray-700 :hover:text-white md::hover:bg-transparent :border-gray-700 text-base">Properties</router-link>
	    	</li> -->
	    	<li>
	    	  <router-link to="/team" class="block py-2 pr-4 pl-3 text-gray-700 border-b border-gray-100 hover:bg-gray-50 md:hover:bg-transparent md:border-0 md:hover:text-[#006747] md:p-0 :text-gray-400 md::hover:text-white :hover:bg-gray-700 :hover:text-white md::hover:bg-transparent :border-gray-700 text-base">Team</router-link>
	    	</li>
	    	<li>
	    	  <router-link to="/contact" class="block py-2 pr-4 pl-3 text-gray-700 border-b border-gray-100 hover:bg-gray-50 md:hover:bg-transparent md:border-0 md:hover:text-[#006747] md:p-0 :text-gray-400 md::hover:text-white :hover:bg-gray-700 :hover:text-white md::hover:bg-transparent :border-gray-700 text-base">Contact</router-link>
	    	</li>
	    	<li>
	    	  <router-link to="/manage" class="block py-2 pr-4 pl-3 text-gray-700 border-b border-gray-100 hover:bg-gray-50 md:hover:bg-transparent md:border-0 md:hover:text-[#006747] md:p-0 :text-gray-400 md::hover:text-white :hover:bg-gray-700 :hover:text-white md::hover:bg-transparent :border-gray-700 text-base">Dashboard</router-link>
	    	</li>
	    	
	    </ul>
	  </div>
	  </div>
	</nav>

</template>