<script>
	import leads from '../mixins/leads'
	import actuator from '../mixins/actuator'
	import Sidebar from '../dashboard/Sidebar'
	import Navbar from '../dashboard/Navbar'
	import Footer from '../dashboard/Footer'
	export default {
		mixins: [leads, actuator],
		mounted(){
			this.readAll()
			this.getALeads()
		},
		computed: { 
			sideBarOpen: {  
				get(){
					return this.$store.state.sideBarOpen
				},
				set(z){
					this.$store.commit('SET_SIDEBAR', z)
				}
			} 
		},
		watch: {
			$route(){
				this.sideBarOpen = false
			}
		},

		components: {
		  Sidebar,
		  Navbar,
		  Footer
		},
		methods: {
		  toggleSidebar(z){
		    this.sideBarOpen = z
		  }
		} 
	}
</script>
<template> 
	<reply-modal v-if="replyModal" @cancel="replyModal = false" /> 
	<!-- <multiselect v-if="msModal" @cancel="msModal = false" /> -->
	<div class="leading-normal tracking-normal" id="main-body">

	  <div class="flex flex-wrap">

	    <Sidebar :sideBarOpen="sideBarOpen"/>

	    <pageload v-if="loading" />

	    <div v-else class="w-full bg-gray-100 pl-0 lg:pl-64 min-h-screen" :class="sideBarOpen ? 'overlay' : ''" id="main-content">

	      <Navbar :sideBarOpen="sideBarOpen" @toggleSidebar="toggleSidebar"/>

	      <div class="bg-gray-100 mb-20">
	        
	        <!-- New Table -->
	        <div class="w-full overflow-hidden rounded-lg shadow-xs">

	          <div class="w-full overflow-x-auto">

	          	<div class="relative p-8 text-center border border-gray-200 rounded-lg" v-if="leads.length == 0">
	          	  <h2 class="text-2xl font-medium">
	          	    There's nothing here...
	          	  </h2>

	          	  <p class="mt-4 text-sm text-gray-500">
	          	    New content will appear here once added. If you are the site administrator add content to begin.
	          	  </p>
	          	</div>

	          	<div class="flex items-center justify-center flex-1">
	          	  <div class="max-w-xl px-4 py-8 mx-auto text-center">
	          	    <h1 class="text-2xl font-bold tracking-tight text-gray-900 sm:text-4xl">
	          	      Add spreadsheets to this contact list 
	          	    </h1>

	          	    <p class="mt-4 text-gray-500">
	          	     Once you have added the spreadsheet to your contact list, you can easily access it and use it whenever you need to. 
	          	    </p>

	          	    <div class="flex flex-wrap gap-4">
	          	    	
	          	    </div>

	          	    <router-link
	          	      to="/rolodex"
	          	      class="inline-block px-5 py-3 mt-6 text-sm font-medium text-white bg-green-700 rounded hover:bg-green-800 focus:outline-none focus:ring"
	          	    >
	          	      Add
	          	    </router-link>
	          	    <router-link
	          	      to="/contact"
	          	      class="inline-block px-5 py-3 mt-6 text-sm font-medium text-green-700 rounded"
	          	    >
	          	      Add a single contact
	          	    </router-link>
	          	    <!-- search bar -->
	          	    <div class="mt-4">   
	          	        <label for="search" class="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white">Search</label>
	          	        <div class="relative">
	          	            <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
	          	                <svg aria-hidden="true" class="w-5 h-5 text-gray-500 dark:text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path></svg>
	          	            </div>
	          	            <input v-model="query" type="search" id="search" class="block w-full p-4 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-green-700 focus:border-green-700 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-green-700 dark:focus:border-green-700" placeholder="Search" required>
	          	            <button type="submit" class="text-white absolute right-2.5 bottom-2.5 bg-green-700 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-lg text-sm px-4 py-2 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800">Search</button>
	          	        </div>
	          	    </div>
	          	  </div>
	          	</div>

	            <table class="w-full whitespace-no-wrap" v-if="cleads.length > 0">
	              <thead>
	                <tr
	                  class="text-xs font-semibold tracking-wide text-left text-black uppercase border-b dark:border-gray-700 bg-gray-50 dark:text-gray-400 dark:bg-gray-800"
	                >
	                  <th class="px-4 py-3 text-black">
	                  	<button 
	                  		@click="(checkedLeads.length == 0) ?  selectAll() : unselectAll()"
	                  		class="bg-[#126f51] hover:bg-red-8	00 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
	                  	  >
	                  	{{ (checkedLeads.length == 0) ?  'Select All' : 'Unselect All'}}
	                  	</button>

	                  </th>
	                  <th class="px-4 py-3 text-black">Name</th>
	                  <th class="px-4 py-3">Email</th>
	                  <th class="px-4 py-3">Country Code</th>
	                  <th class="px-4 py-3">Number</th>
	                  <th class="px-4 py-3">Created At</th>
	                  <th class="px-4 py-3">Listing Inquiry</th>
	                  <th class="px-4 py-3">Message</th>
	                  <th class="px-4 py-3">Actions</th>
	                </tr>
	              </thead> 
	              <tbody
	                class="bg-white divide-y dark:divide-gray-700 dark:bg-gray-800"
	              >
	                <tr class="text-gray-700 dark:text-gray-400" v-for="(lead, i) in cleads" :key="i">
	                  <th scope="col" class="p-4">
	                      <div class="flex items-center">
	                          <input
	                           :id="i" 
	                           type="checkbox"
	                           :value="lead.lead_id"
	                           v-model="checkedLeads"
	                           :name="i" 
	                           class="w-4 h-4 text-green-600 bg-gray-100 border-gray-300 rounded focus:ring-green-500 dark:focus:ring-green-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
	                          <label for="checkbox-all-search" class="sr-only">checkbox</label>
	                      </div>
	                  </th>
	                  <td class="px-4 py-3">
	                    <div class="flex items-center text-sm">
	                      <div>
	                        <p class="font-semibold">{{lead.fname}} {{lead.lname}}</p>
	                        <p class="text-xs text-gray-600 dark:text-gray-400">
	                          {{lead.occupation}}
	                        </p>
	                      </div>
	                    </div>
	                  </td>
	                  <td class="px-4 py-3 text-sm">
	                  	 <input  v-model="cleads[i].email" :id="lead.email" class=" rounded border focus:border-[#126f51] focus:ring-2 focus:ring-[#126f51] text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out">
	                     <!-- {{}} -->
	                  </td>
	                  <td class="px-4 py-3 text-sm">
	                  	<input  v-model="cleads[i].ccode" :id="lead.ccode" class=" rounded border focus:border-[#126f51] focus:ring-2 focus:ring-[#126f51] text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out">
	                  <!--   {{lead.ccode}} -->
	                  </td>
	                  <td class="px-4 py-3 text-sm">
	                    {{sanitizeNumber(lead.number, lead.ccode)}}
	                  </td>
	                  <td class="px-4 py-3 text-sm">
	                    {{lead.created_at}}
	                  </td>
                      <td class="px-4 py-3 text-xs underline">
                      	<router-link :to="(lead.listing) ? `/listing/`+lead.listing.uuid : 'None'">
                      		{{ (lead.listing) ? lead.listing.title : ''}}
                      	</router-link>
	          			
	                  </td>
	                  <td class="px-4 py-3 text-xs">
	        			{{ lead.message }}
	                  </td>
	                  <td class="px-4 py-2">
	                  	<div class="inline-flex rounded-lg border border-gray-100 bg-gray-100 p-1">
	                  	  <button
	                  	  	 @click="edit(lead.lead_id, i)" 
	                  	    class="inline-block rounded-md px-4 py-2 text-sm text-gray-500 hover:text-gray-700 focus:relative"
	                  	  >
	                  	    Edit
	                  	  </button>
	                  	  <button
	                  	  	 @click="reply('sms', lead)" 
	                  	    class="inline-block rounded-md px-4 py-2 text-sm text-gray-500 hover:text-gray-700 focus:relative"
	                  	  >
	                  	    Sms
	                  	  </button>

	                  	  <button
	                  	    @click="reply('email', lead)"
	                  	    class="inline-block rounded-md px-4 py-2 text-sm text-gray-500 hover:text-gray-700 focus:relative"
	                  	  >
	                  	    Email
	                  	  </button>

	                  	  <button
	                  	  	@click="initDel(lead.lead_id)"
	                  	    class="inline-block rounded-md bg-white px-4 py-2 text-sm text-red-500 shadow-sm focus:relative"
	                  	  >
	                  	    Delete
	                  	  </button>
	                  	</div>
	                  </td>

	                </tr>

	              </tbody>
	            </table>
	            <nav class="flex items-center justify-between pt-4" aria-label="Table navigation">
	                <span class="text-sm font-normal text-gray-500 dark:text-gray-400">Showing <span class="font-semibold text-gray-900 dark:text-white">{{(page==1) ? '1' : page*10}}-{{(page==1) ? '10' : (page*10)+10}}</span> of <span class="font-semibold text-gray-900 dark:text-white">{{count }}</span></span>
	                <ul class="inline-flex items-center -space-x-px">
	                    <li>
	                        <a v-if="page != 1" @click="page--" class="block px-3 py-2 ml-0 leading-tight text-gray-500 bg-white border border-gray-300 rounded-l-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white">
	                            <span class="sr-only">Previous</span>
	                            <svg class="w-5 h-5" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z" clip-rule="evenodd"></path></svg>
	                        </a>
	                    </li>
	                    <li>
	                        <a href="#" class="px-3 py-2 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white">{{page}}</a>
	                    </li>

	                    <li>
	                        <a @click="page++" class="block px-3 py-2 leading-tight text-gray-500 bg-white border border-gray-300 rounded-r-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white">
	                            <span class="sr-only">Next</span>
	                            <svg class="w-5 h-5" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd"></path></svg>
	                        </a>
	                    </li>
	                </ul>
	            </nav>
	          </div>
	          <delete  v-if="showDelete" @cancel="showDelete = false" @accept="del()"></delete>
	        </div>
	      </div>


	      <Footer />

	    </div>
	  </div>
	</div>

</template>