<script>
	import properties from '../mixins/properties'
	import actuator from '../mixins/actuator'
	import filterMixin from '../mixins/filter'

	export default {
		mixins: [properties, actuator, filterMixin],
		mounted(){
        	this.loaded = false
			this.readAll()
		},
		props: [
		  'propertiesHeading',
		  'propertiesText'
		]
	}
</script>
<template>
	<div class="max-w-full min-h-full mx-auto justify-center sm:rounded-xl">
		<vue-carousel v-if="loaded" :autoplay="4000" :wrap-around="true"  :pauseAutoplayOnHover="true">
		  <vue-slide v-for="(listing, i) in showcaseItems"  :key="i" style="height: 100%;" >

		  	<div class="propertie-item set-bg w-full h-[500px]" :style="{background: `url(${parse(listing.images)})no-repeat center`, backgroundSize: 'cover'}">
		  	  <router-link :to="`/listing/${listing.uuid}`">
		  	    <div class="propertie-info text-white">
		  	      <div class="info-warp text-left">
		  	        <p class="font-bold text-xl mb-2">{{listing.title }}</p>
		  	        <p v-html="listing.description.slice(0,120)+'...'"></p>
		  	        <!-- <p><i class="fa fa-map-marker"></i> {{ listing.location }}</p> -->
		  	      </div>
		  	      <div class="price ma-3" v-if="listing.price != ''">{{ (strNum(listing.price) != 0 || listing.price == '') ? listing.currency + strNum(listing.price) : 'Price on asking' }} </div>

		  	    </div>
		  	  </router-link>

		  	</div>
		  	
		  </vue-slide>

		  <template #addons>
		    <vue-navigation />
		    <vue-pagination />
		  </template>
		</vue-carousel>

	    <div class="relative w-full h-full  sm:rounded-xl flex flex-col  gap-4 items-center justify-center pb-24">


	        <div class=" absolute -bottom-44 md:-bottom-24">
	            <div class="flex gap-2 bg-[#006f51] rounded-t-xl">
	                <a @click="property_status = 'For Sale'" :class="{'bg-white text-[#006f51]': property_status == 'For Sale'}" class="rounded-t-xl py-2 px-4 text-white">
	                    Buy
	                </a>

	                <a @click="property_status = 'For Let'" :class="{'bg-white text-[#006f51]': property_status == 'For Let'}" class="text-white rounded-t-xl py-2 px-4">
	                    Rent
	                </a>

	                <a @click="property_status = 'Project'" :class="{'bg-white text-[#006f51]': property_status == 'Project'}" class=" text-white rounded-t-xl py-2 px-4">
	                    Projects
	                </a>
	            </div> 
 
	            <div class="flex flex-col bg-white rounded-b-xl p-4 rounded-tr-xl gap-4 drop-shadow-xl">


	                <div class="flex flex-wrap items-center justify-start gap-2">

	                    <div class="flex items-center justify-start bg-gray-100 gap-1 py-2 px-4 rounded-xl w-full md:w-fit">

	                        <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 text-[#006f51]" fill="none"
	                             viewBox="0 0 24 24" stroke="currentColor" stroke-width="1">
	                            <path stroke-linecap="round" stroke-linejoin="round"
	                                  d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"/>
	                        </svg>

	                        <input v-model="query" class="text-sm bg-gray-100 text-[#006f51] focus:ring-0 focus:outline-none"
	                               placeholder="search"/>
	                    </div>


	                    <div class="flex items-center justify-start bg-gray-100 gap-3 py-2 px-4 rounded-xl w-full md:w-fit">

	                    	<p class="text-sm  text-[#006f51]">KES</p>

	                        <input class="text-sm bg-gray-100 text-[#006f51] focus:ring-0 focus:outline-none"
	                               placeholder="min price" v-model="$store.state.minprice"/>
	                    </div>

	                    <div class="flex items-center justify-start bg-gray-100 gap-3 py-2 px-4 rounded-xl w-full md:w-fit">

	                    	<p class="text-sm  text-[#006f51]">KES</p>

	                        <input class="text-sm bg-gray-100 text-[#006f51] focus:ring-0 focus:outline-none"
	                               placeholder="max price" v-model="$store.state.maxprice"/>
	                    </div>

	                </div>

	                <button @click="filterBySearch" class="bg-[#006f51] hover:bg-[#006f51] px-6 py-3 text-sm text-white rounded-xl">
	                    Search
	                </button>

	            </div>
	        </div>


	    </div>
	</div>
	


	<!-- ====== Hero Section End -->
</template>
<style>
	.hero-img {
		background-size: inherit;
	}
	.mt-0 {
		margin-top: 0!important;
		--tw-space-y-reverse: none!important;
	}
	:root {
		--vc-clr-primary: #006f51!important;
		--vc-clr-secondary: #baf1d2!important;
	}
	.carousel__prev--in-active,
	.carousel__next--in-active {
	  display: none;
	}
	.carousel__prev{
		margin-left: 20px;
	}
	.carousel__next{
		margin-right: 20px;
	}
</style>