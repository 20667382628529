const propams = {
	data () {
		return {
			resources: [
			"Aircon", "Alarm", "Backup Generator", "Balcony", "BBQ", "Borehole", "Bus Stop", "CCTV", "Electric Fence", "En Suite", "Fibre Internet", "Furnished", "Garden", "Golf Course", "Guest House", "Gym", "Hospital", "Parking", "Pets Allowed", "Scenic View", "School", "Sea View", "Service Charge Included", "Serviced", "Staff Quarters", "Swimming Pool", "Walk In Closet", "Wheelchair Access"
			]
		}
	}
}

export default propams