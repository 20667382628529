import axios from 'axios'
import api from './api'
import actuator from './actuator'

const newsletters = {
	data: () => {
		return {
			subject: null,
			newsletters: [],
			loaded: false,
			showDelete: false,
			newsletter_id: 0,
			counter: 0,
			unsendables: 0
		} 
	},

	computed: {
		selectedContacts(){
			return this.$store.state.selectedContacts
		},
		checkedLeads(){
			return this.$store.state.checkedLeads
		},
		aLeads(){
			return this.$store.state.aLeads
		}
	},
 
	methods: { 
		// send(){
		// 	var f = new FormData()

		// 	for (var i = 0; i < this.selectedContacts.length; i++) {
		// 		f.append('subject', this.subject)
		// 		// sift through content find name 
		// 		f.append('content', this.content.html)
		// 		f.append('email', this.selectedContacts[i].email)

		// 		api.post('send/newsletter/', f, {  headers: {
		// 		    'Content-Type': 'application/json',
		// 		    'X-CSRFToken': this.$store.state.authtoken
		// 		}}).then( (r) => {
	
		// 		}, (e) => {
		// 			console.log(e)
		// 		})
		// 	}	
		// 	this.$router.push('/manage')
		// },
		async create(y){
			if (this.isEmptyOrUndefinedString(y.email)) {
				this.unsendables++
			}else{
				var f = new FormData()

				try {
					f.append('subject', this.subject)
					// sift through content find name 
					f.append('content', this.content.html)
					f.append('email', y.email)

					 const response = await api.post('send/newsletter/', f)
					 const data = response.data
				  // Do something with the data
				} catch (error) {
				  console.error(error)
				  this.unsendables++
				}
			}
		},
		async lContacts(){
			for (var i = 0; i < this.checkedLeads.length; i++) {
				await this.create(this.findLeadById(this.checkedLeads[i]))
				this.counter++
			}	
		},
		findLeadById(id) {
		  console.log(id)
		  for (const lead of this.aLeads) {
		    if (lead.lead_id == id) {
		      return lead
		    }
		  }
		  return null
		},
		async cContacts() { 
		  await this.lContacts()
		  console.log('Loop finished')
		},
	},
	
}

export default newsletters