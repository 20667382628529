<template>
	<section>
	  <div class="max-w-screen-xl px-4 py-12 mx-auto sm:px-6 lg:px-8">
	    <div
	      class="grid grid-cols-1 gap-4 lg:grid-cols-4 lg:items-start"
	    >
	    <div class="lg:sticky lg:top-4">
	      <details
	        open
	        class="overflow-hidden border border-gray-200 rounded"
	      >
	        <summary class="flex items-center justify-between px-5 py-3 bg-gray-100 lg:hidden">
	          <span class="text-sm font-medium">
	            Property Filters
	          </span>

	          <svg class="w-5 h-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
	            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16"/>
	          </svg>
	        </summary>

	        <form
	          action=""
	          class="border-t border-gray-200 lg:border-t-0"
	        >
	          <fieldset>
	            <legend class="block w-full px-5 py-3 text-xs font-medium bg-gray-50">
	              Bathrooms
	            </legend>

	            <div class="px-5 py-6 space-y-2 bg-[#9d2235]">
	              <div class="flex items-center" v-for="i in 4">
	                <input
	                  :id="`bathrooms${i}`"
	                  type="radio"
	                  :value="i"
	                  v-model="filters.bathroom"
	                  :name="`bathrooms${i}`"
	                  class="w-5 h-5 border-gray-300 rounded-full "
	                />

	                <label
	                  for="Bathrooms"
	                  v-if="i != 4"
	                  class="ml-3 text-sm font-medium text-white"
	                >
	                 {{(i == 1) ? `${i} bathroom ` : `${i} bathrooms `}}
	                </label>
	                <label
	                  for="Bathrooms"
	                  v-else
	                  class="ml-3 text-sm font-medium text-white"
	                >
	                  {{`4+ Bathrooms`}}
	                </label>
	              </div>

	            </div>
	          </fieldset>

	          <div>
	            <fieldset>
	              <legend class="block w-full px-5 py-3 text-xs font-medium bg-gray-50">
	                Bedrooms
	              </legend>

	              <div class="px-5 py-6 space-y-2 bg-[#9d2235]" >
	                <div class="flex items-center" v-for="i in 4">
	                  <input
	                  :id="`bedrooms${i}`"
	                  type="radio"
	                  v-model="filters.bedroom"
	                  :value="i"
	                  :name="`bedrooms${i}`"
	                    class="w-5 h-5 border-gray-300 rounded-full"
	                  />

	                  <label
	                    for="3+"
	                    v-if="i != 4"
	                    class="ml-3 text-sm font-medium text-white"
	                  >
	                    {{(i == 1) ? `${i} bedroom ` : `${i} bedrooms `}}
	                  </label>
	                  <label
	                    for="3+"
	                    v-else
	                    class="ml-3 text-sm font-medium text-white"
	                  >
	                    {{`4+ Bedrooms`}}
	                  </label>
	                </div>

	              </div>
	            </fieldset>
	          </div>

	          <div>
	            <fieldset>
	              <legend class="block w-full px-5 py-3 text-xs font-medium bg-gray-50">
	                Amenities
	              </legend>

	              <div class="px-5 py-6 space-y-3" >
	                <div class="flex flex-wrap items-center">
	                	<div class="flex flex-wrap  gap-2">
	                	    <span v-for="amenity in resources" :class="{'bg-green-600 text-white': checkedAmenities.includes(amenity)}" class="inline-flex gap-1 items-center text-xs justify-start border border-slate-700 rounded-xl py-1 px-2 text-sm" @click="addAmenity(amenity)">
	                	        <span>{{amenity}}</span>
	                	    </span>
	                	</div>
	                </div>

	              </div>
	            </fieldset>
	          </div>

	          <div class="flex justify-between px-5 py-3 border-t border-gray-200">
	            <button
	              @click="resetFilters"
	              name="reset"
	              type="button"
	              class="text-xs font-medium text-gray-600 underline rounded"
	            >
	              Reset All
	            </button>

	            <button
	              @click="applyFilters"
	              name="commit"
	              type="button"
	              class="px-5 py-3 text-xs font-medium text-white bg-[#9d2235] rounded"
	            >
	              Apply Filters
	            </button>
	          </div>
	        </form>
	      </details>
	    </div>

	      <div class="lg:col-span-3">
	        <div class="flex items-center justify-between">
	          <p class="text-sm text-gray-500">
	            <span class="hidden sm:inline">
	              Showing
	            </span>
	            Filtered Properties
	          </p>

	          <div class="ml-4">
	            <label
	              for="SortBy"
	              class="sr-only"
	            >
	              Sort
	            </label>

	            <select
	              id="SortBy"
	              name="sort_by"
	              v-model="sort"
	              class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-[#006f51] focus:border-[#006f51] block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-[#006f51] dark:focus:border-[#006f51]"
	            >
	              <option value="title-asc">Title, A-Z</option>
	              <option value="title-desc">Title, Z-A</option>
	              <option value="price-asc">Price, Low-High</option>
	              <option value="price-desc">Price, High-Low</option>
	            </select>
	          </div>
	        </div>

	        <div class="grid grid-cols-1 gap-px mt-4  border border-gray-200 sm:grid-cols-2 lg:grid-cols-3">
				<filter-item v-for="listing in filtered" :listing="listing"/>
	        </div>
	      </div>
	    </div>
	  </div>
	</section>
</template>

<script>
import FilterItem from './filterItem'
import properties from '../mixins/properties'
import filterMixin from '../mixins/filter'
import propams from '../mixins/amenities'

export default {
	components: {
		FilterItem
	},
	mixins: [properties, filterMixin, propams],
	mounted(){
		this.readAll()
	}
}

</script>
