import axios from 'axios'
import api from './api'
import { v4 as uuidv4 } from 'uuid'
import Cookies from 'js-cookie'

const ImageUploader = {
	data: () => {
		return {
			arrayFiles: [],
			fileList: [],
			uploads: [],
			loading: false
		}
	},
	methods: {
		deleteItem(index){
		  let filesx = JSON.parse(JSON.stringify(this.fileList))
		  filesx.splice(index, 1)
		  this.arrayFiles.splice(index, 1)
		  this.fileList.splice(index, 1)
		  this.$emit('update:fileList', [this.images, this.imageIndex])	
		  // if (this.images.length == 0) {
		  // 	this.dialog = false
		  // }
		},

		multipleUploadx(e){
		  var a = this.$refs.inputsx.files
		  Array.from(a).forEach(file => { 
		  	var reader  = new FileReader()
		  	reader.onloadend =  ()=> {
		  	  console.log(reader.result)
		  	  this.arrayFiles.push({src:reader.result})
		  	  this.fileList.push({src:file})	
		  	}

		  	if (file) {
		  	  reader.readAsDataURL(file)
		  	} 

		   })

		},
		getImages(){
			api.get('file/').then( (r) => {
				console.log(r.data)
			})
		},
		uploadImage(){
		  	const Upload = (file, fileName, callbackProgress, callback) => {
		  	  const config = {
		  	    onUploadProgress: function (progressEvent) {
		  	      var percentCompleted = Math.round(
		  	        (progressEvent.loaded * 100) / progressEvent.total
		  	      )
		  	      callbackProgress(percentCompleted)
		  	    },
		  	    headers: {
		  	    	'Content-Type': 'multipart/form-data',
		  	    	'X-CSRFToken': Cookies.get('csrftoken')
		  	    },
		  	  }

		  	  let data = new FormData()

		  	  data.append('file', file )
		  	  data.append('filename', fileName)

		  	  api.post('files/', data, config)
		  	    .then((res) => callback(res))
		  	    .catch((err) => {
		  	    	this.load = false
		  	    	this.alert = true
		  	    	this.message = 'Oops, something went wrong try again later'
		  	    })
		  	}

		  	this.loading = true

		  	var i = null

		  	for (i = this.fileList.length - 1; i >= 0; i--) {
		  		console.log(i)
		  		var blob = this.fileList[i].src
		  		var uuid = uuidv4()


		  		var str = blob.type.split('/')
		  		var fileName = uuid + '.' + str[1]


		  		var file = new File([blob], fileName, {type: blob.type})

		  		this.load = true

		  		Upload(file, fileName, (percent) => {
		  			// this.uploadProgress = percent
		  			this.progress = percent
		  		}, (result) => {
		  			this.uploads.push(result.data)
		  			
		  			if (this.uploads.length == this.fileList.length) {
		  				this.$store.commit('SET_IMAGES', this.uploads)
		  			}
		  		})
		  	}

		  	


		}
	}
}
export default ImageUploader