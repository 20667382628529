<script>
	import ImageUploader from '../mixins/imageUploader'
	import services from '../mixins/services'

	import auth from '../mixins/auth.js'

	import actuator from '../mixins/actuator.js'

	export default { 
		mixins: [auth,ImageUploader, services, actuator],
		mounted(){
			this.authenticated()
			if (this.$route.name == 'EditService') {
				this.title = this.$route.params.name
				this.read()
			}
			
		},
		methods: {
			setHtml(z){
				this.description = z
			}
		}
	}
</script>
<template>
	<section> 

	  <div class="relative mx-auto ">
	    <div class="grid grid-cols-1 vh-full">
	      <div class="bg-gray-50 md:py-24 text-left	">
	        <div class="max-w-lg px-4 mx-auto lg:px-8 mt-16">
	        	<div class="mx-auto my-12 ">
	        	<h1 >{{($route.name == 'EditService') ? 'Edit' : 'Add'}} Services</h1>
	        	<a class="underline my-8" @click="$router.go(-1)">&larr;  Back </a>
	        	</div>
	          <form class="grid grid-cols-6 gap-4">
	      
	          	<div class="col-span-6">
	          	  <label class="block mb-1 text-sm text-gray-600" for="title">
	          	    Service Image
	          	  </label>
	          	  <div class="relative p-8 text-center border border-gray-200 rounded-lg" v-if="arrayFiles.length == 0">
	          	    <h2 class="text-2xl font-medium">
	          	      There's nothing here...
	          	    </h2>

	          	    <p class="mt-4 text-sm text-gray-500">
	          	      Uploaded images will appear here, try clicking below to add one!
	          	    </p>
	          	  </div>
	          	  <ul class=" mb-8" >
	          	    <li  v-for="(image, i) in arrayFiles" :key="i">
	          	      <a
	          	        class="block shadow-xl"
	          	      >


	          	        <div class="aspect-w-1 aspect-h-1">
	          	          <img
	          	            loading="lazy"
	          	            alt="Smart Watch"
	          	            class="object-cover" 
	          	            :src="image.src"
	          	          />
	          	        </div>

	          	      </a>
	          	      <div class="text-center" >
	          	      	<a @click="deleteItem(i)">
	          	      		<vue-feather type=x></vue-feather>
	          	      	</a>

	          	      </div>
	          	      
	          	    </li>

	          	  </ul>



	          	  <div class="flex justify-center mt-8" v-if="arrayFiles.length == 0">
	          	      <div class="max-w-xs rounded-lg bg-transparent">
	          	          <div class="m-4">
	          	              <label class="inline-block mb-2 text-gray-500">File Upload</label>
	          	              <div class="flex items-center justify-center w-full">
	          	                  <label
	          	                      class="flex flex-col w-full h-32 border-4 border-[#126f51] border-dashed hover:bg-gray-100 hover:border-gray-300">
	          	                      <div class="flex flex-col items-center justify-center pt-7">
	          	                          <svg xmlns="http://www.w3.org/2000/svg" class="w-8 h-8 text-gray-400 group-hover:text-gray-600"
	          	                              fill="none" viewBox="0 0 24 24" stroke="currentColor">
	          	                              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
	          	                                  d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12" />
	          	                          </svg>
	          	                          <p class="pt-1 text-sm tracking-wider text-gray-400 group-hover:text-gray-600">
	          	                              Attach a file</p>
	          	                      </div>
	          	                      <input type="file" class="opacity-0" ref="inputsx" accept="image/*"  @change="multipleUploadx"/>
	          	                  </label>
	          	              </div>
	          	          </div>
	          	      </div>
	          	  </div> 
	          	</div>

	          	<div class="justify-center mx-auto ">
	          		<a
	          		  class="block shadow-xl"
	          		  v-if="$route.name == 'EditService' && arrayFiles.length == 0 && loaded"
	          		>


	          		  <div class="aspect-w-1 aspect-h-1">
	          		    <img
	          		      loading="lazy"
	          		      alt="Smart Watch"
	          		      class="object-cover"
	          		      :src="parse(image)"
	          		    />
	          		  </div>

	          		</a>
	          	</div>



	            <div class="col-span-6">
	              <label class="block mb-1 text-sm text-gray-600" for="title">
	                Title 
	              </label>

	              <input
	                class="rounded-lg shadow-sm border-gray-200 focus:border-[#126f51] focus:ring-2 focus:ring-[#126f51] w-full text-sm p-2.5"
	                type="text"
	                v-model="title"
	                id="title"
	              />
	            </div>


	            <div class="col-span-6">
	              <label class="block mb-1 text-sm text-gray-600" for="email">
	                Email 
	              </label>

	              <input
	                class="rounded-lg shadow-sm border-gray-200 focus:border-[#126f51] focus:ring-2 focus:ring-[#126f51] w-full text-sm p-2.5"
	                type="text"
	                v-model="email"
	                id="email"
	              />
	            </div>

	            <div class="col-span-6">
	              <label class="block mb-1 text-sm text-gray-600" for="description">
	                Description
	              </label>

	              <wysiwyg 
	                v-if="loaded"
	              	@write="setHtml" 
	              	:isEdit="true" 
	              	:editContent="description"
	              	></wysiwyg>
	              	<wysiwyg 
	              	v-else
              		@write="setHtml" 
              		:isEdit="false" 
              		:editContent="description"
              		></wysiwyg>
	            </div>


	           


	          </form>
	          <div class="col-span-6 my-8">
	            <button
	              v-if="!loading && arrayFiles.length != 0"
	            	@click="uploadImage"
	              class="rounded-lg bg-[#126f51] text-sm p-2.5 text-white block">
	              Proceed
	            </button>
	            <loader v-if="loading"></loader>
	          </div>
	          <div class="col-span-6 mt-5">
	            <button
	              v-if="!loading && $route.name == 'EditService' && arrayFiles.length == 0"
	              @click="edit"
	              class="rounded-lg bg-[#126f51] text-sm p-2.5 text-white block">
	              Edit
	            </button>
	          </div>
	        </div>
	      </div>


	    </div>
	  </div>
	  <div class="h-6"></div>
	</section>
</template>