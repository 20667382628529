<script>
	// import Swiper JS
	import Swiper from 'swiper'
	// import Swiper styles
	import 'swiper/css'
	import ImageUploader from '../mixins/imageUploader'
	import actuator from '../mixins/actuator'
	import properties from '../mixins/properties'
	import autocomplete from './autocomplete'
 
 	import propams from '../mixins/amenities'
	import auth from '../mixins/auth.js'

	export default {
		components: {
			autocomplete
		},
		mixins: [auth,ImageUploader, properties, actuator, propams],
		mounted(){
			this.authenticated()

			if (this.$route.name == 'EditListing') {
				this.uuid = this.$route.params.uuid
				this.read(this.$route.params.uuid)
			}

			new Swiper('.swiper', {
			  loop: true, 
			  spaceBetween: 32,
			  slidesPerView: 4,
			  pagination: {
			    clickable: true,
			    el: '.swiper-pagination',
			  },
			  breakpoints: {
			    640: {
			      slidesPerView: 4,
			    },
			    1024: {
			      slidesPerView: 4,
			    },
			  },
			})


		},
		methods: {
			complete(y){
				this.rawLocation = y
			},
			setHtml(z){
				this.description = z
			}
		}
	}
</script>
<template>
	<section>

	  <div class="relative mx-auto ">
	    <div class="grid grid-cols-1 vh-full">
	      <div class="bg-gray-50 md:py-24 text-left	">
	        <div class="max-w-lg px-4 mx-auto lg:px-8 mt-16">
	        	<div class="mx-auto my-12 ">
	        	<h1 >Create Listing</h1>
	        	<a class="underline my-8" @click="$router.go(-1)">&larr;  Back </a>
	        	</div>
	          <form class="grid grid-cols-6 gap-4">
	      
	          	<div class="col-span-6">
	          	  <label class="block mb-1 text-sm text-gray-600" for="title">
	          	    Images
	          	  </label>
	          	  <div class="flex justify-center mt-8">
	          	      <div class="max-w-xs rounded-lg bg-transparent">
	          	          <div class="m-4">
	          	              <label class="inline-block mb-2 text-gray-500">File Upload</label>
	          	              <div class="flex items-center justify-center w-full">
	          	                  <label
	          	                      class="flex flex-col w-full h-32 border-4 border-[#126f51] border-dashed hover:bg-gray-100 hover:border-gray-300">
	          	                      <div class="flex flex-col items-center justify-center pt-7">
	          	                          <svg xmlns="http://www.w3.org/2000/svg" class="w-8 h-8 text-gray-400 group-hover:text-gray-600"
	          	                              fill="none" viewBox="0 0 24 24" stroke="currentColor">
	          	                              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
	          	                                  d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12" />
	          	                          </svg>
	          	                          <p class="pt-1 text-sm tracking-wider text-gray-400 group-hover:text-gray-600">
	          	                              Attach a file</p>
	          	                      </div>
	          	                      <input type="file" class="opacity-0" ref="inputsx" accept="image/*" multiple  @change="multipleUploadx"/>
	          	                  </label>
	          	              </div>
	          	          </div>
	          	      </div>
	          	  </div> 
	          	  <div class="relative p-8 text-center border border-gray-200 rounded-lg" v-if="arrayFiles.length == 0">
	          	    <h2 class="text-2xl font-medium">
	          	      There's nothing here...
	          	    </h2>

	          	    <p class="mt-4 text-sm text-gray-500">
	          	      Uploaded images will appear here, try clicking below to add one!
	          	    </p>
	          	  </div>



	          	</div>

	          	<div class="col-span-6" >
	          		<div class="mt-8 swiper">
	          		  <ul class="swiper-wrapper mb-8"  v-if="arrayFiles.length != 0">
	          		    <li class="swiper-slide " v-for="(image, i) in arrayFiles" :key="i">
	          		      <a
	          		        class="block shadow-xl"
	          		      >


	          		        <div class="aspect-w-1 aspect-h-1">
	          		          <img
	          		            loading="lazy"
	          		            alt="Smart Watch"
	          		            class="object-cover"
	          		            :src="image.src"	
	          		          />
	          		        </div>

	          		      </a>
	          		      <div class="text-center" >
	          		      	<a @click="deleteItem(i)">
	          		      		<vue-feather type=x></vue-feather>
	          		      	</a>

	          		      </div>
	          		      
	          		    </li>

	          		  </ul>
	          		</div>
	          		<div class="mt-8" v-if="$route.name == 'EditListing' ">
	          			<label class="block mb-1 text-sm text-gray-600" for="title">
	          			 Contemporary Images
	          			</label>
	          		  <ul class="flex flex-wrap -m-4 " v-if="tems != '[]' || tems != []">
	          		    <li class="p-4 w-1/3 " v-for="(image, i) in tems" :key="i">
	          		      <a
	          		        class="block shadow-xl"
	          		      >


	          		        <div class="aspect-w-1 aspect-h-1">
	          		          <img
	          		            loading="lazy"
	          		            alt="Smart Watch"
	          		            class="object-cover"
	          		            :src="image.file"
	          		          />
	          		        </div>

	          		      </a>
	          		      <div class="text-center" >
	          		      	<a @click="deleteTems(image.filename)">
	          		      		<vue-feather type=x></vue-feather>
	          		      	</a>

	          		      </div>
	          		      
	          		    </li>

	          		  </ul>
	          		  <div class="my-16">
	          		  	<a v-if="undoItems.length != 0" @click="undo">
	          		  		<vue-feather type=refresh-ccw></vue-feather>
	          		  		<p>Undo Delete</p>
	          		  	</a>
	          		  </div>
	          		</div>
	          	</div>


	            <div class="col-span-6">
	              <label class="block mb-1 text-sm text-gray-600" for="title">
	                Title
	              </label>

	              <input
	                class="rounded-lg shadow-sm border-gray-200 focus:border-[#126f51] focus:ring-2 focus:ring-[#126f51] w-full text-sm p-2.5"
	                type="text"
	                v-model="title"
	                id="title"
	              />
	            </div>
 

	            <div class="col-span-6">
	              <label class="block mb-1 text-sm text-gray-600" for="title">
	                Description
	              </label>
	              <wysiwyg 
	                v-if="loaded"
	              	@write="setHtml" 
	              	:isEdit="true" 
	              	:editContent="description"
	              	></wysiwyg>
	              	<wysiwyg 
	              	v-else
              		@write="setHtml" 
              		:isEdit="false" 
              		:editContent="description"
              		></wysiwyg>
	            </div>

	            <div class="col-span-6">
	            	<label class="block mb-1 text-sm text-gray-600">
	            		Status
	            	</label>
	            	<div class="flex items-center ">
	            	    <input id="default-radio-1" type="radio" v-model="status" value="For Let"  class="w-4 h-4 text-[#126f51] bg-gray-100 border-gray-300 focus:ring-[#126f51] dark:focus:ring-[#126f51] dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
	            	    <label for="default-radio-1" class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">To Let</label>
	            	</div>
	            	<div class="flex items-center">
	            	    <input id="default-radio-2" type="radio" v-model="status" value="For Sale"  class="w-4 h-4 text-[#126f51] bg-gray-100 border-gray-300 focus:ring-[#126f51] dark:focus:ring-[#126f51] dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
	            	    <label for="default-radio-2" class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">For Sale</label>
	            	</div>
	            	<div class="flex items-center">
	            	    <input id="status-radio-2" type="radio" v-model="status" value="Project"  class="w-4 h-4 text-[#126f51] bg-gray-100 border-gray-300 focus:ring-[#126f51] dark:focus:ring-[#126f51] dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
	            	    <label for="status-radio-2" class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">Project</label>
	            	</div>
	            </div>

	            <div class="col-span-6">
	            	<label class="block mb-1 text-sm text-gray-600">
	            		Visibility
	            	</label>
	            	<div class="flex items-center ">
	            	    <input id="default-radio-4" type="radio" v-model="visibility" value="Public"  class="w-4 h-4 text-[#126f51] bg-gray-100 border-gray-300 focus:ring-[#126f51] dark:focus:ring-[#126f51] dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
	            	    <label for="default-radio-4" class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">Public</label>
	            	</div>
	            	<div class="flex items-center">
	            	    <input id="default-radio-9" type="radio" v-model="visibility" value="Private"  class="w-4 h-4 text-[#126f51] bg-gray-100 border-gray-300 focus:ring-[#126f51] dark:focus:ring-[#126f51] dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
	            	    <label for="default-radio-9" class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">Private</label>
	            	</div>
	            </div>

	            <div class="col-span-6">
	            	<label class="block mb-1 text-sm text-gray-600">
	            		Showcase
	            	</label>
	            	<div class="flex items-center ">
	            	    <input id="default-radio-11" type="radio" v-model="showcase" value="True"  class="w-4 h-4 text-[#126f51] bg-gray-100 border-gray-300 focus:ring-[#126f51] dark:focus:ring-[#126f51] dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
	            	    <label for="default-radio-11" class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">Showcase</label>
	            	</div>
	            	<div class="flex items-center">
	            	    <input id="default-radio-21" type="radio" v-model="showcase" value="False"  class="w-4 h-4 text-[#126f51] bg-gray-100 border-gray-300 focus:ring-[#126f51] dark:focus:ring-[#126f51] dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
	            	    <label for="default-radio-21" class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">Do not showcase</label>
	            	</div>
	            </div>

	            <div class="col-span-6">
	            	<label class="block mb-1 text-sm text-gray-600">
	            		Availability
	            	</label>
	            	<div class="flex items-center ">
	            	    <input id="availability-radio-1" type="radio"  v-model="availability" value="Available"  class="w-4 h-4 text-[#126f51] bg-gray-100 border-gray-300 focus:ring-[#126f51] dark:focus:ring-[#126f51] dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
	            	    <label for="availability-radio-1" class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">Available</label>
	            	</div>
	            	<div class="flex items-center">
	            	    <input id="availability-radio-2" type="radio" v-model="availability"  value="Sold"  class="w-4 h-4 text-[#126f51] bg-gray-100 border-gray-300 focus:ring-[#126f51] dark:focus:ring-[#126f51] dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
	            	    <label for="availability-radio-2" class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">Sold</label>
	            	</div>
	            </div>


	            <div class="col-span-6">
	            	<label class="block mb-1 text-sm text-gray-600">
	            		Category
	            	</label>
	            	<div class="flex items-center ">
	            	    <input id="category-radio-1" type="radio" value="Residential"  v-model="category" name="category-radio" class="w-4 h-4 text-[#126f51] bg-gray-100 border-gray-300 focus:ring-[#126f51] dark:focus:ring-[#126f51] dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
	            	    <label for="category-radio-1" class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">Residential </label>
	            	</div>
	            	<div class="flex items-center">
	            	    <input  id="category-radio-2" type="radio" value="Offices" v-model="category" name="category-radio" class="w-4 h-4 text-[#126f51] bg-gray-100 border-gray-300 focus:ring-[#126f51] dark:focus:ring-[#126f51] dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
	            	    <label for="category-radio-2" class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">Offices</label>
	            	</div>
	            	<div class="flex items-center">
	            	    <input  id="category-radio-3" type="radio" value="Land" v-model="category" name="category-radio" class="w-4 h-4 text-[#126f51] bg-gray-100 border-gray-300 focus:ring-[#126f51] dark:focus:ring-[#126f51] dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
	            	    <label for="category-radio-3" class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">Land</label>
	            	</div>
	            	<div class="flex items-center">
	            	    <input  id="category-radio-4" type="radio" value="Industrial" v-model="category" name="category-radio" class="w-4 h-4 text-[#126f51] bg-gray-100 border-gray-300 focus:ring-[#126f51] dark:focus:ring-[#126f51] dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
	            	    <label for="category-radio-4" class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">Industrial</label>
	            	</div>
	            	<div class="flex items-center">
	            	    <input  id="category-radio-5" type="radio" value="Retail" v-model="category" name="category-radio" class="w-4 h-4 text-[#126f51] bg-gray-100 border-gray-300 focus:ring-[#126f51] dark:focus:ring-[#126f51] dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
	            	    <label for="category-radio-5" class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">Retail</label>
	            	</div>
	            </div>

                <fieldset class="col-span-6">
    	            <div class="flex -space-x-px">
                      <div class="flex-1">
                      	<div>
                      		<legend class="block mb-1 text-sm text-gray-600">
                      		 Subcategory
                      		</legend>
                      		<label class="sr-only" for="subcategory">Category	</label>

                      		<select
                      		  class="border-gray-200 relative rounded-t-lg w-full focus:ring-[#126f51] text-sm p-2.5"
                      		  id="subcategory"
                      		  name="subcategory"
                      		  v-model="subcategory"
                      		>
                      		  <optgroup label="Residential" v-if="category == 'Residential'">
                      		      <option>House</option>
                      		      <option>Serviced Apartment</option>
                      		      <option>Furnished Apartment</option>
                      		      <option>Villas</option>
                      		  </optgroup>
                      		  <optgroup label="Retail" v-if="category == 'Retail'">
                      		      <option>Retail</option>
                      		  </optgroup>
                      		  <optgroup label="Offices" v-if="category == 'Offices'">
                      		      <option>Serviced Co-Working</option>
                      		  </optgroup>
                      		  <optgroup label="Industrial" v-if="category == 'Industrial'">
                      		      <option>Industrial</option>
                      		  </optgroup>
                      		  <optgroup label="Land" v-if="category == 'Land'">
                      		      <option>Land</option>
                      		  </optgroup>
                      		 
                      		</select>
                      	</div>
                      </div>
                    </div>
                </fieldset>

                <fieldset class="col-span-6">
    	            <div class="flex -space-x-px">
                      <div class="flex-1">
                      	<div>
                      		<legend class="block mb-1 text-sm text-gray-600">
                      		 Location
                      		</legend>
                      		<label class="sr-only" for="gender">Location</label>

                      		<autocomplete @complete="complete"></autocomplete>
                      	</div>
                      </div>
                    </div>
                </fieldset>


                <div class="col-span-3" v-for="a in resources">
                   <input
                     :id="a"
                     type="checkbox"
                     :value="a"
                     v-model="checkedAmenities"
                     :name="a"
                     class="w-5 h-5 border-gray-300 rounded "
                   />

                   <label
                     for=""
                     class="ml-3 text-sm font-medium"
                   >
                     {{a}}
                   </label>
                 </div>


	            <div class="col-span-3">
	              <label class="block mb-1 text-sm text-gray-600" for="parking">
	                Parking
	              </label>

	              <input
	                class="rounded-lg shadow-sm border-gray-200 focus:border-[#126f51] focus:ring-2 focus:ring-[#126f51] w-full text-sm p-2.5"
	                v-model="parking"
	                type="text"
	                id="parking"
	              />
	            </div>

	            <div class="col-span-3">
	              <label class="block mb-1 text-sm text-gray-600" for="Bedrooms">
	                Bedrooms
	              </label>

	              <input
	                class="rounded-lg shadow-sm border-gray-200 focus:border-[#126f51] focus:ring-2 focus:ring-[#126f51] w-full text-sm p-2.5"
	                type="text"
	                id="Bedrooms"
	                v-model="bedroom"
	              />
	            </div>

	            <div class="col-span-6">
	              <label class="block mb-1 text-sm text-gray-600" for="Bedrooms">
	                Bathrooms
	              </label>

	              <input
	                class="rounded-lg shadow-sm border-gray-200 focus:border-[#126f51] focus:ring-2 focus:ring-[#126f51] w-full text-sm p-2.5"
	                type="text"
	                v-model="bathroom"
	                id="Bathrooms"
	              />
	            </div>

	            <div class="col-span-6">
	              <label class="block mb-1 text-sm text-gray-600" for="Size">
	                Size
	              </label>

	              <input
	                class="rounded-lg shadow-sm border-gray-200 focus:border-[#126f51] focus:ring-2 focus:ring-[#126f51] w-full text-sm p-2.5"
	                type="text"
	                id="Size"
	                v-model="size"
	              />
	            </div>

	            <label > Price Metadata</label>

	            <fieldset class="col-span-6">
	              <legend class="block mb-1 text-sm text-gray-600">
	               Price
	              </legend>

	              <div class="-space-y-px bg-white rounded-lg shadow-sm">
	                <div>
	                  <label class="sr-only" for="id-number">Price</label>

	                  <input
	                    class="border-gray-200 focus:border-[#126f51] focus:ring-2 focus:ring-[#126f51] relative rounded-t-lg w-full focus:z-10 text-sm p-2.5 placeholder-gray-400"
	                    type="number"
	                    name="Price"
	                    id="Price"
	                    v-model="price"
	                    :placeholder="`Service Charge in ${currency}`"
	                  />
	                </div>

	              </div>

	            </fieldset>

	            <fieldset class="col-span-6">


	              <div class="-space-y-px bg-white rounded-lg shadow-sm">
	                <div>
	                  <legend class="block mb-1 text-sm text-gray-600">
	                  	Currency
	                  </legend>

	                  <input
	                    class="border-gray-200 focus:border-[#126f51] focus:ring-2 focus:ring-[#126f51] relative rounded-t-lg w-full focus:z-10 text-sm p-2.5 placeholder-gray-400"
	                    type="text"
	                    name="Currency"
	                    id="Currency"
	                    v-model="currency"
	                    placeholder="Currency"
	                  />
	                </div>

	              </div>

	            </fieldset>
	            <fieldset class="col-span-6">


	              <div class="-space-y-px bg-white rounded-lg shadow-sm">
	                <div>
	                  <legend class="block mb-1 text-sm text-gray-600">
	                  	Service Charge
	                  </legend>

	                  <input
	                    class="border-gray-200 focus:border-[#126f51] focus:ring-2 focus:ring-[#126f51] relative rounded-t-lg w-full focus:z-10 text-sm p-2.5 placeholder-gray-400"
	                    type="number"
	                    name="Service Charge"
	                    id="Service Charge"
	                    v-model="serviceCharge"
	                    :placeholder="`Service Charge in ${currency}`"
	                  />
	                </div>

	              </div>

	            </fieldset>
	            <div class="col-span-6">
	            	<label class="block mb-1 text-sm text-gray-600">
	            		Disclosure
	            	</label>
	            	<div class="flex items-center ">
	            	    <input id="disclosure-radio-1" type="radio" v-model="disclosure" value="Per square foot"  class="w-4 h-4 text-[#126f51] bg-gray-100 border-gray-300 focus:ring-[#126f51] dark:focus:ring-[#126f51] dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
	            	    <label for="disclosure-radio-1" class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">Per square foot</label>
	            	</div>
	            	<div class="flex items-center">
	            	    <input  id="disclosure-radio-2" type="radio" v-model="disclosure" value="Absolute Price"  class="w-4 h-4 text-[#126f51] bg-gray-100 border-gray-300 focus:ring-[#126f51] dark:focus:ring-[#126f51] dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
	            	    <label for="disclosure-radio-2" class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">Absolute Price</label>
	            	</div>
	            	<div class="flex items-center">
	            	    <input  id="disclosure-radio-3" type="radio" v-model="disclosure" value="Price on Application"  class="w-4 h-4 text-[#126f51] bg-gray-100 border-gray-300 focus:ring-[#126f51] dark:focus:ring-[#126f51] dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
	            	    <label for="disclosure-radio-3" class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">Price on Application</label>
	            	</div>
	            	<div class="flex items-center ">
	            	    <input id="disclosure-radio-4" type="radio" v-model="disclosure" value="Per Acre"  class="w-4 h-4 text-[#126f51] bg-gray-100 border-gray-300 focus:ring-[#126f51] dark:focus:ring-[#126f51] dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
	            	    <label for="disclosure-radio-4" class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">Per Acre</label>
	            	</div>
	            </div>



	          </form>
	          <div class="col-span-6 mt-5">
	            <button
	              v-if="!loading && arrayFiles.length != 0"
	            	@click="uploadImage"
	              class="rounded-lg bg-[#126f51] text-sm p-2.5 text-white block">
	              Proceed
	            </button>
	          </div>
	          <div class="col-span-6 mt-5">
	            <button
	              v-if="!loading && $route.name == 'EditListing' && arrayFiles.length == 0"
	              @click="edit"
	              class="rounded-lg bg-[#126f51] text-sm p-2.5 text-white  block">

	              Edit
	            </button>
	            <loader v-if="loading"></loader>
	          </div>
	        </div>
	      </div>

	    </div>
	  </div>

	</section>
</template>