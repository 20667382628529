<template>
    <div class="sticky top-0">
            <div class="w-full h-20 px-6 bg-gray-100 border-b flex items-center justify-between">

              <!-- left navbar -->
              <div class="flex">

                <!-- mobile hamburger -->
                <div class="inline-block lg:hidden flex items-center mr-4">
                  <button class="hover:text-blue-500 hover:border-white focus:outline-none navbar-burger" @click="toggleSidebar()">
                    <svg class="h-5 w-5" v-bind:style="{ fill: 'black' }" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><title>Menu</title><path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z"/></svg>
                  </button>
                </div>

                <div v-if="checkedLeads.length > 1" class="inline-flex rounded-lg border border-gray-100 bg-gray-100 p-1">
                  <!-- <p class="font-semibold inline-block rounded-md px-4 py-2 text-sm ">Selected Actions:</p> -->
                  <button
                     @click="reply('sms', lead)" 
                    class="inline-block rounded-md px-4 py-2 text-sm text-gray-500 hover:text-gray-700 focus:relative"
                  >
                    Sms
                  </button>

                  <button
                    @click="reply('email', lead)"
                    class="inline-block rounded-md px-4 py-2 text-sm text-gray-500 hover:text-gray-700 focus:relative"
                  >
                    Email
                  </button>

                  <button
                    @click="msModal = true"
                    class="inline-block rounded-md bg-white px-4 py-2 text-sm text-red-500 shadow-sm focus:relative"
                  >
                    Delete
                  </button>
                </div>

              </div> 

              <!-- right navbar -->
              <div class="flex items-center relative -mr-6">
                <button class="px-4" @click="logout">
                     
                    <svg class="h-4 w-4 fill-current" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 384.971 384.971" xml:space="preserve"><g><g id="Sign_Out"><path d="M180.455,360.91H24.061V24.061h156.394c6.641,0,12.03-5.39,12.03-12.03s-5.39-12.03-12.03-12.03H12.03
                        C5.39,0.001,0,5.39,0,12.031V372.94c0,6.641,5.39,12.03,12.03,12.03h168.424c6.641,0,12.03-5.39,12.03-12.03
                        C192.485,366.299,187.095,360.91,180.455,360.91z"/><path d="M381.481,184.088l-83.009-84.2c-4.704-4.752-12.319-4.74-17.011,0c-4.704,4.74-4.704,12.439,0,17.179l62.558,63.46H96.279
                        c-6.641,0-12.03,5.438-12.03,12.151c0,6.713,5.39,12.151,12.03,12.151h247.74l-62.558,63.46c-4.704,4.752-4.704,12.439,0,17.179
                        c4.704,4.752,12.319,4.752,17.011,0l82.997-84.2C386.113,196.588,386.161,188.756,381.481,184.088z"/></g><g></g><g></g><g></g><g></g><g></g><g></g></g>
                    </svg>
                </button>

              </div>

            </div>

            <!-- dropdown menu -->
            <div class="absolute bg-gray-100 border border-t-0 shadow-xl text-gray-700 rounded-b-lg w-48 bottom-10 right-0 mr-6" :class="dropDownOpen ? '' : 'hidden'">
                <a href="#" class="block px-4 py-2 hover:bg-gray-200">Account</a>
                <a href="#" class="block px-4 py-2 hover:bg-gray-200">Settings</a>
                <a href="#" class="block px-4 py-2 hover:bg-gray-200">Logout</a>
            </div>
            <!-- dropdown menu end -->

    </div>
    <!-- dropdown menu end -->
    <multiselect v-if="msModal" @cancel="msModal = false" @dSelected="dSelected" /> 
</template>

<script>
import Auth from '../mixins/auth'
import leads from '../mixins/leads'

export default {
    data() {
        return {
            dropDownOpen: false
        }
    },
    props: ['sideBarOpen'],
    mixins: [Auth, leads],
    methods: {
        toggleSidebar() {
            this.$emit('toggleSidebar', (this.sideBarOpen) ? false : true)
        }
    },
    computed: {
        checkedLeads: {
            get(){
                return this.$store.state.checkedLeads
            },
            set(x){
                this.$store.commit('SET_CHECKED_LEADS', x)
            }
        }
    }
}
</script>