<template>

	<div class="bg-white mt-0">
	  <footer class="max-w-screen-2xl px-4 md:px-8 mx-auto">
	    <div class="flex flex-col items-center border-t pt-6">
	      <!-- nav - start -->
	      <nav class="flex flex-wrap justify-center md:justify-start gap-x-4 gap-y-2 md:gap-6 mb-4">
	        <router-link to="/Services" class="text-gray-500 hover:text-[#006747] active:text-[#006747] transition duration-100">Services</router-link>

	        <router-link to="/Team" class="text-gray-500 hover:text-[#006747] active:text-[#006747] transition duration-100">Team</router-link>
	        <router-link to="/Contact" class="text-gray-500 hover:text-[#006747] active:text-[#006747] transition duration-100">Contact</router-link>
	        <router-link to="/Dashboard" class="text-gray-500 hover:text-[#006747] active:text-[#006747] transition duration-100">Dashboard</router-link>
	      </nav>
	      <!-- nav - end -->

	      <!-- social - start -->
	      <div class="flex gap-4">
	      	<a class="text-gray-500" href="https://web.facebook.com/Axis-Real-Estate-Ltd-101885241942112">
	      	  <svg fill="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-5 h-5" viewBox="0 0 24 24">
	      	    <path d="M18 2h-3a5 5 0 00-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 011-1h3z"></path>
	      	  </svg>
	      	</a>
	      	<a class="ml-3 text-gray-500" href="https://mobile.twitter.com/axis_real">
	      	  <svg fill="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-5 h-5" viewBox="0 0 24 24">
	      	    <path d="M23 3a10.9 10.9 0 01-3.14 1.53 4.48 4.48 0 00-7.86 3v1A10.66 10.66 0 013 4s-4 9 5 13a11.64 11.64 0 01-7 2c9 5 20 0 20-11.5a4.5 4.5 0 00-.08-.83A7.72 7.72 0 0023 3z"></path>
	      	  </svg>
	      	</a>
	      	<a class="ml-3 text-gray-500" href="https://instagram.com/axis_real">
	      		<svg fill="currentColor" xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 30 30" class="w-5 h-5">    <path d="M 9.9980469 3 C 6.1390469 3 3 6.1419531 3 10.001953 L 3 20.001953 C 3 23.860953 6.1419531 27 10.001953 27 L 20.001953 27 C 23.860953 27 27 23.858047 27 19.998047 L 27 9.9980469 C 27 6.1390469 23.858047 3 19.998047 3 L 9.9980469 3 z M 22 7 C 22.552 7 23 7.448 23 8 C 23 8.552 22.552 9 22 9 C 21.448 9 21 8.552 21 8 C 21 7.448 21.448 7 22 7 z M 15 9 C 18.309 9 21 11.691 21 15 C 21 18.309 18.309 21 15 21 C 11.691 21 9 18.309 9 15 C 9 11.691 11.691 9 15 9 z M 15 11 A 4 4 0 0 0 11 15 A 4 4 0 0 0 15 19 A 4 4 0 0 0 19 15 A 4 4 0 0 0 15 11 z"/></svg>
	      	</a>

	      	<a class="ml-3 text-gray-500" href="https://ke.linkedin.com/company/axis-real-estate-limited">
	      	  <svg fill="currentColor" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="0" class="w-5 h-5" viewBox="0 0 24 24">
	      	    <path stroke="none" d="M16 8a6 6 0 016 6v7h-4v-7a2 2 0 00-2-2 2 2 0 00-2 2v7h-4v-7a6 6 0 016-6zM2 9h4v12H2z"></path>
	      	    <circle cx="4" cy="4" r="2" stroke="none"></circle>
	      	  </svg>
	      	</a>
	      </div>
	      <!-- social - end -->
	    </div>

	    <div class="text-gray-400 text-sm text-center py-8">© {{currentYear}} -Axis Real Estate Ltd. All rights reserved.</div>
	  </footer>
	</div>

</template>
<script>
	export default {
		computed: {
			currentYear(){
				const d = new Date()
				let year = d.getFullYear()
				return year
			}
		}
	}
</script>