<template>
	<!--
  Heads up! 👋

  This component comes with some `rtl` classes. Please remove them if they are not needed in your project.

  Plugins:
    - @tailwindcss/forms
-->

<section class="bg-gray-50">
  <div class="p-8 md:p-12 lg:px-16 lg:py-24">
    <div class="mx-auto max-w-lg text-center">
      <h2 class="text-2xl font-bold text-gray-900 md:text-3xl">
        Find your dream home with our innovative property website
      </h2>

      <p class="hidden text-gray-500 sm:mt-4 sm:block">
      	Stay up-to-date on your dream property! Subscribe to our email alerts today and never miss out on new listings, price drops, or hot deals. Be the first to know and take the first step towards owning your dream home. Create alerts based on the following parameters similar to this property.
      </p>
    </div>
    <fieldset>
      <div class="px-5 py-6 space-y-3" >
        <div class="flex flex-wrap items-center">
        	<div class="flex flex-wrap text-center items-center mx-auto	 gap-2">
        	    <span v-for="a in alType" :class="{'bg-green-600 text-white': chAlerts.includes(a)}" class="inline-flex gap-1 items-center text-xs justify-start border border-slate-700 rounded-xl py-1 px-2 text-sm" @click="addAlert(a)">
        	        <span>{{a}}</span>
        	    </span>
        	</div>
        </div>

      </div>
    </fieldset>

    <div class="mx-auto mt-8 max-w-xl">
      <form action="#" class="sm:flex sm:gap-4">
        <div class="sm:flex-1">
          <label for="email" class="sr-only">Email</label>

          <input
            type="email"
            placeholder="Email address"
            class="w-full rounded-md border-gray-200 bg-white p-3 text-gray-700 shadow-sm transition focus:border-white focus:outline-none focus:ring focus:ring-yellow-400"
          />
        </div>

        <button
          type="submit"
          class="group mt-4 flex w-full items-center justify-center gap-2 rounded-md bg-red-800 px-5 py-3 text-white transition focus:outline-none focus:ring focus:ring-yellow-400 sm:mt-0 sm:w-auto"
        >
          <span class="text-sm font-medium"> Create Alert </span>

          <svg
            class="h-5 w-5 rtl:rotate-180"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M17 8l4 4m0 0l-4 4m4-4H3"
            />
          </svg>
        </button>
      </form>
    </div>
  </div>
</section>
</template>
<script>
	export default {
		data: () => {
			return {
				chAlerts: [],
				alType: [ 'parking', 'bathroom', 'bedroom', 'price', 'category', 'subcategory', 'size', 'location', 'status' ],

			}
		},
		methods:{
			addAlert(e){
				if (this.chAlerts.includes(e)) {
					this.chAlerts = this.chAlerts.filter(item => item != e)
				}else{
					this.chAlerts.push(e)
				}
			}
		}
	}
</script>
