<template>
	<section class="text-gray-600 body-font">
	  <div class="container px-5 py-24 mx-auto">
	    <div class="flex flex-col space-y-4 mb-9">
	        <div class="flex justify-start items-center space-x-2">
	            <span class="text-red-500">&#8212;&#8212;</span>
	            <h3 class="text-lg tracking-loose">Testimonials</h3>
	        </div>

	        <p class="text-5xl tracking-wide mb-7">
	           Why people love us
	        </p>
	    </div>
	    <div class="flex flex-wrap -m-4">
	      <div class="p-4 md:w-1/2 w-full">
	        <div class="h-full bg-gray-100 p-8 rounded">
	          <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="block w-5 h-5 text-gray-400 mb-4" viewBox="0 0 975.036 975.036">
	            <path d="M925.036 57.197h-304c-27.6 0-50 22.4-50 50v304c0 27.601 22.4 50 50 50h145.5c-1.9 79.601-20.4 143.3-55.4 191.2-27.6 37.8-69.399 69.1-125.3 93.8-25.7 11.3-36.8 41.7-24.8 67.101l36 76c11.6 24.399 40.3 35.1 65.1 24.399 66.2-28.6 122.101-64.8 167.7-108.8 55.601-53.7 93.7-114.3 114.3-181.9 20.601-67.6 30.9-159.8 30.9-276.8v-239c0-27.599-22.401-50-50-50zM106.036 913.497c65.4-28.5 121-64.699 166.9-108.6 56.1-53.7 94.4-114.1 115-181.2 20.6-67.1 30.899-159.6 30.899-277.5v-239c0-27.6-22.399-50-50-50h-304c-27.6 0-50 22.4-50 50v304c0 27.601 22.4 50 50 50h145.5c-1.9 79.601-20.4 143.3-55.4 191.2-27.6 37.8-69.4 69.1-125.3 93.8-25.7 11.3-36.8 41.7-24.8 67.101l35.9 75.8c11.601 24.399 40.501 35.2 65.301 24.399z"></path>
	          </svg>
	          
	          <p class="leading-relaxed mb-6">We hired Axis Real Estate team as our buyer agent because they are specifically trained in Short Sale & Foreclosure transactions. All in all I have no doubt that we would have failed to close without an experienced representative such as the professionals at Axis Real Estate working for us, and we are very grateful for all they did.</p>
	          <a class="inline-flex items-center">
	            <img alt="testimonial" class="w-12 h-12   object-cover object-center rounded-full inline-block border-2 border-gray-200 bg-gray-100" src="/static/Kihara_Vincent.jpg">
	            <span class="flex-grow flex flex-col pl-4">
	              <span class="title-font font-medium text-gray-900">Kihara Vincent</span>
	              <span class="text-gray-500 text-sm">Esteemed Customer</span>
	            </span>
	          </a>
	        </div>
	      </div>
	      <div class="p-4 md:w-1/2 w-full">
	        <div class="h-full bg-gray-100 p-8 rounded">
	        	<svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="block w-5 h-5 text-gray-400 mb-4" viewBox="0 0 975.036 975.036">
	        	  <path d="M925.036 57.197h-304c-27.6 0-50 22.4-50 50v304c0 27.601 22.4 50 50 50h145.5c-1.9 79.601-20.4 143.3-55.4 191.2-27.6 37.8-69.399 69.1-125.3 93.8-25.7 11.3-36.8 41.7-24.8 67.101l36 76c11.6 24.399 40.3 35.1 65.1 24.399 66.2-28.6 122.101-64.8 167.7-108.8 55.601-53.7 93.7-114.3 114.3-181.9 20.601-67.6 30.9-159.8 30.9-276.8v-239c0-27.599-22.401-50-50-50zM106.036 913.497c65.4-28.5 121-64.699 166.9-108.6 56.1-53.7 94.4-114.1 115-181.2 20.6-67.1 30.899-159.6 30.899-277.5v-239c0-27.6-22.399-50-50-50h-304c-27.6 0-50 22.4-50 50v304c0 27.601 22.4 50 50 50h145.5c-1.9 79.601-20.4 143.3-55.4 191.2-27.6 37.8-69.4 69.1-125.3 93.8-25.7 11.3-36.8 41.7-24.8 67.101l35.9 75.8c11.601 24.399 40.501 35.2 65.301 24.399z"></path>
	        	</svg>
	        	
	          <p class="leading-relaxed mb-6">The Axis Real Estate team did an outstanding job helping me buy my second home. The high level of service and dedication to seeing things done the right way is what I look   for. The Residential Agents delivered on that expectation and I would highly recommend them to anyone who is in the market to buy a home.</p>
	          <a class="inline-flex items-center">
	            <img alt="testimonial" class="w-12 h-12 object-cover object-center rounded-full inline-block border-2 border-gray-200 bg-gray-100" src="/static/Kiiru.jpg">	
	            <span class="flex-grow flex flex-col pl-4">
	              <span class="title-font font-medium text-gray-900">George Mwangi</span>
	              <span class="text-gray-500 text-sm">Pilot</span>
	            </span>
	          </a>
	        </div>
	      </div>
	    </div>
	  </div>
	</section>
</template>