<template>

</template>
<script>
	import actuator from '../mixins/actuator'
	
	export default {
		mixins: [actuator],
		props: ['listing', 'loaded', 'showViewer']
	}
</script>
