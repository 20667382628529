<template>
  <div class="flex justify-center items-center h-screen">
    <div class="bg-white shadow-none rounded px-8 pt-6 pb-8 mb-4 flex flex-col my-2">
      <!-- SMS notifications view code -->
      <div class="bg-white shadow rounded px-8 pt-6 pb-8 mb-4 flex flex-col my-2" v-show="!isEmpty(receivers)">
        <h2 class="text-xl font-bold mb-4 text-primary">Create SMS Notification</h2>
        <p>You have selected {{receivers.length}} number of sms receivers, write their message below</p>

        <form>
          <div class="mb-4">
            <label class="block text-gray-700 font-bold mb-2" for="message">
              Message
            </label>

            <textarea
              class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="message"
              name="message"
              v-model="message"
              rows="5"
            ></textarea>
          </div>
          <p> {{counter}} number of sms receivers, have received their message</p>
          <p> {{unsendables}} number of sms receivers, cannot receive their message</p>
          <div class="flex items-center justify-between">
            <button
              class="bg-[#126f51] hover:bg-green-600 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
              type="button"
              @click="loadcContacts"
              v-if="!loadbtn"
            >
              Create Notification
            </button>
            <loader v-else />
            <button
              class="text-red font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
              type="button"
              @click="$router.go(-1)"
            >
              Back
            </button>
          </div>
        </form>
      </div>
      <!--
        Heads up! 👋

        This component comes with some `rtl` classes. Please remove them if they are not needed in your project.
      -->

      <section
        v-show="isEmpty(receivers)"
        class="relative"
      >
        <div
          class="absolute inset-0 bg-white/75 sm:bg-transparent sm:from-white/95 sm:to-white/25 ltr:sm:bg-gradient-to-r rtl:sm:bg-gradient-to-l"
        ></div>

        <div
          class="relative mx-auto max-w-screen-xl px-4 py-32 sm:px-6 lg:flex lg:h-screen lg:items-center lg:px-8"
        >
          <div class="max-w-xl text-center ltr:sm:text-left rtl:sm:text-right">
            <h1 class="text-3xl font-extrabold sm:text-xl">
              Select Contacts

            </h1>

            <p class="mt-4 max-w-lg sm:text-xl/relaxed">
              To proceed select contacts you want to receive the sms from the leads tab
            </p>

            <div class="mt-8 mx-auto justify-center flex flex-wrap gap-4 text-center">
              <router-link
                to="/view/leads"
                class="block w-full rounded bg--600 px-12 py-3 text-sm font-medium shadow hover:bg--700 focus:outline-none focus:ring active:bg--500 sm:w-auto"
              >
               Proceed
              </router-link>

              <a
                @click="$router.go(-1)"
                class="block w-full rounded bg-white px-12 py-3 text-sm font-medium text--600 shadow hover:text--700 focus:outline-none focus:ring active:text--500 sm:w-auto"
              >
                Back
              </a>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>

</template>

<script >
  import SMS from '../mixins/sms'
  import actuator from '../mixins/actuator'

  export default {
    data: () => {
      return {
        loadbtn: false
      }
    },
    mixins: [SMS, actuator],
    computed: {
      receivers(){
        return this.$store.state.checkedLeads
      }
    },
    mounted(){
      this.message = this.preset
    },
    methods: {
      loadcContacts(){
        this.loadbtn = true
        this.cContacts()
      }
    }
  }
</script>

<style>
.primary {
  color: #126f51;
}
</style>
