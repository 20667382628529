<script>
	import StarterKit from '@tiptap/starter-kit'
	import { Editor, EditorContent } from '@tiptap/vue-3'

	export default {
	  data() {
	    return {
	      editor: null,
        content: `<blockquote><p>This line can be edited, <strong>click</strong> on one of the options above to change the way the text <strong><em>appears, as you would a word document</em></strong></p></blockquote>`
	    }
	  },

    props: ['isEdit', 'editContent'],
    components: {
      EditorContent,
    },

	  mounted() {
	    this.editor = new Editor({
	      extensions: [
	        StarterKit,
	      ],
	      content: (this.isEdit) ? this.editContent : this.content,
        onUpdate: ({ editor }) => {
           this.$emit('write', editor.getHTML())
        },
	    })
	  }
	}

</script>
<template>
     <div v-if="editor" class=" items-center -space-x-px text-xs rounded-md">
        <button class="px-5 py-3 font-medium border rounded-l-md hover:z-10 focus:outline-none focus:border-indigo-600 focus:z-10 hover:bg-gray-50 active:opacity-75" @click.prevent="editor.chain().focus().toggleBold().run()">
          bold
        </button>
        <button class="px-5 py-3 font-medium border rounded-l-md hover:z-10 focus:outline-none focus:border-indigo-600 focus:z-10 hover:bg-gray-50 active:opacity-75" @click.prevent="editor.chain().focus().toggleItalic().run()" >
          italic
        </button>
        <button class="px-5 py-3 font-medium border rounded-l-md hover:z-10 focus:outline-none focus:border-indigo-600 focus:z-10 hover:bg-gray-50 active:opacity-75" @click.prevent="editor.chain().focus().toggleStrike().run()" >
          strike
        </button>
        <button class="px-5 py-3 font-medium border rounded-l-md hover:z-10 focus:outline-none focus:border-indigo-600 focus:z-10 hover:bg-gray-50 active:opacity-75" @click.prevent="editor.chain().focus().toggleCode().run()" >
          code
        </button>
        <button class="px-5 py-3 font-medium border rounded-l-md hover:z-10 focus:outline-none focus:border-indigo-600 focus:z-10 hover:bg-gray-50 active:opacity-75" @click.prevent="editor.chain().focus().unsetAllMarks().run()">
          clear marks
        </button>
        <button class="px-5 py-3 font-medium border rounded-l-md hover:z-10 focus:outline-none focus:border-indigo-600 focus:z-10 hover:bg-gray-50 active:opacity-75" @click.prevent="editor.chain().focus().clearNodes().run()">
          clear nodes
        </button>
        <button class="px-5 py-3 font-medium border rounded-l-md hover:z-10 focus:outline-none focus:border-indigo-600 focus:z-10 hover:bg-gray-50 active:opacity-75" @click.prevent="editor.chain().focus().setParagraph().run()" >
          paragraph
        </button>
        <button class="px-5 py-3 font-medium border rounded-l-md hover:z-10 focus:outline-none focus:border-indigo-600 focus:z-10 hover:bg-gray-50 active:opacity-75" @click.prevent="editor.chain().focus().toggleHeading({ level: 1 }).run()">
          h1
        </button>
        <button class="px-5 py-3 font-medium border rounded-l-md hover:z-10 focus:outline-none focus:border-indigo-600 focus:z-10 hover:bg-gray-50 active:opacity-75" @click.prevent="editor.chain().focus().toggleHeading({ level: 2 }).run()" >
          h2
        </button>
        <button class="px-5 py-3 font-medium border rounded-l-md hover:z-10 focus:outline-none focus:border-indigo-600 focus:z-10 hover:bg-gray-50 active:opacity-75" @click.prevent="editor.chain().focus().toggleHeading({ level: 3 }).run()" >
          h3
        </button>
        <button class="px-5 py-3 font-medium border rounded-l-md hover:z-10 focus:outline-none focus:border-indigo-600 focus:z-10 hover:bg-gray-50 active:opacity-75" @click.prevent="editor.chain().focus().toggleHeading({ level: 4 }).run()" >
          h4
        </button>
        <button class="px-5 py-3 font-medium border rounded-l-md hover:z-10 focus:outline-none focus:border-indigo-600 focus:z-10 hover:bg-gray-50 active:opacity-75" @click.prevent="editor.chain().focus().toggleBulletList().run()" >
          bullet list
        </button>
        <button class="px-5 py-3 font-medium border rounded-l-md hover:z-10 focus:outline-none focus:border-indigo-600 focus:z-10 hover:bg-gray-50 active:opacity-75" @click.prevent="editor.chain().focus().toggleOrderedList().run()" >
          ordered list
        </button>
        <button class="px-5 py-3 font-medium border rounded-l-md hover:z-10 focus:outline-none focus:border-indigo-600 focus:z-10 hover:bg-gray-50 active:opacity-75" @click.prevent="editor.chain().focus().toggleCodeBlock().run()">
          code block
        </button>
        <button class="px-5 py-3 font-medium border rounded-l-md hover:z-10 focus:outline-none focus:border-indigo-600 focus:z-10 hover:bg-gray-50 active:opacity-75" @click.prevent="editor.chain().focus().toggleBlockquote().run()" >
          blockquote
        </button>
        <button class="px-5 py-3 font-medium border rounded-l-md hover:z-10 focus:outline-none focus:border-indigo-600 focus:z-10 hover:bg-gray-50 active:opacity-75" @click.prevent="editor.chain().focus().setHorizontalRule().run()">
          horizontal rule
        </button>
        <button class="px-5 py-3 font-medium border rounded-l-md hover:z-10 focus:outline-none focus:border-indigo-600 focus:z-10 hover:bg-gray-50 active:opacity-75" @click.prevent="editor.chain().focus().setHardBreak().run()">
          hard break
        </button>
        <button class="px-5 py-3 font-medium border rounded-l-md hover:z-10 focus:outline-none focus:border-indigo-600 focus:z-10 hover:bg-gray-50 active:opacity-75" @click.prevent="editor.chain().focus().undo().run()">
          undo
        </button>
        <button class="px-5 py-3 font-medium border rounded-l-md hover:z-10 focus:outline-none focus:border-indigo-600 focus:z-10 hover:bg-gray-50 active:opacity-75" @click.prevent="editor.chain().focus().redo().run()">
          redo
        </button>
      </div>
      <div class="mt-5">
      	<editor-content :editor="editor" />
      </div>


</template>
<style>
.ProseMirror {
	outline: none!important;
}
> * + * {
  margin-top: 0.75em;
}

ul,
ol {
  padding: 0 1rem;
}

h1,
h2,
h3,
h4,	
h5,
h6 {
  line-height: 1.1;
}
h1 {
	font-size: 3rem; /* 48px */
}
h2 {
  font-size: 2.5rem; /* 48px */
}
h3 {
  font-size: 2rem; /* 48px */
}
h4 {
  font-size: 1.5rem; /* 48px */
}

code {
  background-color: rgba(#616161, 0.1);
  color: #fff;
}

pre {
  background: #0D0D0D;
  color: #FFF;
  font-family: 'JetBrainsMono', monospace;
  padding: 0.75rem 1rem;
  border-radius: 0.5rem;

  code {
    color: inherit;
    padding: 0;
    background: none;
    font-size: 0.8rem;
  }
}

img {
  max-width: 100%;
  height: auto;
}

blockquote {
  padding-left: 1rem;
  border-left: 2px solid #000!important;
}

hr {
  border: none;
  border-top: 2px solid #000!important;
  margin: 2rem 0;
}
</style>