import axios from 'axios'
import api from './api'
import actuator from './actuator'

const leads = {
	data: () => {
		return {
			lead_id: '',
			active_lead_id: '',
			fname: '',
			lname: '',
			email: '',
			ccode: '',
			number: '',
			message: '',
			status: '',
			occupation: '',
			success: false,
			error: false,
			leads: [],
			loading: true,
			showDelete: false,
			lead: null,
			selectedContacts:[],
			replyModal: false,
			query: '',
			results: [],
			cleads: [],
			msModal: false,
			page: 1,
			count: null,
			next: null,
			previous: null,
			loadbtn: false
		}
	},
	mixins: [actuator],
	watch: {
		results(y){
			if (y.length === 0) {
				//cleads is current leads 
				this.cLeads = this.leads
			}else{
				this.cleads = y
			}
		},
		query(z){
			this.search()
		},
		page(){
			this.readAll()
		}
	},
	computed: {
		counter: {
			get(){
				return this.$store.state.multicount
			},
			set(x){
				this.$store.commit('SET_MULTICOUNT', x)
			}
		},
		aLeads: {
			get(){
				return this.$store.state.aLeads
			},
			set(x){
				this.$store.commit('SET_ALEADS_LEADS', x)
			}
		},
		checkedLeads: {
			get(){
				return this.$store.state.checkedLeads
			},
			set(x){
				this.$store.commit('SET_CHECKED_LEADS', x)
			}
		}
	},
	methods: {
		//check if email is empty before email send
		//check if number is empty before email send
		async uploadSpread(y){
			// Check if a contact with the same email or number already exists
			const contactExists = this.leads.some(contact => {
			  return contact.email === y.email || contact.number === y.number
			})

			if (contactExists) {
			  // A contact with the same email or number already exists
			  console.log("A contact with the same email or number already exists.")
			} else {

			  try {
			  	var f = new FormData()
			  	f.append('fname', y.first_name)
			  	f.append('lname', y.last_name)
			  	f.append('email', y.email_address)
			  	f.append('number', y.mobile_number)
			  	f.append('message', this.message) 
			  	f.append('status', this.status)
			  	f.append('occupation', this.occupation)
			  	f.append('ccode', this.ccode)

			    const response = await api.post('create/lead/', f)
			    const data = response.data
			    console.log(`Data ${i} created`)
			    // Do something with the data
			  } catch (error) {
			    console.error(error)
			  }
			}

		},	
		async lSpread(){
			for (var i = 0; i < this.spreadsheetData.length; i++) {
				await this.uploadSpread(this.spreadsheetData[i])
				this.counter++
			}	
		},
		async cSpreadsheet() { 
		  await this.lSpread()
		  console.log('Loop finished')
		  this.$router.push('/manage')
		},

		reply(type, lead){
			this.selectedContacts.push(lead)
			this.$store.commit('SET_SELECTED_CONTACTS', this.selectedContacts)
			if (type == 'email') {
				this.$router.push('/create/newsletter')
			}else{
				this.$router.push('/create/sms')
			}
		},	
		selectAll(){
			for (var i = 0; i < this.leads.length; i++) {
				this.checkedLeads.push(this.leads[i].lead_id)
				this.selectedContacts.push(this.leads[i])
				this.replyModal = true
			}	
			this.$store.commit('SET_SELECTED_CONTACTS', this.selectedContacts)
		},
		unselectAll(){
			this.checkedLeads = []
			this.selectedContacts  = []
		},
		create(){
			var f = new FormData()

			f.append('fname', this.fname)
			f.append('lname', this.lname)
			f.append('email', this.email)
			f.append('ccode', this.ccode)
			f.append('number', this.number)
			f.append('message', this.message)
			f.append('status', this.status)
			f.append('occupation', this.occupation)

			api.post('create/lead/', f).then( (r) => {
				this.success = true
				this.lead_id = r.data.lead_id
				if (this.propertyView) {
					this.AddLeadToProperty()
				}
			}, (e) => {
				this.error = true
				// const q = setTimeout(this.error = false, 5000)
			})
		},
		edit(lead_id, clead_id){
			var f = new FormData()

			f.append('lead_id', lead_id)
			f.append('fname', this.cleads[clead_id].fname)
			f.append('lname', this.cleads[clead_id].lname)
			f.append('email', this.cleads[clead_id].email)
			f.append('ccode', this.cleads[clead_id].ccode)
			f.append('number', this.cleads[clead_id].number)
			f.append('message', this.cleads[clead_id].message)
			f.append('status', this.cleads[clead_id].status)
			f.append('occupation', this.cleads[clead_id].occupation)

			api.post('edit/lead/', f).then( (r) => {
				this.$router.push('/manage')
			}, (e) => {
				this.error = true
				// const q = setTimeout(this.error = false, 5000)
			})
		},
		AddLeadToProperty(){
			var f = new FormData()

			f.append('listing_id', this.propertyId)
			f.append('lead_id', this.lead_id)

			api.post('addLeadToProperty/', f).then( (r) => {
				this.success = true
			}, (e) => {
				this.error = true
			})

		},
		GetLead(){
			var f = new FormData()

			f.append('lead_id', this.lead_id)

			api.post('lead/', f).then( (r) => {
				this.lead = r.data
			})
		},
		GetPropertyLeads(){
			var f = new FormData()

			f.append('listing_id', this.propertyId)

			api.post('getPropertyLeads/', f).then( (r) => {
				this.success = true
				this.leads = r.data
				this.loading = false
			}, (e) => {
				this.error = true
			})

		},
		// Gets leads with pagination
		readAll(){
			var f = new FormData()
			f.append('page', this.page)
			api.post('get/leads/', f).then( (r) => {
				// this.$router.push('/manage')
				this.leads = r.data.leads
				this.cleads = r.data.leads
				this.count = r.data.count
				this.next = r.data.next
				this.previous = r.data.previous
				this.loading = false
			}, (e) => {
				console.log(e)
			})
		},
		//Gets leads without pagination
		getALeads(){
			api.get('get/aleads/').then( (r) => {
				// this.$router.push('/manage')
				this.aLeads = r.data.leads
				this.loading = false 
				this.$store.state.leadLn = this.aLeads.length
			}, (e) => {
				console.log(e)
			})
		},
		initDel(y){
			this.lead_id = y
			this.showDelete = true
		},
		async loop() {
		  for (var i = 0; i < this.checkedLeads.length; i++) {
		  	this.lead_id = this.checkedLeads[i]
		    await this.mdData(this.checkedLeads[i])
		  }
		},
		async mdData(i) {
		  try {
		  	var f = new FormData()

		  	f.append('lead_id', this.lead_id)

		    const response = await api.post('delete/lead/', f)
		    const data = response.data
		    console.log(`Data ${i} fetched`)
		    // Do something with the data
		  } catch (error) {
		    console.error(error)
		  }
		},
		async dSelected() {
		  this.loadbtn = true
		  await this.loop()
		  this.readAll()
		  console.log('Loop finished')
		  this.$router.push('/manage')
		},

		// dSelected(){
		// 	for (var i = 0; i < this.checkedLeads.length; i++) {
		// 		this.lead_id = this.checkedLeads[i]
		// 		this.del()
		// 	}	
		// 	this.msModal = false
		// 	this.checkedLeads=[]
		// 	this.$router.go()
		// },
		multidelete(y){
			this.msModal = true
		},
		del(){
			var f = new FormData()

			f.append('lead_id', this.lead_id)

			api.post('delete/lead/', f).then( (r) => {
				this.readAll()
				this.showDelete = false
			})
		},
		search(){
			this.results = []
			const fuzzysort = require('fuzzysort')

			let results = fuzzysort.go(this.query, this.aLeads, {
			  keys: ['fname','lname', 'occupation', 'email'],
			  // Create a custom combined score to sort by. -100 to the desc score makes it a worse match
			  scoreFn: a => Math.max(a[0]?a[0].score:-1000, a[1]?a[1].score-100:-1000)
			})
			console.log(results)

			results.forEach( (result) => {
				if (result.score > -1000) {
					this.results.push(result.obj)
				}
			})

			// if (this.applyFilter) {
			// 	this.filter()
			// }
		}
	}
}

export default leads