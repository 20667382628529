<template>
	<!-- Alert -->

	<div
	  class="p-4 text-red-700 border rounded border-red-900/10 bg-red-50"
	  role="alert"
	>
	  <strong class="text-sm font-medium"> Something went wrong! </strong>

	  <p class="mt-1 text-xs">
	  	We couldn't complete the action as requested, please try again or contact support on 
	  	support@axisre.co.ke
	  </p>
	</div>
</template>