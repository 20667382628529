<template>
	<body class="w-full space-y-20 antialiased" v-if="loaded">
		<navbar></navbar>
		<top 
		 :propertiesHeading="propertiesHeading"
		 :propertiesText="propertiesText"
		></top>

		<filter-component />

		<newsletter />
		<foot></foot>
	</body>
</template>
<script >
	import Top from './top'
	import services from './services'
	import Properties from './properties'
	import Testimonials from './testimonials'
	import Foot from './foot'
	import Navbar from './navbar'
	import Team from './team'
	import Contact from './contact'
	import About from './about'
	import FilterComponent from './filter'
	import narratives from '../mixins/narratives.js'
	import newsletter from './newsletter'

	export default {
		mixins: [narratives],
		components: {
			Top,
			About,
			services,
			Properties,
			Testimonials,
			Foot,
			Navbar, 
			Team,
			Contact,
			FilterComponent,
			newsletter

		},
		mounted(){
			this.readAllNarratives()
		}


	}
</script>