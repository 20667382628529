<template>
  <div class="container mx-auto my-8 p-4">
    <h1 class="text-2xl font-bold mb-4">Mortgage Calculator</h1>
    <div class="flex flex-wrap">
      <div class="w-full md:w-1/2 mb-4 md:pr-4">
        <form @submit.prevent="calculateMortgage">
          <div class="mb-4">
            <label for="loanAmount" class="block font-bold mb-2">Loan Amount</label>
            <input type="number" v-model="loanAmount" id="loanAmount" class="w-full px-3 py-2 border rounded" required>
          </div>
          <div class="mb-4">
            <label for="interestRate" class="block font-bold mb-2">Interest Rate</label>
            <input type="number" v-model="interestRate" id="interestRate" class="w-full px-3 py-2 border rounded" required>
          </div>
          <div class="mb-4">
            <label for="loanTerm" class="block font-bold mb-2">Loan Term (Years)</label>
            <input type="number" v-model="loanTerm" id="loanTerm" class="w-full px-3 py-2 border rounded" required>
          </div>
          <button type="submit" class="bg-green-700 hover:bg-green-600 text-white font-bold py-2 px-4 rounded">
            Calculate
          </button>
        </form>
      </div>
      <div class="w-full md:w-1/2 mb-4 md:pl-4">
        <div class="bg-gray-100 p-4">
          <h2 class="text-lg font-bold mb-4">Monthly Payment</h2>
          <p class="text-xl font-bold mb-4" v-if="monthlyPayment">KES{{ monthlyPayment.toFixed(2) }}</p>
          <p class="text-sm text-gray-600">Loan Amount: KES{{ loanAmount }}</p>
          <p class="text-sm text-gray-600">Interest Rate: {{ interestRate }}%</p>
          <p class="text-sm text-gray-600">Loan Term: {{ loanTerm }} years</p>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
export default {
  data() {
    return {
      loanAmount: 0,
      interestRate: 0,
      loanTerm: 0,
      monthlyPayment: null,
    };
  },
  watch:{
  	loanAmount(){
  		this.calculateMortgage()
  	},
  	interestRate(){
  		this.calculateMortgage()
  	},
  	loanTerm(){
  		this.calculateMortgage()
  	}
  },
  methods: {
    calculateMortgage() {
      const principal = this.loanAmount;
      const interest = this.interestRate / 100 / 12;
      const payments = this.loanTerm * 12;

      const x = Math.pow(1 + interest, payments);
      const monthly = (principal * x * interest) / (x - 1);

      this.monthlyPayment = monthly;
    },
  },
};
</script>

<style>
/* You can add any custom styles or use Tailwind CSS classes here */
</style>
