<template>
   <div class="container mx-auto">
      <div class="flex justify-between mb-4">
         <h2 class="text-2xl font-bold">Property Developers</h2>
         <button @click="showForm = true; formTitle = 'Create Property Developer'" class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
         Create Developer
         </button>
      </div>
      <div v-if="showForm" class="bg-white p-6 rounded-lg shadow-lg">
         <h3 class="text-lg font-bold mb-2">{{ formTitle }}</h3>
         <form class="mb-4">
            <div class="mb-4">
               <label class="block text-gray-700 font-bold mb-2" for="name">
               Name:
               </label>
               <input v-model="form.name" class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="name" type="text" placeholder="Enter name">
            </div>
            <div class="mb-4">
               <label class="block text-gray-700 font-bold mb-2" for="email">
               Email:
               </label>
               <input v-model="form.email" class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="email" type="email" placeholder="Enter
                  email">
            </div>
            <div class="mb-4">
               <label class="block text-gray-700 font-bold mb-2" for="phone_number">
               Phone Number:
               </label>
               <input v-model="form.phone_number" class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="phone_number" type="tel" placeholder="Enter phone number">
            </div>
            <div class="mb-4">
               <label class="block text-gray-700 font-bold mb-2" for="address">
               Address:
               </label>
               <input v-model="form.address" class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="address" type="text" placeholder="Enter address">
            </div>
            <div class="mb-4">
               <label class="block text-gray-700 font-bold mb-2" for="city">
               City:
               </label>
               <input v-model="form.city" class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="city" type="text" placeholder="Enter city">
            </div>
            <div class="mb-4">
               <label class="block text-gray-700 font-bold mb-2" for="state">
               State:
               </label>
               <input v-model="form.state" class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="state" type="text" placeholder="Enter state">
            </div>
            <div class="flex justify-end">
               <button @click.prevent="submitForm" class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mr-2">
               {{ formTitle }}
               </button>
               <button @click="showForm = false" class="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded">
               Cancel
               </button>
            </div>
         </form>
      </div>
      <table class="table-auto w-full mb-4">
         <thead>
            <tr>
               <th class="px-4 py-2">#</th>
               <th class="px-4 py-2">Name</th>
               <th class="px-4 py-2">Email</th>
               <th class="px-4 py-2">Phone Number</th>
               <th class="px-4 py-2">Address</th>
               <th class="px-4 py-2">City</th>
               <th class="px-4 py-2">State</th>
               <th class="px-4 py-2">Actions</th>
            </tr>
         </thead>
         <tbody>
            <tr v-for="developer in propertyDevelopers" :key="developer.id">
               <td class="border px-4 py-2">{{ developer.id }}</td>
               <td class="border px-4 py-2">{{ developer.name }}</td>
               <td class="border px-4 py-2">{{ developer.email }}</td>
               <td class="border px-4 py-2">{{ developer.phone_number }}</td>
               <td class="border px-4 py-2">{{ developer.address }}</td>
               <td class="border px-4 py-2">{{ developer.city }}</td>
               <td class="border px-4 py-2">{{ developer.state }}</td>
               <td class="border px-4 py-2">
                  <button @click="editDeveloper(developer)" class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mr-2">
                  Edit
                  </button>
                  <button @click="deleteDeveloper(developer)" class="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded">
                  Delete
                  </button>
               </td>
            </tr>
         </tbody>
      </table>

   </div>
</template>
<script>
import axios from 'axios'

export default {
  data() {
    return {
      propertyDevelopers: [],
      form: {
        name: '',
        email: '',
        phone_number: '',
        address: '',
        city: '',
        state: ''
      },
      showForm: false,
      formTitle: ''
    }
  },
  mounted() {
    this.getPropertyDevelopers()
  },
  methods: {
    getPropertyDevelopers() {
      axios.get('/api/property-developers/')
        .then(response => {
          this.propertyDevelopers = response.data
        })
        .catch(error => {
          console.log(error)
        })
    },
    createDeveloper() {
      axios.post('/api/property-developers/', this.form)
        .then(response => {
          this.showForm = false
          this.form = {
            name: '',
            email: '',
            phone_number: '',
            address: '',
            city: '',
            state: ''
          }
          this.getPropertyDevelopers()
        })
        .catch(error => {
          console.log(error)
        })
    },
    updateDeveloper() {
      axios.put(`/api/property-developers/${this.form.id}/`, this.form)
        .then(response => {
          this.showForm = false
          this.form = {
            name: '',
            email: '',
            phone_number: '',
            address: '',
            city: '',
            state: ''
          }
          this.getPropertyDevelopers()
        })
        .catch(error => {
          console.log(error)
        })
    },
    editDeveloper(developer) {
      this.showForm = true
      this.formTitle = 'Edit Property Developer'
      this.form.id = developer.id
      this.form.name = developer.name
      this.form.email = developer.email
      this.form.phone_number = developer.phone_number
      this.form.address = developer.address
      this.form.city = developer.city
      this.form.state = developer.state
    },
    deleteDeveloper(developer) {
      axios.delete(`/api/property-developers/${developer.id}/`)
        .then(response => {
          this.getPropertyDevelopers()
        })
        .catch(error => {
          console.log(error)
        })
    },
    submitForm() {
      if (this.form.id) {
        this.updateDeveloper()
      } else {
        this.createDeveloper()
      }
    },
    cancelForm() {
      this.showForm = false
      this.form = {
        name: '',
        email: '',
        phone_number: '',
        address: '',
        city: '',
        state: ''
      }
    }
  }
}
</script>
