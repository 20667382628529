<script>
	import properties from '../mixins/properties'
	import actuator from '../mixins/actuator'
	import contact from '../landing/contact'
	import auth from '../mixins/auth.js'
	import scruff from './scruff'
	import mortgagecalc from  './mortgageCalculator'
	import propertytable from './propertytable'
	import inquiries from './inquiries'
	import 'viewerjs/dist/viewer.css'
	import Viewer from 'viewerjs'
	import navbar from '../landing/navbar'
	import gallery from './gallery'
	import mailflyer from './mailflyer'
	import cAlerts from './alerts'

	export default {
		data: () => {
			return {
				active: 0,
				showViewer: false,
				imgUrl: null,
				activeImage: 0,
				items: [{
				    src: 'http://via.placeholder.com/600x400',
				    thumbnail: 'http://via.placeholder.com/64x64',
				    w: 600,
				    h: 400,
				    alt: 'some numbers on a grey background' // optional alt attribute for thumbnail image
				  }
				]
			}
		},
		components: {
			contact,
			scruff,
			inquiries,
			navbar,
			gallery,
			mortgagecalc,
			propertytable,
			mailflyer,
			cAlerts
		},
		mixins: [properties, actuator, auth],
		mounted(){
			this.getListing(this.$route.params.uuid)
		},
		watch: {
			loaded(_){
				if (_) {
					//create an array of images 
					var m = []
					for (var i = 0; i < this.parseAll(this.listing.images).length; i++) {
						const img = {
						    src: `https://res.cloudinary.com/dwwxa5if0/image/upload/w_300,x_5,y_5,l_axis_watermark/`+ this.lastPart(this.parseAll(this.listing.images)[i].file),
						    thumbnail: `https://res.cloudinary.com/dwwxa5if0/image/upload/w_300,x_5,y_5,l_axis_watermark/`+ this.lastPart(this.parseAll(this.listing.images)[i].file),
						    w: 600,
						    h: 400,
						    alt: 'some numbers on a grey background' // optional alt attribute for thumbnail image
						  }

						m = m.concat(img)

					}
					this.items = m
				}
			}
		},

		methods: {
			initViewer(e){
				this.imgUrl = e
				this.showViewer = true

				console.log(document.getElementById('image'))

				setTimeout(() => {
				  console.log(document.getElementById('image'))
				  const viewer = new Viewer(document.getElementById('image'), {
				    inline: true,
				    viewed() {
				      viewer.zoomTo(1);
				    },
				  })
				}, 1000)


			},
			logRefs(){
				console.log(this.$refs.pictureSwipe.init)
			}
		}
	}
</script>
<template>
	<navbar />
	  <div class="max-w-screen-xl py-12 mx-auto sm:px-6 lg:px-8">
	    <div
	      class=""
	    >
	    	<div class="lg:col-span-2 mt-9">

	    		

	    		<section class="text-gray-600 body-font">
	    		  <div class="container px-5 mx-auto">
	    		    <div class="flex h-full bg-white rounded overflow-hidden">
	    		    	<div class="flex flex-wrap no-underline hover:no-underline">
	    		    		<div class="w-full md:w-2/3 rounded-t">	
	    		    			<img :src="`${parse(listing.images)}`" class="w-full shadow">
	    		    			<div class="mx-auto lg:w-4/4 h-full flex flex-wrap text-center mt-4">
	    		    			  <div class="mx-auto">
	    		    			  	<div  v-if="loaded" class="grid">
	    		    			  	  <div class="flex lg:flex-col order-last lg:order-none gap-4 w-full overflow-x-scroll ewubar">
	    		    			  	  	<vue-picture-swipe :items="items" ref="pictureSwipe"></vue-picture-swipe>
	    		    			  	  </div>
	    		    			  	  
	    		    			  	</div>	

	    		    			  </div>
	    		    			</div>
	    		    		</div>

	    		    		<div class="w-full md:w-1/3 flex flex-col flex-grow flex-shrink">
	    		    			<div class="flex-1 bg-white rounded-t rounded-b-none overflow-hidden">
	    		    				<p class="w-full text-gray-600 text-xs md:text-sm pt-6 px-6">Property Description</p>
	    		    				<div class="w-full font-bold text-xl text-gray-900 px-6">{{ listing.title }}</div>
	    		    				<p class="text-gray-800 text-base px-6 mb-5" v-html="listing.description"></p>
	    		    			</div>


	    		    		</div>

	    		    	</div>
	    		    </div>

	    		    
	    		    <propertytable :listing="listing" />

	    		    <div class="flex mt-5 flex-wrap lg:w-4/5 sm:mx-auto sm:mb-2 -mx-2">
	    		      <div class="p-2 sm:w-1/2 w-full" v-for="amenity in parseStr(listing.amenities)">
	    		        <div class="bg-gray-100 rounded flex p-4 h-full items-center">
	    		          <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="3" class="text-[#126f51] w-6 h-6 flex-shrink-0 mr-4" viewBox="0 0 24 24">
	    		            <path d="M22 11.08V12a10 10 0 11-5.93-9.14"></path>
	    		            <path d="M22 4L12 14.01l-3-3"></path>
	    		          </svg>
	    		          <span class="title-font font-medium">{{ amenity }}</span>
	    		        </div>
	    		      </div>
	    		    </div>

	    		  </div>
	    		</section>

	    		<mailflyer :listing="listing"/>
	    		<mortgagecalc />


	    		<section class="text-gray-600 body-font">
	    		  <div class="container px-5 mb-3 mx-auto">
	    		    <div class="lg:w-2/3 flex flex-col sm:flex-row sm:items-center items-start mx-auto">
	    		      <h1 class="flex-grow sm:pr-16 text-2xl font-medium title-font text-gray-900">Contact us on whatsapp</h1>
	    		      	  <a
	    		      	    type="button" 
	    		      	    name="wishlist"
	    		      	    target="_blank"
	    		      	    :href="`https://wa.me/+254728787414?text=Hi%2C+I+am+interested+in+your+property+${listing.title}+on+https%3A%2F%2Fwww.axisre.co.ke%2Flisting%2F${listing.uuid}`"
	    		      	    class=" text-white bg-white rounded-full right-2 top-2"
	    		      	  >
	    		      	  	<img src="/static/whatsapp.svg" class="h-12 w-12">
	    		      	  </a>
	    		    </div>
	    		  </div>
	    		</section>
	    		<div class="border-t border-gray-200">
	    		    <div class="container px-5 py-8 flex flex-wrap mx-auto items-center">
	    		      <div class="flex md:flex-nowrap flex-wrap justify-center items-end md:justify-start">
	    		      	Share this property on social media
	    		      </div>
	    		      <span class="inline-flex lg:ml-auto lg:mt-0 mt-6 w-full justify-center md:justify-start md:w-auto">
	    		      	<ShareNetwork
	    		      	    network="twitter"
	    		      	    :url="`https://axisre.co.ke/listing/${listing.uuid}`"
	    		      	    :title="listing.title"
	    		      	    :description="listing.description"
	    		      	    :quote="listing.uuid"
	    		      	    :hashtags="listing.category"
	    		      	    twitterUser="axis_real"
	    		      	  >
	    		      	  <vue-feather class="mx-2 mt-2" type=twitter size=18 />
	    		      	</ShareNetwork>
	    		      	<ShareNetwork
	    		      	    network="facebook"
	    		      	    :url="`https://axisre.co.ke/listing/${listing.uuid}`"
	    		      	    :title="listing.title"
	    		      	    :description="listing.description"
	    		      	    :quote="listing.uuid"
	    		      	    :hashtags="listing.category"
	    		      	    twitterUser="axis_real"
	    		      	  >
	    		      	  <vue-feather class="mx-2 mt-2" type=facebook size=18 />
	    		      	</ShareNetwork>
	    		      	<ShareNetwork
	    		      	    network="linkedin"
	    		      	    :url="`https://axisre.co.ke/listing/${listing.uuid}`"
	    		      	    :title="listing.title"
	    		      	    :description="listing.description"
	    		      	    :quote="listing.uuid"
	    		      	    :hashtags="listing.category"
	    		      	    twitterUser="axis_real"
	    		      	  >
	    		      	  <vue-feather class="mx-2 mt-2" type=linkedin size=18 />
	    		      	</ShareNetwork>
	    		      </span>
	    		    </div>
	    		  </div>
	    		<iframe width="100%" height="300" frameborder="0" marginheight="0" marginwidth="0" title="map" scrolling="no" :src="`https://maps.google.com/maps?width=100%&amp;height=600&amp;hl=en&amp;q=${listing.location}&amp;ie=UTF8&amp;t=&amp;z=14&amp;iwloc=B&amp;output=embed`"></iframe>

	    	</div>
		    <div class="lg:sticky">
		    	<contact v-if="loaded" :propertyView="true" :propertyId="listing.listing_id" :preset="`Hi! I would like to know more about the ${listing.title}`"></contact>
		    </div>
		    <cAlerts />
	    </div>
	  </div>
	  <foot />
	
</template>