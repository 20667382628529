<script>
	import leads from '../mixins/leads'
	import Navbar from './navbar'
	export default {
		props: ['propertyView', 'propertyId', 'preset'],
		mixins: [leads],
		mounted(){
			var input = document.querySelector("#phone")
			var iti = window.intlTelInput(input, {
			  separateDialCode: true,
			  preferredCountries: ["ke"],
			})

			input.addEventListener("countrychange", () => {
			  var countryData = iti.getSelectedCountryData()
			  this.ccode = countryData.dialCode
			  console.log(countryData.dialCode)
			})

			this.message = this.preset

		},
		components: {
			Navbar
		}
	}
</script>
<template> 
	<navbar v-if="!propertyView"/>
	<section id="contact" class="text-gray-600 body-font relative " :class="{'mt-20': !propertyView}">
	  <div class="absolute inset-0 bg-gray-300" v-if="!propertyView">
	    <iframe width="100%" height="100%" frameborder="0" marginheight="0" marginwidth="0" title="map" scrolling="no" src="https://maps.google.com/maps?width=100%&amp;height=600&amp;hl=en&amp;q=The Rahimtulla Tower&amp;ie=UTF8&amp;t=&amp;z=14&amp;iwloc=B&amp;output=embed"></iframe>
	  </div>
	  <div class="container py-24 mx-auto flex max-w-md">
	    <div class="max-w-md bg-white rounded-lg p-8 flex flex-col md:ml-auto w-full mt-10 md:mt-0 relative shadow-md">
	      <h2 class="text-gray-900 text-lg mb-1 font-medium title-font">Contact us {{ (propertyView) ? 'about this property' : ''}}</h2>
	      <!-- <p class="leading-relaxed mb-5 text-gray-600">Over 30,000 other people have already contacted us</p> -->
	      <h2 class="title-font font-semibold text-gray-900 tracking-widest text-xs">EMAIL</h2>
	      <a class="text-[#006747] leading-relaxed">{{ (propertyView) ? 'agency' : 'info'}}@axisre.co.ke</a>
	      <h2 class="title-font font-semibold text-gray-900 tracking-widest text-xs mt-4">PHONE</h2>
	      <p class="leading-relaxed mb-3">+254 722203032</p>
	      <div class="relative mb-4 text-left">
	        <label for="fname" class="leading-7 text-sm text-left text-gray-600">First Name</label>
	        <input type="text" id="fname" name="fname" v-model="fname" class="w-full bg-white rounded border focus:border-[#006747] focus:ring-2 focus:ring-[#006747] text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out">
	      </div>
	      <div class="relative mb-4 text-left">
	        <label  class="leading-7 text-sm text-left text-gray-600">Last Name</label>
	        <input type="text" v-model="lname" class="w-full bg-white rounded border focus:border-[#006747] focus:ring-2 focus:ring-[#006747] text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out">
	      </div>
	      <div class="relative mb-4 text-left ">

	        <label for="email" class="leading-7 text-sm text-left text-gray-600">Email</label>
	        <input type="email" id="email" name="email" v-model="email" class="w-full bg-white rounded border focus:border-[#006747] focus:ring-2 focus:ring-[#006747] text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"> 
	      </div>
	      <div class="relative mb-4 text-left">
  			<label class="leading-7 text-sm text-left text-gray-600">Phone Number</label>
  			<input v-model="number" name="phone" type="text" id="phone"  class="bg-white w-full rounded border focus:border-[#006747] focus:ring-2 focus:ring-[#006747] text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"/> 
	      </div>
	      <div class="relative mb-4 text-left ">
	        <label for="message" class="leading-7 text-sm text-gray-600">Message</label>
	        <textarea id="message" name="message" v-model="message" class="w-full bg-white rounded border focus:border-[#006747] focus:ring-2 focus:ring-[#006747] h-32 text-base outline-none text-gray-700 py-1 px-3 resize-none leading-6 transition-colors duration-200 ease-in-out"></textarea>
	      </div>

	      <success v-if="success"></success>
	      <error v-if="error"></error>

	      <button class="text-white bg-[#006747] border-0 py-2 px-6 focus:outline-none hover:bg-[#006747] rounded text-lg" @click="create">Submit</button>

	    </div>
	  </div>
	</section>

</template>
<style>
	.max-w-md {
		max-width: 28rem!important;
	}
	.iti{
		width: 100%!important;
	}
</style>