import axios from 'axios'
import api from './api'
import Cookies from 'js-cookie'

const auth = {
	data: () => {
		return {
			username: '',
			email: '',
			password: '',
			selectedRole:'',
			loadAuth: true,
			createdUser: '',
			users: [],
			loaded: false,
			showDelete: false,
			user_id: 0
		}
	},
	computed: {
		loggedIn(){
			return this.$store.state.loggedIn
		}
	},
	methods: {
		register(){ 
			var f = new FormData()
			f.append('username', this.username)
			f.append('email', this.email)
			f.append('password', this.password)

			api.post('registration/', f).then( (r) => {
				this.createdUser = r.data.username
				this.sendWelcomeEmail()
			}, (e) => {
				console.log(e)
			})
		},
		login(){
			var f = new FormData()
			f.append('username', this.username)
			f.append('email', this.email)
			f.append('password', this.password)

			api.post('login/', f).then( (r) => {
				this.$router.push('/manage')
			}, (e) => {
				console.log(e)
			})
		},
		sendWelcomeEmail(){
			var f = new FormData()
			f.append('username', this.username)
			f.append('email', this.email)
			f.append('password', this.password)

			api.post('sendWelcomeEmail/', f).then( (r) => {
				this.assignRole()
			}, (e) => {
				console.log(e)
			})
		},
		assignRole(){
			var f = new FormData()

			f.append('createdUser', this.createdUser)
			f.append('selectedRole', this.selectedRole)

			api.post('assignRole/', f).then((r) => {
				this.$router.push('/view/users')
			})
		},
		read(){
			var f = new FormData()

			f.append('username', this.username)

			api.post('get/user/', f,  {  headers: {
			    'Content-Type': 'application/json',
			    'X-CSRFToken': this.$store.state.authtoken
			}}).then( (r) => {
				this.email = r.data.email
				if (r.data.groups.length != 0) {
					this.selectedRole =  r.data.groups[0].name
				}
				this.loaded = false
			}, (e) => {
				console.log(e)
			})

		},
		edit(){
			var f = new FormData()

			f.append('username', this.username)
			f.append('email', this.email)
			f.append('selectedRole', this.selectedRole)

			api.post('edit/user/', f,  {  headers: {
			    'Content-Type': 'application/json',
			    'X-CSRFToken': this.$store.state.authtoken
			}}).then( (r) => {
				this.$router.push('/manage')
				this.loaded = false
			}, (e) => {
				console.log(e)
			})
		},
		initDel(y){
			this.user_id = y
			this.showDelete = true
		},
		del(){
			var f = new FormData()

			f.append('user_id', this.user_id) 

			api.post('delete/user/', f).then( (r) => {
				this.readAll()
				this.showDelete = false
			})
		},
		readAll(){
			api.get('get/users/').then( (r) => {
				this.users = r.data
				this.$store.state.userLn = r.data.length
				this.loaded = true
			}, (e) => {
				console.log(e)
			})
		},
		authenticated(){
			this.loadAuth = true

			api.get('authenticated/').then( (r) => {
				if (r.data == 'False') {
					this.$router.push('/register')
				}else{
					Cookies.set('csrftoken', r.data)
					this.$store.commit('SET_AUTHTOKEN', r.data)
					this.$store.commit('SET_AUTH', true)
				}

				this.loadAuth = false
			})
		},
		logout(){
			api.get('logout/').then( (r) => {
				if (r.data == 'False') {
					this.$router.push('/')
				}
				console.log(r)
			})
		}
	}
}

export default auth