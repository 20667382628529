<script>
	import services from '../mixins/services'
	import actuator from '../mixins/actuator'
	import Sidebar from '../dashboard/Sidebar'
	import Navbar from '../dashboard/Navbar'
	import Footer from '../dashboard/Footer'


	export default {
		mixins: [services, actuator],
		mounted(){
			this.readAll()
		},
		computed: {
			sideBarOpen: {
				get(){
					return this.$store.state.sideBarOpen
				},
				set(z){
					this.$store.commit('SET_SIDEBAR', z)
				}
			}
		},
		watch: {
			$route(){
				this.sideBarOpen = false
			}
		},
		components: {
		  Sidebar,
		  Navbar,
		  Footer
		},
		methods: {
		  toggleSidebar(z){
		    this.sideBarOpen = z
		  }
		}
	}
</script>
<template>
	<div class="leading-normal tracking-normal" id="main-body">
	  <div class="flex flex-wrap">

	    <Sidebar :sideBarOpen="sideBarOpen"/>

	    <div class="w-full bg-gray-100 pl-0 lg:pl-64 min-h-screen" :class="sideBarOpen ? 'overlay' : ''" id="main-content">

	      <Navbar :sideBarOpen="sideBarOpen" @toggleSidebar="toggleSidebar"/>

	      <div class="bg-gray-100 mb-20">

	        <div class="w-full overflow-hidden rounded-lg shadow-xs">
	          <div class="w-full overflow-x-auto">

	          	<div class="relative p-8 text-center border border-gray-200 rounded-lg" v-if="services.length == 0">
	          	  <h2 class="text-2xl font-medium">
	          	    There's nothing here...
	          	  </h2>

	          	  <p class="mt-4 text-sm text-gray-500">
	          	    New content will appear here once added. If you are the site administrator add content to begin.
	          	  </p>
	          	</div>
	            <table class="w-full whitespace-no-wrap" v-if="loaded && services.length > 0">
	              <thead>
	                <tr
	                  class="text-xs font-semibold tracking-wide text-left text-black uppercase border-b dark:border-gray-700 bg-gray-50 dark:text-gray-400 dark:bg-gray-800"
	                >
	                  <th class="px-4 py-3">Title</th>
	                  <th class="px-4 py-3">Description</th>
	                  <th class="px-4 py-3">Action</th>
	                </tr>
	              </thead>
	              <tbody
	                class="bg-white divide-y dark:divide-gray-700 dark:bg-gray-800"
	              >
	                <tr class="text-gray-700 dark:text-gray-400" v-for="(service, i) in services" :key="i">
	                  <td class="px-4 py-3">
	                    <div class="flex items-center text-sm">
	                    	<img alt="team" class="w-7 h-7 bg-gray-100 object-cover object-center flex-shrink-0 rounded-full mr-4" :src="parse(service.image)">
	                      <div>
	                        <p class="font-semibold">{{service.title}}</p>
	                      </div>
	                    </div>
	                  </td>
	                  <td class="px-6 py-3 text-sm">
	                     {{service.description.slice(0, 45)}}
	                  </td>
	                  <td class="px-4 py-3 text-sm">
	                  	<a @click="initDel(service.service_id)"  class="mx-2">
	                  		<vue-feather type=trash ></vue-feather>
	                  	</a>
	                  	<router-link :to="{path:`/edit/service/${service.title}`}"  class="mx-2">
	                  		<vue-feather type=edit ></vue-feather>
	                  	</router-link>
	                  </td>
	                </tr>

	              </tbody>
	            </table>
	          </div>
	          <delete  v-if="showDelete" @cancel="showDelete = false" @accept="del()"></delete>
	        </div>
	      </div>

	      <Footer />

	    </div>
	  </div>
	</div>



</template>