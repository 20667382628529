<template>
  <div class="flex flex-col flex h-screen items-center justify-center">
    <div class="mx-auto bg-white p-8 ">
      <h2 class="text-xl text-center font-semibold mb-4">Upload Spreadsheet</h2>
      <div class="mb-4" v-if="!spreadsheetData">

        <div class="flex items-center justify-center w-full">
            <label for="dropzone-file" class="flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600">
                <div class="flex flex-col items-center justify-center -mb-20 pt-5 pb-6">
                    <svg aria-hidden="true" class="w-10 h-10 mb-3 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"></path></svg>
                    <p class="mb-2 text-sm text-gray-500 dark:text-gray-400"><span class="font-semibold">Click to upload</span> or drag and drop</p>
                    <p class="text-xs text-gray-500 dark:text-gray-400">XLSX, XLS,ODS,CSV</p>
                </div>
                <input ref="inputsx" id="file-upload" type="file" class="top-0 opacity-0 h-full left-0" @change="handleFileUpload" />
            </label>
        </div> 

      </div>
      <div v-if="spreadsheetData">
        <multiload @accept="cSpreadsheet" :spreadLn="spreadsheetData.length" />

        <h2 class="text-xl font-semibold mb-4">Spreadsheet Data</h2>
        <div class="relative overflow-x-auto shadow-md sm:rounded-lg">
            <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                    <tr>
                        <th scope="col" class="p-4">
                            <div class="flex items-center">
                                <input id="checkbox-all-search" type="checkbox" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
                                <label for="checkbox-all-search" class="sr-only">checkbox</label>
                            </div>
                        </th>
                        <th scope="col" class="px-6 py-3">
                            First Name
                        </th>
                        <th scope="col" class="px-6 py-3">
                            Middle Name
                        </th>
                        <th scope="col" class="px-6 py-3">
                            Last Name
                        </th>
                        <th scope="col" class="px-6 py-3">
                            Title
                        </th>
                        <th scope="col" class="px-6 py-3">
                            Company Name
                        </th>
                        <th scope="col" class="px-6 py-3">
                            Mobile Number
                        </th>
                        <th scope="col" class="px-6 py-3">
                            Landline
                        </th>
                        <th scope="col" class="px-6 py-3">
                            Telephone Number
                        </th>
                        <th scope="col" class="px-6 py-3">
                            Email Address
                        </th>
                        <th scope="col" class="px-6 py-3">
                          Physical Address
                        </th>
                        
                        <th scope="col" class="px-6 py-3">
                          Postal Address
                        </th>
                        
                        <th scope="col" class="px-6 py-3">
                          Postal Code
                        </th>
                        
                        <th scope="col" class="px-6 py-3">
                          Town
                        </th>
                        
                        <th scope="col" class="px-6 py-3">
                          Created At
                        </th>
                        
                    </tr>
                </thead>
                <tbody>
                    <tr 
                      class="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
                      v-for="(row, index) in spreadsheetData"
                      :key="index">
                      <td class="w-4 p-4">
                          <div class="flex items-center">
                              <input id="checkbox-table-search-1" type="checkbox" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
                              <label for="checkbox-table-search-1" class="sr-only">checkbox</label>
                          </div>
                      </td>
                      <td class="border px-4 py-2">{{ row.first_name }}</td>
                      <td class="border px-4 py-2">{{ row.middle_name }}</td>
                      <td class="border px-4 py-2">{{ row.last_name }}</td>
                      <td class="border px-4 py-2">{{ row.title }}</td>
                      <td class="border px-4 py-2">{{ row.company_name }}</td>
                      <td class="border px-4 py-2">{{ row.mobile_number }}</td>
                      <td class="border px-4 py-2">{{ row.landline }}</td>
                      <td class="border px-4 py-2">{{ row.telephone_number }}</td>
                      <td class="border px-4 py-2">{{ row.email_address }}</td>
                      <td class="border px-4 py-2">{{ row.physical_address }}</td>
                      <td class="border px-4 py-2">{{ row.postal_address }}</td>
                      <td class="border px-4 py-2">{{ row.postal_code }}</td>
                      <td class="border px-4 py-2">{{ row.town }}</td>
                      <td class="border px-4 py-2">{{ row.created_at }}</td>
                      <td class="flex items-center px-6 py-4 space-x-3">
                          <a href="#" class="font-medium text-blue-600 dark:text-blue-500 hover:underline">Edit</a>
                          <a href="#" class="font-medium text-red-600 dark:text-red-500 hover:underline">Remove</a>
                      </td>
                    </tr>

                    
                </tbody>
            </table>
        </div> 

      </div>
    </div>
    
  </div>
</template> 

<script>
import FlowBite from "flowbite"
import * as XLSX from 'xlsx'
import leads from '../mixins/leads'

export default {
  mixins: [leads],
  data() {
    return {
      spreadsheetData: null,
      arr1:[]
    }
  }, 
  mounted(){
    this.readAll()
  },
  methods: {	
  	handleFileUpload(event) {
  	  const file = event.target.files[0]
  	  const reader = new FileReader()

  	  reader.onload = event => {
  	    const data = new Uint8Array(event.target.result)
  	    const workbook = XLSX.read(data, { type: "array" })
  	    const worksheetName = workbook.SheetNames[0]
  	    const worksheet = workbook.Sheets[worksheetName]
  	    const headers = {}
  	    let rowData = []

  	    for (let cell in worksheet) {
  	      if (cell[0] === "!") continue
  	      //parse out the column, row, and value
  	      let col = cell.substring(0, 1)
  	      let row = parseInt(cell.substring(1));
  	      let value = worksheet[cell].v;

  	      //store header names
  	      if (row == 1) {
  	        headers[col] = value;
  	        continue;
  	      }

  	      //skip empty rows
  	      if (!value) continue;

  	      if (!rowData[row]) rowData[row] = {};
  	      rowData[row][headers[col]] = value;
  	    }
  	    
  	    //remove the first empty element of the data array
  	    rowData.splice(0,2);
  	    rowData = rowData.filter(item => item !== undefined);
  	    this.spreadsheetData = rowData;
  	  };

  	  reader.readAsArrayBuffer(file);
  	}


  }
}
</script>

