<script>
	import services from '../mixins/services'
	import actuator from '../mixins/actuator'
	import narratives from '../mixins/narratives'
	import navbar from './navbar'

	export default {

		components: {
			navbar 
		},
		mixins: [services, actuator,  narratives],
		mounted(){
			this.readAll()
			this.readAllNarratives()
		}
	} 
</script>
<template>
	<navbar />

	<section class="mt-24 space-y-9 p-8">
		<div class="max-w-5xl mx-auto flex flex-col sm:flex-row gap-10 sm:gap-20">
		    <div class="text-3xl w-full sm:w-2/3 text-green-900 leading-relax">
		       Axis Real Estate Ltd is a leading real estate adviser in Africa. 
		    </div>

		    <div class="flex flex-col gap-5 items-start justify-center w-full sm:w-1/3">
		        <div class="text-green-900">
		            With experience spanning more than two decades of management. Based in Nairobi, Axis Real Estate Ltd focuses on providing superior services in the real estate markets throughout Kenya. Our professionals specialize in the sale, acquisition, leasing and valuation of residential, industrial, office and retail units.
		        </div>

		        <router-link to="/about" class="text-green-900 hover:text-green-700 inline-flex gap-1 items-center justify-start">
		            Read More
		            <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 rotate-45 text-current" fill="none"
		                 viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
		                <path stroke-linecap="round" stroke-linejoin="round" d="M7 11l5-5m0 0l5 5m-5-5v12"/>
		            </svg>
		        </router-link>
		    </div>
		</div>


		<div class="max-w-5xl mx-auto grid grid-cols-1 sm:grid-cols-2 gap-10">
		    <div class="relative flex flex-col gap-5 bg-green-50/50 h-96 p-4" v-for="(service, i) in services">

		    	<router-link :to="`/service/${service.title}`" >
			        <div class="flex justify-between items-start">
			            <span class="text-3xl font-mono text-gray-200">
			                0{{i}}
			            </span>
			        </div>
			        <div class="text-green-900 text-3xl">
			           {{ service.title }}
			        </div>

			        <div class="hidden sm:block text-green-900 mt-9" v-html="service.description.slice(0, 108)">
			        </div>

			        <div>
			            <a href="#"
			               class="bottom-3 absolute text-green-900 hover:text-green-700 inline-flex gap-1 items-start">
			                read more
			                <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 rotate-45 text-current" fill="none"
			                     viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
			                    <path stroke-linecap="round" stroke-linejoin="round" d="M7 11l5-5m0 0l5 5m-5-5v12"/>
			                </svg>
			            </a>
			        </div>
			    </router-link>
		    </div>
		</div>
	</section>

</template>

