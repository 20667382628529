<template>
  <div class="flex justify-center items-center h-screen">
    <form class="w-full max-w-md p-8" @submit.prevent="($route.name == 'CreateUsers') ? register() : edit()">
      <h2 class="text-2xl font-bold mb-8">Create User</h2>
      <div>
        <label class="block text-gray-700 font-bold mb-2" for="userRole">
          User Role
        </label>
        <div class="relative inline-block w-full text-gray-700">
          <select v-model="selectedRole" class="w-full h-10 pl-3 pr-8 text-base placeholder-gray-600 border rounded-lg appearance-none focus:shadow-outline-green focus:border-green-400">
            <option value="" disabled selected>Select role</option>
            <option value="Admin" >Admin</option>
            <option value="Editor" >Editor</option>
            <option value="User" >User</option>
          </select>
        </div>
      </div>
      <div class="mb-4">
        <label class="block text-gray-700 font-bold mb-2" for="username">
          Username
        </label>
        <input
          class="appearance-none border rounded w-full focus:border-[#126f51] focus:ring-2 focus:ring-[#126f51] py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          id="username" type="text" placeholder="Username" v-model="username">
      </div>
      <div class="mb-4">
        <label class="block text-gray-700 font-bold mb-2" for="email">
          Email
        </label>
        <input
          class="appearance-none border rounded w-full focus:border-[#126f51] focus:ring-2 focus:ring-[#126f51] py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          id="email" type="email" placeholder="Email" v-model="email">
      </div>
      <div class="mb-4" v-if="($route.name == 'CreateUsers')">
        <label class="block text-gray-700 font-bold mb-2" for="password">
          Password
        </label>
        <input 
          class="appearance-none border rounded w-full focus:border-[#126f51] focus:ring-2 focus:ring-[#126f51] py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          id="password" type="password" placeholder="********" v-model="password">
      </div>
      <div class="flex items-center justify-between">
        <loader v-if="loading"></loader>
        <button v-else class="bg-[#126f51] hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
          type="submit">
          {{($route.name == 'CreateUsers') ? 'Create' : 'Edit'}}
        </button>
        <a class="inline-block align-baseline font-bold text-sm text-[#126f51] hover:text-green-800" @click="$router.go(-1)">
          Cancel
        </a>
      </div>
    </form>
  </div>
</template>

<script >
	import Auth from '../mixins/auth'

	export default {
		mixins: [Auth],
    mounted(){
      this.authenticated()
      if (this.$route.name == 'EditUser') {
        this.username = this.$route.params.username
        this.read()
      }
    }
	}
</script>