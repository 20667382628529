<script>
	import { OpenStreetMapProvider } from 'leaflet-geosearch';
	export default {
		data: () => {
			return {
				query: null,
				results: []
			}
		},
		watch: {
			query(y){
				this.autogeo()
			}
		},
		methods: {
			async autogeo(){

				const provider = new OpenStreetMapProvider()

				const results = await provider.search({ query: this.query })

				console.log(results)

				this.results = results

			},

			complete(y){
				this.query = y.raw.display_name

				this.$emit('complete', y)
			}
		}
	}
</script>
<template>
	<!-- Dropdown menu -->
	<div id="dropdownSearch" class="z-10 bg-white rounded shadow dark:bg-gray-700 block" data-popper-reference-hidden="" data-popper-escaped="" data-popper-placement="bottom" >
	    <div class="p-3">
	      <label for="input-group-search" class="sr-only">Search</label>
	      <div class="relative">
	        <div class="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
	          <svg class="w-5 h-5 text-gray-500 dark:text-gray-400" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clip-rule="evenodd"></path></svg>
	        </div>
	        <input type="text" v-model="query" id="input-group-search" class="block p-2 pl-10 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-[#126f51] focus:border-[#126f51] dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-[#126f51] dark:focus:border-[#126f51]" placeholder="Search Location">
	      </div>
	    </div>
	    <ul class="overflow-y-auto px-3 pb-3 h-48 text-sm text-gray-700 dark:text-gray-200" aria-labelledby="dropdownSearchButton">
	      <li v-for="(result, i) in results" :key="i" v-if="results.length > 0">
	        <div class="flex items-center pl-2 rounded hover:bg-gray-100 dark:hover:bg-gray-600">

	          <a @click="complete(result)" for="checkbox-item-11" class="py-2 ml-2 w-full text-sm font-medium text-gray-900 rounded dark:text-gray-300">{{ result.raw.display_name }}</a>
	        </div>

	      </li>
	      <div class="relative p-8 text-center border border-gray-200 rounded-lg" v-else>
	        <h2 class="text-2xl font-medium">
	          There's nothing here...
	        </h2>

	        <p class="mt-4 text-sm text-gray-500">
	          Search results will appear here, try entering another one!
	        </p>

	      </div>
	    </ul>
	</div>

</template>