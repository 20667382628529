// store.js
import { createStore } from 'vuex'
// import axios from 'axios'
// import Cookies from 'js-cookie' 
// import router from '@/routes'
import api from '@/mixins/api'
import Cookies from 'js-cookie'

export default new createStore({
  state () {
    return { 
    	leadLn: 0,
    	propertyLn: 0,
    	teamLn: 0,
    	serviceLn: 0,
      images: [],
      loggedIn: false,
      authtoken: null,
      sideBarOpen: false,
      loaded_listings: false,
      listings: [],
      filtered: [],
      maxprice: null, 
      minprice: null,
      selectedContacts: [],
      content: { html: '<h1>Hello World!</h1>' },
      multicount: 0,
      checkedLeads: [],
      aLeads:[]
    }
  },
  actions: {

  },
  mutations: {
    SET_IMAGES (state, images) {
      state.images = images
    },
    SET_AUTH (state, auth){
      state.loggedIn = auth
    },
    SET_AUTHTOKEN (state, authtoken){
      state.authtoken = authtoken
    },
    SET_SIDEBAR(state, sideBarOpen){
      state.sideBarOpen = sideBarOpen
    },
    SET_LISTINGS(state, listings){
      state.listings = listings
    },
    SET_FILTERED(state, filtered){
      state.filtered = filtered
    },
    SET_LOADED_LISTINGS(state, loaded){
      state.loaded_listings = loaded
    },
    SET_MAX_PRICE(state, maxprice){
      state.maxprice = maxprice
    },
    SET_MIN_PRICE(state, minprice){
      state.minprice = minprice
    },
    SET_SELECTED_CONTACTS(state, selectedContacts){
      state.selectedContacts = selectedContacts
    }, 
    SET_CURRENT_CONTENT(state, currentContent){
      state.currentContent = currentContent
    },
    SET_MULTICOUNT(state, multicount){
      state.multicount = multicount
    },
    SET_CHECKED_LEADS(state, checkedLeads){
      state.checkedLeads = checkedLeads
    },
    SET_ALEADS_LEADS(state, aLeads){
      state.aLeads = aLeads
    }
  }
})