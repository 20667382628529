<script>
	import properties from '../mixins/properties'
	import actuator from '../mixins/actuator'

	export default {
		mixins: [properties, actuator],
		mounted(){
      this.loaded = false
			this.readAll()
		},
    props: [
      'propertiesHeading',
      'propertiesText'
    ]
	}
</script>
<template>
	<div id="properties" class="w-full space-y-20 my-36 px-4 sm:px-0">
	    <div class="mx-auto max-w-6xl">
            <section class=" body-font">
              <div class="container mx-auto">
                <div class="relative p-8 text-center border border-gray-200 rounded-lg" v-if="listings.length == 0">
                  <h2 class="text-2xl font-medium">
                    There's nothing here...
                  </h2>

                  <p class="mt-4 text-sm text-gray-500">
                    New content will appear here once added. If you are the site administrator add content to begin.
                  </p>
                </div>
                <div class="flex flex-wrap sm:-m-4 -mx-4 -mb-10 -mt-4" v-if="listings.length > 0 && loaded">
                  <div class="p-4 w-full md:w-1/2 sm:mb-0 mb-6"  v-for="(listing,i) in listings">
                    <div class="propertie-item set-bg" :style="{background: `url(${parse(listing.images)})no-repeat`, backgroundSize: 'cover'}">
                      <router-link :to="`/listing/${listing.uuid}`">
                        <div class="sale-notic">{{ listing.location }}</div>
                        <div class="propertie-info text-white">
                          <div class="info-warp">
                            <h5>{{listing.title }}</h5>
                            <!-- <p><i class="fa fa-map-marker"></i> {{ listing.location }}</p> -->
                          </div>
                          <div class="price" v-if="listing.price != ''"> {{listing.currency}} {{ strNum(listing.price) }} </div>

                        </div>
                      </router-link>

                    </div>
                  </div>
                </div>
              </div>
            </section>              

	    </div>
	</div>
</template>
<style>
  .propertie-item {
    position: relative;
    margin-bottom: 30px;
    position: relative;
  }

  .propertie-item:after {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#000000+0,303030+100&0+32,0.9+100 */
    /* FF3.6-15 */
    /* Chrome10-25,Safari5.1-6 */
    background: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0)), color-stop(32%, rgba(15, 15, 15, 0)), to(rgba(48, 48, 48, 0.9)));
    background: -o-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(15, 15, 15, 0) 32%, rgba(48, 48, 48, 0.9) 100%);
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(15, 15, 15, 0) 32%, rgba(48, 48, 48, 0.9) 100%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#e6303030', GradientType=0);
    /* IE6-9 */
  }

  .propertie-item .sale-notic,
  .propertie-item .rent-notic {
    margin: 30px;
  }

  .propertie-info {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    padding: 30px;
    padding-bottom: 20px;
    z-index: 5;
  }

  .propertie-info h5 {
    margin-bottom: 8px;
    font-weight: 400;
  }

  .propertie-info p {
    margin-bottom: 0;
  }

  .propertie-info p i {
    color: #006f51;
    margin-right: 5px;
  }

  .propertie-info .info-warp {
    float: left;
  }

  .propertie-info .price {
    float: right;
    background: #9d2235;
    padding: 12px;
    min-width: 110px;
    text-align: center;
    border-radius: 2px;
  }
  .sale-notic,
  .rent-notic {
    color: #fff;
    font-size: 12px;
    text-transform: uppercase;
    background: #006747;
    padding: 7px 13px;
    display: inline-block;
    border-radius: 2px;
    position: relative;
    z-index: 3;
  }
</style>