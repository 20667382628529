<script>
	import actuator from '../mixins/actuator'

	export default {
		props:['listing'],
		mixins: [actuator],
		mounted() {
		   // are exposed on `this`
		   console.log(this.listing)
		 }
	}
</script>
<template>
	<div >
		<div class="leading-relaxed mb-4" v-html="listing.description"></div>
		<div class="flex border-t border-gray-200 py-2">
		  <span class="text-gray-500">Category</span>
		  <span class="ml-auto text-gray-900">{{ listing.category }}</span>
		</div>
		<div class="flex border-t border-gray-200 py-2">
		  <span class="text-gray-500">Parking</span>
		  <span class="ml-auto text-gray-900">{{ listing.parking }}</span>
		</div>
		<div class="flex border-t border-gray-200 py-2">
		  <span class="text-gray-500">Bathrooms</span>
		  <span class="ml-auto text-gray-900">{{ listing.bathroom }}</span>
		</div>
		<div class="flex border-t border-gray-200 py-2">
		  <span class="text-gray-500">Bedrooms</span>
		  <span class="ml-auto text-gray-900">{{ listing.bedroom }}</span>
		</div>
		<div class="flex border-t border-b mb-6 border-gray-200 py-2">
		  <span class="text-gray-500">Size</span>
		  <span class="ml-auto text-gray-900">{{ listing.size }} ft</span>
		</div>
		<div class="flex" v-if="listing.price != ''">
		  <span class="title-font font-medium text-2xl text-gray-900">KES {{ strNum(listing.price) }}</span>

		</div>


	</div>
</template>