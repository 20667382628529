import axios from 'axios'
import api from './api'
import actuator from './actuator'

const services = {
	data: () => {
		return {
			title: '',
			email: '',
			description: '',
			image: '',
			service_id: '',
			services: [],
			loaded: false,
			showDelete: false,
			service_id: 0 
		}
	},
	computed: {
		images(){
			return this.$store.state.images
		}
	},
	watch:{
		images(y){
			if(y.length > 0){
				if (this.$route.name == 'EditService') {
					this.edit()
				}else {
					this.create()
				}
				
			}
		}
	},
	methods: {
		create(){
			var f = new FormData()

			f.append('title', this.title)
			f.append('description', this.description)
			f.append('email', this.email)
			f.append('image', JSON.stringify(this.$store.state.images))

			api.post('create/service/', f, {  headers: {
			    'Content-Type': 'application/json',
			    'X-CSRFToken': this.$store.state.authtoken
			}}).then( (r) => {
				// this.$router.push('/manage')
				this.$store.commit('SET_IMAGES', [])
				this.$router.push('/manage')
			}, (e) => {
				console.log(e)
			})
		},
		edit(){
			var f = new FormData()

			f.append('service_id', this.service_id)
			f.append('email', this.email)
			f.append('title', this.title)
			f.append('description', this.description)
			if (this.$store.state.images.length == 0 ) {
				f.append('image', this.image)
			}else {
				f.append('image', JSON.stringify(this.$store.state.images))
			}
			

			api.post('edit/service/', f,  {  headers: {
			    'Content-Type': 'application/json',
			    'X-CSRFToken': this.$store.state.authtoken
			}}).then( (r) => {
				// this.$router.push('/manage')
				this.$store.commit('SET_IMAGES', [])
				this.$router.push('/manage')

				this.loaded = false
			}, (e) => {
				console.log(e)
			})
		},
		read(){
			var f = new FormData()

			f.append('title', this.title)

			api.post('get/service/', f).then( (r) => {
				// this.$router.push('/manage')
				this.service_id = r.data.service_id
				this.name = r.data.name
				this.email = r.data.email
				this.description = r.data.description
				this.image = r.data.image	

				this.loaded = true
			}, (e) => {
				console.log(e)
			})
		},
		readAll(){
			api.get('get/services/').then( (r) => {
				this.services = r.data
				this.$store.state.serviceLn = r.data.length
				this.loaded = true
			}, (e) => {
				console.log(e)
			})
		},
		initDel(y){
			this.service_id = y
			this.showDelete = true
		},
		del(){
			var f = new FormData()

			f.append('service_id', this.service_id) 

			api.post('delete/service/', f).then( (r) => {
				this.readAll()
				this.showDelete = false
			})
		}
	}
}

export default services