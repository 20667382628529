<template>
	<section>
		<div class="bg-white py-6 ">
		  <div class="max-w-screen-2xl px-4 md:px-8 mx-auto">
		    <div class="flex flex-col items-center bg-gray-100 rounded-lg p-4 sm:p-8">
		      <div class="mb-4 sm:mb-8">
		        <h2 class="text-[#006747] text-xl sm:text-2xl lg:text-3xl font-bold text-center">Receive a flyer for this property</h2>
		        <p class="text-gray-500 text-center">Enter your email</p>
		      </div>

		      <div class="w-full max-w-md flex gap-2 mb-3 sm:mb-5">
		        <input placeholder="Email" v-model="email" class="w-full flex-1 bg-gray-white text-gray-800 placeholder-gray-400 border border-gray-300 focus:ring ring-[#006747] rounded outline-none transition duration-100 px-3 py-2" />

		        <button @click="sendFlyer" class="inline-block bg-[#006747] hover:bg-[#006747] active:bg-[#006747] focus-visible:ring ring-[#006747] text-white text-sm md:text-base font-semibold text-center rounded outline-none transition duration-100 px-8 py-2">Send</button>
		        <success v-if="success"></success>
		        <error v-if="error"></error>
		      </div>


		      <p class="text-gray-400 text-xs text-center">By signing up to our newsletter you agree to our <a href="#" class="hover:text-[#006747] active:text-[#006747] underline transition duration-100">Term of Service</a> and <a href="#" class="hover:text-[#006747] active:text-[#006747] underline transition duration-100">Privacy Policy</a>.</p>
		    </div>
		  </div>
		</div>

	</section>
</template>
<script>
	import api from '../mixins/api'
	import actuator from '../mixins/actuator'
	export default {
		props: ['listing'],
		mixins: [api, actuator],
		data: () => {
			return {
				email: null,
				success: false,
				error: false
			}
		},
		methods: {
			sendFlyer(){
				var f = new FormData()

				f.append('email', this.email)
				f.append('title', this.listing.title)
				f.append('description', this.listing.description)
				f.append('parking', this.listing.parking)
				f.append('bathroom', this.listing.bathroom)
				f.append('bedroom', this.listing.bedroom)
				f.append('price', this.listing.price)
				f.append('category', this.listing.category)
				f.append('subcategory', this.listing.subcategory)
				f.append('size', this.listing.size)
				f.append('location', this.listing.location)
				f.append('status', this.listing.status)
				f.append('img1', this.parse(this.listing.images))

				api.post('send/flyer/', f).then( (r) => {
					console.log('done')
					this.success = true
				})
			}
		}

	}
</script>