import { createApp } from 'vue'
import App from './App.vue'

import VueFeather from 'vue-feather'
import VueSocialSharing from 'vue-social-sharing'
import 'flowbite'

import { createRouter, createWebHashHistory } from 'vue-router'

import router from './routes'

import store from './store.js'

import './index.css'

import ErrorComponent from './components/error'
import SuccessComponent from './components/success'
import LoaderComponent from './components/loader'
import DeleteComponent from './components/delete'
import ReplyModal from './components/reply'
import IDE from './components/ide'
import Flowcel from './components/carousel'
import wysiwyg from './components/wysiwyg'
import pageload from './components/pageload'
import multiload from './components/multiload'
import multiselect from './components/multiselect'
import VuePictureSwipe from 'vue3-picture-swipe'
import foot from './landing/foot'
import 'vue3-carousel/dist/carousel.css';
import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel'

import InfiniteLoading from "v3-infinite-loading";
import "v3-infinite-loading/lib/style.css"; //required if you're not going to override default slots
import './registerServiceWorker'


const app = createApp(App)
app.use(router)
app.use(store)
app.use(VueSocialSharing)



app.component(VueFeather.name, VueFeather)
app.component('Error', ErrorComponent)
app.component('Success', SuccessComponent)
app.component('Loader', LoaderComponent)
app.component('multiload', multiload)
app.component('Delete', DeleteComponent)
app.component('vue-picture-swipe', VuePictureSwipe)
app.component('wysiwyg', wysiwyg)
app.component('pageload', pageload)
app.component('VueCarousel', Carousel)
app.component('VueSlide', Slide)
app.component('VuePagination', Pagination)
app.component('VueNavigation', Navigation)
app.component('IDE', IDE)
app.component('multiselect', multiselect)
app.component('foot', foot)
app.component('ReplyModal', ReplyModal)
app.component("infinite-loading", InfiniteLoading)

app.mount('#app')

