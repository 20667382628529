<template>
  <div class="flex flex-col h-screen">
    <div class="flex-grow flex flex-col overflow-hidden">
      <div class="flex-grow">
        <div class="flex flex-col h-full">
          <div class="flex-grow overflow-hidden nobar">
            <div class="h-full flex flex-col">
              <div class="bg--700 h-6 flex items-center px-2">
                <label class="block text-gray-700 font-bold mb-2" for="title">HTML</label>
                
              </div>
              <div class="flex-grow">
                <textarea v-model="currentContent.html" class="h-full w-full bg--900 text-black ewubar border-none focus:border-transparent focus:ring-2 focus:ring-transparent "></textarea>
              </div>
              <span class="text-black">Copy and paste html to send it out to the mailing list</span>
            </div>
          </div>
          <div class="flex-grow overflow-auto nobar">
            <div class="h-full flex flex-col">
              <div class="bg--700 h-6 flex items-center px-2">
                <!-- <span class="text-black">Result</span> -->
              </div>
              <div class="flex-grow">
                <iframe :srcdoc="currentContent.html" class="w-full h-full ewubar"></iframe>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    runCode() {
      const iframe = this.$el.querySelector('iframe');
      const doc = iframe.contentDocument || iframe.contentWindow.document;
      doc.open();
      doc.write(this.currentContent.html);
      doc.close();
    }
  },
  computed: {
    content: {
      get(){
        return this.$store.state.currentContent
      },
      set(y){
        this.$store.commit('SET_CURRENT_CONTENT', y)
      }
    },
    currentContent() {
      return this.content
    }
  }
};
</script>
