import api from './api'
import actuator from './actuator'

const filterMixin = {
	data () {
		return {
			filters: {
				bedroom: null,
				bathroom: null,

			},
			checkedAmenities: [],
			query: null,
			sort: null,
			property_status: ''
		}
	},
	mixins: [actuator],

	computed: {
		listings() {
			return this.$store.state.listings
		},
		filtered: {
			get(){
				return this.$store.state.filtered
			},
			set(x){
				this.$store.commit('SET_FILTERED', x)
			}
		},
		minprice: {
			get(){
				return this.$store.state.minprice
			},
			set(x){
				this.$store.commit('SET_MIN_PRICE', x)
			}
		},
		maxprice: {
			get(){
				return this.$store.state.maxprice
			},
			set(x){
				this.$store.commit('SET_MAX_PRICE', x)
			}
		}
	},
	watch: {
		sort(e){
			this.sortFiltered(e)
		},
		property_status(e){
			this.filtered = this.listings.filter((item) => {
			  return item.status == this.property_status
			})
		}
	},
	methods: {
		resetFilters(){
			this.filtered = this.$store.state.listings
			this.filters.bedroom = null
			this.filters.bathroom = null

		},
		applyFilters(){

			if (this.filters.bedroom != null) {
				if (this.filters.bedroom == 4) {
					var a = this.listings.filter((item) => {
					  return item.bedroom >= this.filters.bedroom
					})
				}else{
					var a = this.listings.filter((item) => {
					  return item.bedroom == this.filters.bedroom
					})
				}
			}

			if (this.filters.bathroom != null ) {
				if (this.filters.bathroom == 4) {
					var a = this.listings.filter((item) => {
					  return item.bathroom >= this.filters.bathroom
					})
				}else{
					var a = this.listings.filter((item) => {
					  return item.bathroom == this.filters.bathroom
					})
				}

			}

			if (this.checkedAmenities != []) {
				var m = []
				for (var i = 0; i < this.checkedAmenities.length; i++) {
					var h = this.listings.filter((item) => {
					  var k = this.parseStr(item.amenities)
					  return k.includes(this.checkedAmenities[i])
					})

					m = m.concat(h)

				}
				console.log(m)
			}


			if (a != undefined) {
				this.filtered = (m != []) ? a.concat(m) : a
			}else{
				this.filtered = m
			}
			

			// this.filterByPrice()	
		},

		sortFiltered(e){
			var a = this.filtered
			a.sort((a,b) => (a.title.toLowerCase() > b.title.toLowerCase()) ? 1 : ((b.title.toLowerCase() > a.title.toLowerCase()) ? -1 : 0))

			var b = this.listings.filter(( u ) => {
			    return u.price !== ''
			})

			var c = this.listings.filter(( u ) => {
			    return u.price == ''
			})

			b.sort((a,b) => (parseInt(a.price) > parseInt(b.price)) ? 1 : ((parseInt(b.price) > parseInt(a.price)) ? -1 : 0))

			if(e == 'title-asc'){
				this.filtered = a
			}
			if(e == 'title-desc'){
				this.filtered = a.reverse()
			}
			if(e == 'price-asc'){
				this.filtered = b.concat(c)
			}

			if(e == 'price-desc'){
				this.filtered = b.reverse().concat(c)
			}
		},

		filterBySearch(){
			const fuzzysort = require('fuzzysort')

			var a = []

			if (this.query != null) {
				let results = fuzzysort.go(this.query, this.listings, {
				  keys: ['title','location'],
				  // Create a custom combined score to sort by. -100 to the desc score makes it a worse match
				  scoreFn: a => Math.max(a[0]?a[0].score:-1000, a[1]?a[1].score-100:-1000)
				})

				this.filtered = []
				results.forEach( (result) => {
				    this.filtered.push(result.obj)
				})
				console.log(results)
			}

			// this.filterByPrice()

		},
		filterByPrice(){
			var a = this.filtered.filter(( o ) => {
			    return parseInt(o.price) <= parseInt(this.maxprice) && parseInt(o.price) >= parseInt(this.minprice)
			})

			console.log(a)

			this.filtered = a
		},
		addAmenity(e){
			if (this.checkedAmenities.includes(e)) {
				this.checkedAmenities = this.checkedAmenities.filter(item => item != e)
			}else{
				this.checkedAmenities.push(e)
			}
			
		}
	}

}

export default filterMixin