<script >
	import leads from '../mixins/leads'

	export default {
		props: ['propertyId'],
		mixins: [leads],
		mounted(){
			this.GetPropertyLeads()
		}
	}
</script>
<template>
	<div class="w-full overflow-hidden rounded-lg shadow-xs">
	  <div class="w-full overflow-x-auto">
	  	<div class="relative p-8 text-center border border-gray-200 rounded-lg" v-if="leads.length == 0">
	  	  <h2 class="text-2xl font-medium">
	  	    There's nothing here...
	  	  </h2>

	  	  <p class="mt-4 text-sm text-gray-500">
	  	    New content will appear here once added. If you are the site administrator add content to begin.
	  	  </p>
	  	</div>
	    <table class="w-full whitespace-no-wrap" v-if="leads.length > 0">
	      <thead>
	        <tr
	          class="text-xs font-semibold tracking-wide text-left text-black uppercase border-b dark:border-gray-700 bg-gray-50 dark:text-gray-400 dark:bg-gray-800"
	        >
	          <th class="px-4 py-3 text-black">Lead</th>
	          <th class="px-4 py-3">Email</th>
	          <th class="px-4 py-3">Country Code</th>
	          <th class="px-4 py-3">Number</th>
	          <th class="px-4 py-3">Message</th>
	          <th class="px-4 py-3">Actions</th>
	        </tr>
	      </thead>
	      <tbody
	        class="bg-white divide-y dark:divide-gray-700 dark:bg-gray-800"
	      >
	        <tr class="text-gray-700 dark:text-gray-400" v-for="(lead, i) in leads" :key="i">
	          <td class="px-4 py-3">
	            <div class="flex items-center text-sm">
	              <div>
	                <p class="font-semibold">{{lead.fname}} {{lead.lname}}</p>
	                <p class="text-xs text-gray-600 dark:text-gray-400">
	                  {{lead.occupation}}
	                </p>
	              </div>
	            </div>
	          </td>
	          <td class="px-4 py-3 text-sm">
	             {{lead.email}}
	          </td>
	          <td class="px-4 py-3 text-sm">
	            {{lead.ccode}}
	          </td>
	          <td class="px-4 py-3 text-sm">
	            {{lead.number}}
	          </td>
	          <td class="px-4 py-3 text-xs">
				{{ lead.message.slice(0, 45) }}
	          </td>
	        </tr>

	      </tbody>
	    </table>
	  </div>
	</div>
</template>