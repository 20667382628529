import axios from 'axios'
import api from './api'
import actuator from './actuator'

const smss = {
	data: () => {
		return {
			title: '', 
			message: '',
			ccode: '254',
			number: '',
			image: '',
			sms_id: '',
			smss: [],
			loaded: false,
			showDelete: false,
			sms_id: 0,
			counter: 0,
			unsendables: 0
		}
	},
	computed: {
		checkedLeads(){
			return this.$store.state.checkedLeads
		},
		aLeads(){
			return this.$store.state.aLeads
		}
	},
	watch:{
		images(y){
			if(y.length > 0){
				if (this.$route.name == 'Editsms') {
					this.edit()
				}else {
					this.create()
				}
				
			}
		}
	},
	methods: {
		async create(y){
			if (this.isEmptyOrUndefinedString(y.number)) {
				this.unsendables++
			}else{
				var f = new FormData()

				try {
					f.append('number', '+'+this.processCode(y.ccode)+y.number)
					f.append('message', this.message)

					 const response = await api.post('create/sms/', f)
					 const data = response.data
					 console.log(`Data ${i} created`)
				  // Do something with the data
				} catch (error) {
				  console.error(error)
				  this.unsendables++
				}
			}
		},
		async lContacts(){
			for (var i = 0; i < this.checkedLeads.length; i++) {

				await this.create(this.findLeadById(this.checkedLeads[i]))
				this.counter++
			}	
		},
		findLeadById(id) {
		  console.log(id)
		  for (const lead of this.aLeads) {
		    if (lead.lead_id == id) {
		      return lead
		    }
		  }
		  return null
		},
		async cContacts() { 
		  await this.lContacts()
		  console.log('Loop finished')
		},
		edit(){
			var f = new FormData()

			f.append('sms_id', this.sms_id)
			f.append('message', this.message)
			f.append('title', this.title)
			f.append('number', this.number)

			api.post('edit/sms/', f,  {  headers: {
			    'Content-Type': 'application/json',
			    'X-CSRFToken': this.$store.state.authtoken
			}}).then( (r) => {
				// this.$router.push('/manage')
				this.$store.commit('SET_IMAGES', [])
				this.$router.push('/manage')

				this.loaded = false
			}, (e) => {
				console.log(e)
			})
		},
		read(){
			var f = new FormData()

			f.append('title', this.title)

			api.post('get/sms/', f).then( (r) => {
				// this.$router.push('/manage')
				this.sms_id = r.data.sms_id
				this.name = r.data.name
				this.message = r.data.message
				this.number = r.data.number
				this.image = r.data.image	

				this.loaded = true
			}, (e) => {
				console.log(e)
			})
		},
		readAll(){
			api.get('get/sms/').then( (r) => {
				this.smss = r.data
				this.$store.state.smsLn = r.data.length
				this.loaded = true
			}, (e) => {
				console.log(e)
			})
		},
		initDel(y){
			this.sms_id = y
			this.showDelete = true
		},
		del(){ 
			var f = new FormData()

			f.append('sms_id', this.sms_id) 

			api.post('delete/sms/', f).then( (r) => {
				this.readAll()
				this.showDelete = false
			})
		}
	}
}

export default smss