<script>
	import team from '../mixins/team'
	import actuator from '../mixins/actuator'
	import narratives from '../mixins/narratives'
	import Navbar from './navbar'
	import { saveAs } from 'file-saver'

	export default {
		mixins: [team, actuator, narratives],
		components: {
			Navbar
		},
		mounted(){
			this.readAll()
			this.readAllNarratives()
		},
		methods: {
			saveAs(e){
				var vCardsJS = require('vcards-js')
				var vCard = vCardsJS()

				vCard.firstName = e.name
				vCard.organization = 'Axis Real Estate LTD'
				vCard.workPhone = '+254 202724848'
				vCard.workEmail = e.email
				vCard.socialUrls['linkedIn'] = e.linkedin
				vCard.socialUrls['twitter'] = e.twitter

				var vcfBytes = vCard.getFormattedString()
				console.log(vcfBytes)

				var vcfBlob = new Blob(vcfBytes.split('\n').map(function(x) { return x + '\n' }))

				saveAs(vcfBlob, `${e.name}.vcf`)
			}
		}
	}
</script>
<template>
	<navbar />
	<section class="text-gray-600 body-font">
	  <div class="container px-5 py-24 mx-auto flex flex-col">
	    <div class="max-w-5xl mx-auto flex flex-col sm:flex-row gap-10 sm:gap-20">
	        <div class="text-3xl w-full sm:w-2/3 text-green-900 leading-relax">
	           Axis Real Estate Ltd is a leading real estate adviser in Africa. 
	        </div>


	        <div class="flex flex-col gap-5 items-start justify-center w-full sm:w-1/3">
	            <div class="text-green-900" v-html="(teamText != null || teamText != undefined) ? teamText.html : 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod'">
	                
	            </div>

	            <router-link to="/about" class="text-green-900 hover:text-green-700 inline-flex gap-1 items-center justify-start">
	                Read More
	                <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 rotate-45 text-current" fill="none"
	                     viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
	                    <path stroke-linecap="round" stroke-linejoin="round" d="M7 11l5-5m0 0l5 5m-5-5v12"/>
	                </svg>
	            </router-link>
	        </div>
	    </div>
	    <div class="lg:w-4/6 mx-auto" v-for="teammate in teammates">
	      <div class="flex flex-col sm:flex-row mt-10">
	        <div class="sm:w-1/3 text-center sm:pr-8 sm:py-8">
	          <div class="w-44 h-44 rounded-full inline-flex items-center justify-center bg-gray-200 text-gray-400">
	          	<img :src="parse(teammate.avatar)" class="w-44">
	          </div>
	          <div class="flex flex-col items-center text-center justify-center">
	            <h2 class="font-medium title-font mt-4 text-gray-900 text-lg">{{teammate.name}}</h2>
	            <div class="w-12 h-1 bg-green-500 rounded mt-2 mb-4"></div>
	            <p class="text-base">{{teammate.role}}</p>
	            <span class="inline-flex mt-2">
	              <a target="_blank" :href="teammate.linkedin"  class="text-gray-500">
					<svg fill="currentColor" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="0" class="w-5 h-5" viewBox="0 0 24 24">
					  <path stroke="none" d="M16 8a6 6 0 016 6v7h-4v-7a2 2 0 00-2-2 2 2 0 00-2 2v7h-4v-7a6 6 0 016-6zM2 9h4v12H2z"></path>
					  <circle cx="4" cy="4" r="2" stroke="none"></circle>
					</svg>
	              </a>
	              <a target="_blank" :href="teammate.twitter" class="ml-2 text-gray-500">
	              	<svg fill="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-5 h-5" viewBox="0 0 24 24">
	              	  <path d="M23 3a10.9 10.9 0 01-3.14 1.53 4.48 4.48 0 00-7.86 3v1A10.66 10.66 0 013 4s-4 9 5 13a11.64 11.64 0 01-7 2c9 5 20 0 20-11.5a4.5 4.5 0 00-.08-.83A7.72 7.72 0 0023 3z"></path>
	              	</svg>
	              </a>
	              <a target="_blank" :href="`mailto:${teammate.email}`" class="ml-2 text-gray-500">
	              	<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
	              	  <path stroke-linecap="round" stroke-linejoin="round" d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75" />
	              	</svg>

	              </a>
	            </span>
	          </div>
	        </div>
	        <div class="sm:w-2/3 sm:pl-8 sm:py-8 sm:border-l border-gray-200 sm:border-t-0 border-t mt-4 pt-4 sm:mt-0 text-center sm:text-left">
	          <p class="leading-relaxed text-lg mb-4">{{teammate.description}}</p>
	          <a @click="saveAs(teammate)" class="text-green-500 inline-flex items-center">Download Vcard
	            <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-4 h-4 ml-2" viewBox="0 0 24 24">
	              <path d="M5 12h14M12 5l7 7-7 7"></path>
	            </svg>
	          </a>
	        </div>
	      </div>
	    </div>
	  </div>
	</section>
</template>

