import { createWebHistory, createRouter } from "vue-router";
import Landing from '@/landing/index'
import Register from '@/register'
import Settings from '@/settings'
import Manage from '@/manage'
import ViewListings from '@/manage/listings'
import ViewLeads from '@/manage/leads'
import ViewServices from '@/manage/services'
import ViewTeam from '@/manage/team'
import Logout from '@/manage/logout'
import CreateListing from '@/properties/create'
import ReadListing from '@/properties/read'
import CreateTeammate from '@/team/create'
import CreateService from '@/services/create'
import ReadService from '@/services/read'
import CreateNotifications from '@/notifications/create'
import CreateNarrative from '@/narratives/create'
import ViewUsers from '@/users/view'
import CreateUsers from '@/users/create'
import ViewNarratives from '@/narratives/read'
import FilterProperties from '@/landing/filter'
import Dashboard from '@/dashboard/Dashboard'
import Services from '@/landing/services'
import Team from '@/landing/team'
import About from '@/landing/about'
import Contact from '@/landing/contact'
import CreateNewsletter from '@/newsletter/create'
import Rolodex from '@/rolodex'
import Flyer from '@/properties/flyer'
import Developer from '@/developer/create'

const routes = [
  {
    path: "/",
    name: "Landing",
    component: Landing,
  },
  {
    path: "/developer",
    name: "Developer",
    component: Developer,
  },
  {
    path: "/register",
    name: "Register",
    component: Register,
  },

  {
    path: "/settings",
    name: "Settings",
    component: Settings,
  },

  {
    path: "/dashboard",
    name: "Dashboard",
    component: Dashboard,
  },
  {
    path: "/flyer",
    name: "Flyer",
    component: Flyer,
  },

  {
    path: "/manage",
    name: "Manage",
    component: Dashboard,
  },

  {
    path: "/logout",
    name: "Logout",
    component: Logout,
  },
  {
    path: "/create/sms",
    name: "CreateNotifications",
    component: CreateNotifications,
  },
  {
    path: "/create/listing",
    name: "CreateListing",
    component: CreateListing,
  },
  {
    path: "/view/listings",
    name: "ViewListings",
    component: ViewListings,
  },
  {
    path: "/view/services",
    name: "ViewServices",
    component: ViewServices,
  },
  {
    path: "/view/users",
    name: "ViewUsers",
    component: ViewUsers,
  },
  {
    path: "/create/users",
    name: "CreateUsers",
    component: CreateUsers,
  },
  {
    path: "/view/narratives",
    name: "ViewNarratives",
    component: ViewNarratives,
  },
  {
    path: "/view/team",
    name: "ViewTeam",
    component: ViewTeam,
  },
  {
    path: "/view/leads",
    name: "ViewLeads",
    component: ViewLeads,
  },

  {
    path: "/listing/:uuid",
    name: "ReadListing",
    component: ReadListing,
  },

  {
    path: "/edit/listing/:uuid",
    name: "EditListing",
    component: CreateListing,
  },

  {
    path: "/edit/user/:username",
    name: "EditUser",
    component: CreateUsers,
  },

  {
    path: "/create/team",
    name: "CreateTeammate",
    component: CreateTeammate,
  },

  {
    path: "/edit/team/:name",
    name: "EditTeammate",
    component: CreateTeammate,
  },

  {
    path: "/edit/narrative/:id",
    name: "EditNarrative",
    component: CreateNarrative,
  },

  {
    path: "/create/service",
    name: "CreateService",
    component: CreateService,
  },

  {
    path: "/edit/service/:name",
    name: "EditService",
    component: CreateService,
  },

  {
    path: "/service/:name",
    name: "ReadService",
    component: ReadService,
  },

  {
    path: "/create/narrative",
    name: "CreateNarrative",
    component: CreateNarrative,
  },
  {
    path: "/create/newsletter",
    name: "CreateNewsletter",
    component: CreateNewsletter,
  },
  {
    path: "/filter",
    name: "FilterProperties",
    component: FilterProperties,
  },
  {
    path: "/about",
    name: "About",
    component: About,
  },
  {
    path: "/team",
    name: "Team",
    component: Team,
  },
  {
    path: "/services",
    name: "Services",
    component: Services,
  },
  {
    path: "/contact",
    name: "Contact",
    component: Contact,
  },
  {
    path: "/rolodex",
    name: "Rolodex",
    component: Rolodex,
  },


];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    // always scroll to top
    return { top: 0 }
  },
});

export default router;