<template>
  <div class="leading-normal tracking-normal" id="main-body">
    <div class="flex flex-wrap">

      <Sidebar :sideBarOpen="sideBarOpen"/>

      <div class="w-full bg-gray-100 pl-0 lg:pl-64 min-h-screen" :class="sideBarOpen ? 'overlay' : ''" id="main-content">

        <Navbar :sideBarOpen="sideBarOpen" @toggleSidebar="toggleSidebar"/>

        <div class="p-6 bg-gray-100 mb-20">
          <Home/>
        </div>

        <Footer />

      </div>
    </div>
  </div>
  <pageload v-if="loadAuth"/>
</template>

<script>  
import { mapState } from 'vuex'

import auth from '../mixins/auth.js'

import Sidebar from './Sidebar'
import Navbar from './Navbar'
import Footer from './Footer'
import Home from './Home'

export default {
  data: () => {
    return {
      sideBarOpen: false
    }
  },
  mixins: [auth],
  components: {
    Sidebar,
    Navbar,
    Footer,
    Home
  },
  mounted(){
    this.authenticated()
  },
  methods: {
    toggleSidebar(z){
      this.sideBarOpen = z
    }
  }
}
</script>
