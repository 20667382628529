<template>
	<div
	  v-if="$store.state.loaded_listings && listing.visibility == 'Public'"

	  class="relative block bg-white"
	
	>

	  <img
	    loading="lazy"
	    alt="Find your next property"
	    class="object-cover w-full h-56 lg:h-72 rounded-xl p-1"
	    :src="parse(listing.images)"
	  />

	  <div class="p-6">
	    <span class="inline-block px-3 py-1 text-xs font-medium bg-green-600 text-white">
	      {{ (listing.status != 'For Let') ? listing.status : 'To Let'	 }}
	    </span>

	    <h5 class="mt-4 text-lg font-bold">
	      {{ listing.title }}
	    </h5>

	    <p class="mt-2 text-sm font-medium text-gray-600" v-if="listing.price != ''">
	      {{ listing.currency }}  {{ strNum(listing.price) }}
	    </p>

	    <dl class="flex items-center mt-6 text-xs space-x-2" v-if="listing.status != 'Project'">
	          <div class="sm:inline-flex sm:items-center sm:shrink-0">
	          	<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4 text-neutral-400	">
	          	  <path stroke-linecap="round" stroke-linejoin="round" d="M5.25 7.5A2.25 2.25 0 017.5 5.25h9a2.25 2.25 0 012.25 2.25v9a2.25 2.25 0 01-2.25 2.25h-9a2.25 2.25 0 01-2.25-2.25v-9z" />
	          	</svg>


	            <div class="sm:ml-1 mt-1.5 sm:mt-0">
	              <dt class="text-gray-500">
	                Size
	              </dt>

	              <dd class="font-medium">
	                {{ (listing.size != '') ? strNum(listing.size) : '' }} ft
	              </dd>
	            </div>
	          </div>

	          <div class="sm:inline-flex sm:items-center sm:shrink-0">
	            <svg
	              class="w-4 h-4 text-neutral-400	"
	              xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor"
	            >
	              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 3v4M3 5h4M6 17v4m-2-2h4m5-16l2.286 6.857L21 12l-5.714 2.143L13 21l-2.286-6.857L5 12l5.714-2.143L13 3z" />
	            </svg>

	            <div class="sm:ml-1 mt-1.5 sm:mt-0">
	              <dt class="text-gray-500">
	                Bathroom
	              </dt>

	              <dd class="font-medium">
	               {{ listing.bathroom }} rooms
	              </dd>
	            </div>
	          </div>

	          <div class="sm:inline-flex sm:items-center sm:shrink-0">
	            <svg
	              class="w-4 h-4 text-neutral-400	"
	              xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor"
	            >
	              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M20.354 15.354A9 9 0 018.646 3.646 9.003 9.003 0 0012 21a9.003 9.003 0 008.354-5.646z" />
	            </svg>

	            <div class="sm:ml-1 mt-1.5 sm:mt-0">
	              <dt class="text-gray-500">
	                Bedroom
	              </dt>

	              <dd class="font-medium">
	                {{ listing.bedroom }} rooms
	              </dd>
	            </div>
	          </div>
	        </dl>

	        <router-link :to="`/listing/${listing.uuid}`" class="relative inline-block mt-7 text-sm font-bold text-green-600">
	          <span
	            class="absolute inset-x-0 bottom-0 bg-green-100 transition-transform transform h-2/3 group-hover:scale-110"
	          ></span>
	          <span class="relative">View Listing</span>
	        </router-link>
	  </div>
	</div>
</template>
<script>
	import actuator from '../mixins/actuator'

	export default {
		props: ['listing'],
		mixins: [actuator],
		computed: {
			loaded(){
				return this.$store.state.loaded_listings
			}
		}
	}
</script>