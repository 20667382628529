<template>
  <div class="flex justify-center items-center h-full mt-24">
    <div class="max-w-3xl w-full px-4" v-show="!isEmpty(receivers)">
      <h1 class="text-3xl font-bold mb-4 px-8 text-left">Create Newsletter</h1>
      <p>You have selected {{receivers.length}} number of newsletter receivers, write their email below</p>
      <form class="bg-white rounded px-8 pt-6 pb-8 mb-4" @submit.prevent="onSubmit">
        <div class="mb-4">
          <label class="block text-gray-700 font-bold mb-2" for="subject">Subject</label>
          <input v-model="subject" class="appearance-none border-none focus:border-transparent focus:ring-2 focus:ring-transparent" id="subject" type="text" placeholder="Enter newsletter subject">
        </div>

        <div class="flex flex-col h-screen">
          <div class="flex-grow flex flex-col overflow-hidden">
            <div class="flex-grow">
              <div class="flex flex-col h-full">
                <div class="flex-grow overflow-hidden nobar">
                  <div class="h-full flex flex-col">
                    <div class="bg--700 h-6 flex items-center px-2">
                      <label class="block text-gray-700 font-bold mb-2" for="title">HTML</label>
                       
                    </div>
                    <div class="flex-grow">
                      <textarea v-model="currentContent.html" class="h-full w-full bg--900 text-black ewubar border-none focus:border-transparent focus:ring-2 focus:ring-transparent "></textarea>
                    </div>
                    <span class="text-black">Copy and paste html to send it out to the mailing list</span>
                  </div>
                </div>
                <div class="flex-grow overflow-auto nobar">
                  <div class="h-full flex flex-col">
                    <div class="bg--700 h-6 flex items-center px-2">
                      <!-- <span class="text-black">Result</span> -->
                    </div>
                    <div class="flex-grow">
                      <iframe :srcdoc="currentContent.html" class="w-full h-full ewubar"></iframe>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>

        <p> {{counter}} number of newsletter receivers, have received their email</p>
        <p> {{unsendables}} number of newsletter receivers, cannot receive their email</p>
 
        <div class="flex items-center justify-between">
          <button class="bg-[#126f51] hover:bg-[#126f51] text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" 
          @click="loadcContacts"
          v-if="!loadbtn"
          >
            Next
          </button>
          <loader v-else />
          <router-link class="inline-block align-baseline font-bold text-sm text-[#126f51] hover:text-[#126f51]" to="/manage">
            Cancel
          </router-link>
        </div>
      </form>
    </div>
    <section
      v-show="isEmpty(receivers)"
      class="relative"
    >
      <div
        class="absolute inset-0 bg-white/75 sm:bg-transparent sm:from-white/95 sm:to-white/25 ltr:sm:bg-gradient-to-r rtl:sm:bg-gradient-to-l"
      ></div>

      <div
        class="relative mx-auto max-w-screen-xl px-4 py-32 sm:px-6 lg:flex lg:h-screen lg:items-center lg:px-8"
      >
        <div class="max-w-xl text-center ltr:sm:text-left rtl:sm:text-right">
          <h1 class="text-3xl font-extrabold sm:text-xl">
            Select Contacts

          </h1>

          <p class="mt-4 max-w-lg sm:text-xl/relaxed">
            To proceed select contacts you want to receive the emails from the leads tab
          </p>

          <div class="mt-8 mx-auto justify-center flex flex-wrap gap-4 text-center">
            <router-link
              to="/view/leads"
              class="block w-full rounded bg--600 px-12 py-3 text-sm font-medium shadow hover:bg--700 focus:outline-none focus:ring active:bg--500 sm:w-auto"
            >
             Proceed
            </router-link>

            <a
              @click="$router.go(-1)"
              class="block w-full rounded bg-white px-12 py-3 text-sm font-medium text--600 shadow hover:text--700 focus:outline-none focus:ring active:text--500 sm:w-auto"
            >
              Back
            </a>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import newsletters from '../mixins/newsletter'
import actuator from '../mixins/actuator'
export default {
  mixins: [newsletters, actuator],
  data() {
    return {
      content: { html: '<h1>Hello World!</h1>' },
      loadbtn: false
    }
  },
  methods: {
    runCode() {
      const iframe = this.$el.querySelector('iframe')
      const doc = iframe.contentDocument || iframe.contentWindow.document
      doc.open()
      doc.write(this.currentContent.html)
      doc.close()
    },
    loadcContacts(){
      this.loadbtn = true
      this.cContacts()
    }
  },
  computed: {
    currentContent() {
      return this.content
    },
    receivers(){
      return this.$store.state.checkedLeads
    }
  }
}
</script>
