import axios from 'axios'
import api from './api'
import { v4 as uuidv4 } from 'uuid'
import actuator from './actuator'


const properties = {
	mixins: [actuator],
	data: () => {
		return {
			uuid: null,
			title: '',
			description:'',
			parking: '',
			bathroom: '',
			size: '',
			bedroom: '',
			price: '',
			category: 'Residential', 
			subcategory: '',
			status: 'For Let',
			availability: 'Available',
			serviceCharge: 'N/a',
			currency: 'KES',
			currencies: [],
			checkedAmenities: [],
			amenities: [],
			disclosure: 'Absolute Price',
			location: '',
			rawLocation: '',
			visibility: 'Public',
			showcase: 'True',
			tems: null,
			listings: [],
			showcaseItems:[],
			listing: {},
			undoItems: [],
			loaded: false,
			showDelete: false,
			listing_id: 0 
		}
	},
	mounted(){
		var a = JSON.stringify(require('../helpers/Common-Currency.json'))

		this.currencies = JSON.parse(a)
	},
	computed: {
		images(){
			return this.$store.state.images
		}
	},
	watch:{
		images(y){
			if(y.length > 0){
				if (this.$route.name == 'EditListing') {
					this.edit()
				}else {
					this.create()
				}
			}
		}
	},
	methods: {
		create(){
			var f = new FormData()

			this.uuid = uuidv4()

			f.append('uuid', this.uuid)
			f.append('title', this.title)
			f.append('description', this.description)
			f.append('parking', this.parking)
			f.append('bathroom', this.bathroom)
			f.append('bedroom', this.bedroom)
			f.append('price', this.price)
			f.append('size', this.size)
			f.append('category', this.category)
			f.append('subcategory', this.subcategory)
			f.append('status', this.status)
			f.append('availability', this.availability)
			f.append('serviceCharge', this.serviceCharge)
			f.append('currency', this.currency)
			f.append('amenities', JSON.stringify(this.checkedAmenities))
			f.append('visibility', this.visibility)
			f.append('showcase', this.showcase)
			f.append('disclosure', this.disclosure)
			f.append('location', this.rawLocation.label)
			f.append('rawLocation', JSON.stringify(this.rawLocation))
			f.append('images', JSON.stringify(this.$store.state.images))

			api.post('create/listing/', f,  {  headers: {
			    'Content-Type': 'application/json',
			    'X-CSRFToken': this.$store.state.authtoken
			}}).then( (r) => {
				// this.$router.push('/manage')
				this.$store.commit('SET_IMAGES', [])
				this.$router.push('/listing/'+this.uuid)

			}, (e) => {
				console.log(e)
			})
		},
		deleteTems(filename){
			var a = this.tems 
			this.tems = a.filter(( obj ) => {
			  return obj.filename !== filename
			})
			this.undoItems = a
		},
		undo(){
			this.tems = this.undoItems
		},
		getListing(uuid){
			var f = new FormData()

			f.append('uuid', uuid)

			api.post('get/listing/', f).then( (r) => {
				this.listing = r.data
				this.loaded = true
			}, (e) => {
				console.log(e)
			})

		},
		read(uuid){
			var f = new FormData()

			f.append('uuid', uuid)

			api.post('get/listing/', f).then( (r) => {
				this.uuid = r.data.uuid
				this.title = r.data.title
				this.description = r.data.description
				this.parking = r.data.parking
				this.bathroom = r.data.bathroom
				this.bedroom = r.data.bedroom
				this.price = r.data.price
				this.subcategory = r.data.subcategory
				this.status = r.data.status
				this.size = r.data.size
				this.availability = r.data.availability
				this.serviceCharge = r.data.serviceCharge
				this.currency = r.data.currency
				this.disclosure = r.data.disclosure
				this.visibility = r.data.visibility
				this.showcase = r.data.showcase
				this.checkedAmenities = this.parseStr(r.data.amenities)
				this.category = r.data.category
				this.location = r.data.location
				if (r.data.rawLocation != "") {
					this.rawLocation = JSON.parse(r.data.rawLocation)
				}
				this.tems = this.parseAll(r.data.images)
				this.undoItems = this.parseAll(r.data.images)
				this.loaded = true
			}, (e) => { 
				console.log(e)
			})
		},
		readAll(){
			api.get('get/listings/').then( (r) => {
				this.listings = r.data
				this.$store.state.propertyLn = r.data.length
				this.$store.commit('SET_LISTINGS', r.data)
				this.$store.commit('SET_FILTERED', r.data) 
				this.$store.commit('SET_LOADED_LISTINGS', true)
				this.showcaseItems = this.listings.filter((item) => {
				  return item.showcase != 'False'
				})

				this.loaded = true
			}, (e) => {
				console.log(e)
			})
		},
		edit(){	
			var f = new FormData()

			f.append('uuid', this.uuid)
			f.append('title', this.title)
			f.append('description', this.description)
			f.append('parking', this.parking)
			f.append('bathroom', this.bathroom)
			f.append('subcategory', this.subcategory)
			f.append('status', this.status)
			f.append('availability', this.availability)
			f.append('serviceCharge', this.serviceCharge)
			f.append('currency', this.currency)
			f.append('disclosure', this.disclosure)
			f.append('bedroom', this.bedroom)
			f.append('price', this.price)
			f.append('category', this.category)
			f.append('size', this.size)
			f.append('amenities',  JSON.stringify(this.checkedAmenities))
			f.append('visibility', this.visibility)
			f.append('showcase', this.showcase)
			f.append('disclosure', this.disclosure)
			f.append('location', this.rawLocation.label)
			f.append('rawLocation', JSON.stringify(this.rawLocation))
			f.append('images', JSON.stringify(this.tems.concat(this.$store.state.images)))

			api.post('edit/listing/', f,  {  headers: {
			    'Content-Type': 'application/json',
			    'X-CSRFToken': this.$store.state.authtoken
			}}).then( (r) => {
				this.$router.push('/manage')
			}, (e) => {
				console.log(e)
			})
		},
		initDel(y){
			this.listing_id = y
			this.showDelete = true
		},
		del(){
			var f = new FormData()

			f.append('listing_id', this.listing_id)

			api.post('delete/listing/', f).then( (r) => {
				this.readAll()
				this.showDelete = false
			})
		}
	}
}

export default properties