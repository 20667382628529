<script>
	import services from '../mixins/services'
	import actuator from '../mixins/actuator'
	import navbar from '../landing/navbar'

	export default {
		mixins: [services, actuator],
		mounted(){
			this.loaded = false
			this.title = this.$route.params.name
			this.read()
		},
		components: {
			navbar
		}
	}
</script>
<template>
	<navbar></navbar>
	<section class="bg-white dark:bg-gray-900 mt-12" v-if="loaded">
	    <div class="gap-16 items-center py-8 px-4 mx-auto max-w-screen-xl lg:grid lg:grid-cols-2 lg:py-16 lg:px-6">
	        <div class="">
	            <h2 class="mb-4 text-4xl tracking-tight font-extrabold text-gray-900 dark:text-white">{{ title }}</h2>
	            <div v-html="description"></div>
	            <br>
	            <a :href="`mailto:${email}`" class="underline primary">{{email}}</a><br><br>
	            <a class="underline my-8" @click="$router.go(-1)">&larr;  Back </a>
	        </div>
	        <div class="grid grid-cols-1 mt-8 rounded-lg" :style="{background: `url(${parse(image)})no-repeat`, backgroundSize: 'cover', backgroundPosition: 'center', height: '100vh'}">
	            <!-- <img class="w-full rounded-lg" :src="parse(image)" alt="office content 1"> -->
	        </div>
	    </div>
	</section>

</template>