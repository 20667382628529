<template>

  <div class="leading-normal tracking-normal" id="main-body">
    <div class="flex flex-wrap">

      <Sidebar :sideBarOpen="sideBarOpen"/>

      <div class="w-full bg-gray-100 pl-0 lg:pl-64 min-h-screen" :class="sideBarOpen ? 'overlay' : ''" id="main-content">

        <Navbar :sideBarOpen="sideBarOpen" @toggleSidebar="toggleSidebar"/>

        <div class="bg-gray-100 mt-9">
          <div class="w-full overflow-hidden rounded-lg shadow-xs">
            <div class="w-full overflow-x-auto">

              <div class="relative p-8 text-center border border-gray-200 rounded-lg" v-if="users.length == 0">
                <h2 class="text-2xl font-medium">
                  There's nothing here...
                </h2>

                <p class="mt-4 text-sm text-gray-500">
                  New content will appear here once added. If you are the site administrator add content to begin.
                </p>
              </div>
              <div class="flex flex-col h-screen">
                <div class="overflow-x-auto">
                  <table class="min-w-full divide-y divide-gray-200">
                    <thead class="bg-gray-50 ">
                      <tr>
                        <th class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          ID
                        </th>
                        <th class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Username
                        </th>
                        <th class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Email
                        </th>
                        <th class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Role
                        </th>
                        <th class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          
                        </th>
                      </tr>
                    </thead> 
                    <tbody class="bg-white divide-y divide-gray-200">
                      <tr v-for="user in users" :key="user.id">
                        <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                          {{ user.id }}
                        </td>
                        <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          {{ user.username }}
                        </td>
                        <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          {{ user.email }}
                        </td>
                        <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          {{ (user.groups.length != 0) ? user.groups[0].name : 'None' }}
                        </td>
                        <td class="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                          <router-link class="text-indigo-600 hover:text-indigo-900" :to="`/edit/user/${user.username}`">
                            Edit
                          </router-link>
                          <button class="text-red-600 hover:text-red-900 ml-4" @click="initDel(user.id)">
                            Delete
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <delete  v-if="showDelete" @cancel="showDelete = false" @accept="del()"></delete>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Footer />

      </div>
    </div>
  </div>


</template>

<script>
  import Auth from '../mixins/auth'
  import actuator from '../mixins/actuator'
  import Sidebar from '../dashboard/Sidebar'
  import Navbar from '../dashboard/Navbar'
  import Footer from '../dashboard/Footer'


  export default {
    mixins: [Auth, actuator],
    mounted(){
      this.readAll()
    },
    computed: {
      sideBarOpen: {
        get(){
          return this.$store.state.sideBarOpen
        },
        set(z){
          this.$store.commit('SET_SIDEBAR', z)
        }
      }
    },
    watch: {
      $route(){
        this.sideBarOpen = false
      }
    },
    components: {
      Sidebar,
      Navbar,
      Footer
    },
    methods: {
      toggleSidebar(z){
        this.sideBarOpen = z
      }
    }
  }
</script>
